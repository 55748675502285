<mat-dialog-actions>
    <button class="mat-raised-button close" (click)="close()"> X </button>
</mat-dialog-actions>
<h2 mat-dialog-title></h2>

<div mat-dialog-body>
    <img *ngIf="imageUri!=''" [src]="imageUri | safe"
        style="min-width: 600px !important; height: 80% !important;  margin: 0px auto !important; display: block; max-width: 800px !important;" />

    <img *ngIf="imageUri==''" src="assets/images/image-loader.gif"
        style="width: 50px; height: 50px; margin: 0 auto; display: block;" />

    <button *ngIf="imageUri!=''" (click)="displayOriginalImageSize()" style="margin: 15px auto 0px auto;
    display: block;
    padding: 5px 30px;
    background-color: #4d5198;
    border: none;
    color: white;
    font-size: small;
    border-radius: 5px;">Preview image in original size</button>
</div>