

export class RegisterChatMspModel {
    firstName: string;
      lastName: string;
      hostName: string;
      email: string;
      message: string;
      isUrlCreated:boolean;
}


export class RegisterChatMspFactory {
    public static GetModel(model:any):RegisterChatMspModel
    {
        var retModel=new RegisterChatMspModel();
        retModel.isUrlCreated=false;

        return retModel;
    }
}
