import { Inject, Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Injectable()
export class AppConfig {

  private config: Object = null;
  private  env:    Object = null;
  public logoURL:    Object = null;
  public origin:    Object = null;
  public apikey: Object=null;

  constructor() {
    this.logoURL = environment.logoURL;
    this.origin = environment.origin;
    this.apikey = environment.apikey;
  }

  /**
   * Use to get the data found in the second file (config file)
   */
  public getConfig(key: any) {
    return this.config[key];
  }

  /**
   * Use to get the data found in the first file (env file)
   */
  public getEnv(key: any) {
    return this.env[key];
  }

  /**
   * This method:
   *   a) Loads "env.json" to get the current working environment (e.g.: 'production', 'development')
   *   b) Loads "config.[env].json" to get all env's variables (e.g.: 'config.development.json')
   */
  public load() {
    this.logoURL = environment.logoURL;
    this.origin = environment.origin;
    this.apikey = environment.apikey;
  }
}