import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Router, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ChatCloseFormComponent } from '../../home/chat-close-form/chat-close-form.component';

export interface ComponentCanDeactivate {
    canDeactivate: () => boolean | Observable<boolean> | Promise<boolean>;
  }


@Injectable()
export class PendingChangesGuard  implements CanDeactivate<ChatCloseFormComponent> {

    

    canDeactivate(component: ChatCloseFormComponent, 
        route: ActivatedRouteSnapshot, 
        state: RouterStateSnapshot) {

        let url: string = state.url;
        console.log('Url: '+ url);

        return component.canDeactivate ? component.canDeactivate() : true;
    }
}