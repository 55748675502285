import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../service/alert.service';
import { AuthenticationService } from '../../service/authentication.service';
import { ChatService } from '../../service/chatservice';
import { ChatVolumeModalComponent } from '../chat-volume-modal/chat-volume-modal.component';
@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.css']
})

export class UserSettings implements OnInit {
  model: any = {};
  loading = false;
  returnUrl: string;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService,
      private alertService: AlertService,
      private dialog: MatDialog,
      private chatService: ChatService  ) { }

  ngOnInit() {
      // reset login status
      //this.authenticationService.logout();

      // get return url from route parameters or default to '/'

  }

  openVolume()
  {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    //dialogConfig.height = "310px";
    dialogConfig.width = "500px";
    
    var chatVolume=100;
    var roomVolume=100;

    if (localStorage.getItem('currentUserChatVolume')) 
    {
      chatVolume= parseInt(localStorage.getItem('currentUserChatVolume'));
    }

    if (localStorage.getItem('currentUserRoomVolume')) 
    {
      roomVolume= parseInt(localStorage.getItem('currentUserRoomVolume'));
    }

    dialogConfig.data=
    {
     volume: chatVolume,
     roomVolume:roomVolume
    };

    const dialogRef = this.dialog.open(ChatVolumeModalComponent, dialogConfig);
    const subDialogRef = dialogRef.afterClosed().pipe().subscribe(data => {
      
      
      if(data!=false)
      {
        localStorage.setItem('currentUserChatVolume',data.ChatVolume);
        localStorage.setItem('currentUserRoomVolume',data.RoomVolume);
      }
      
    });
  }
}

