/**
 * Room represents a group of Users exchanging Messages
 */
export class ChatPersonalization {
  id: number;
  colorCode: string;
  logo: string;
  additionalColor: string;

 
  
  constructor(obj?: any) {
    this.id = obj && obj.id || 0;
    this.colorCode = obj && obj.colorCode || '';
    this.logo = obj && obj.logo || '';
    this.additionalColor = obj && obj.additionalColor || '';

  }
}

