<div class="clocked-popup-wrapper">

    <mat-dialog-actions>
    <button class="mat-raised-button close" (click)="cancel()"> X </button>
    </mat-dialog-actions>
    
    <h2 mat-dialog-title>Notice</h2>
    <div class="mat-dialog-question">{{noticeMessage}}</div>
    
    <mat-dialog-actions>
    <button class="mat-raised-button mat-primary" (click)="close()">OK</button>
    </mat-dialog-actions>
    
    </div>
    