import { User } from "./user";
import { Room } from "./room.model";
import { uuid } from "../home/util/uuid";
import { MessageAttachment } from "./MessageAttachment.model";




/**
 * Message represents one message being sent in a Room
 */
 export class Message {
   id: string;
   closedByUserId: string;
   sentAt: Date;
   isRead: boolean;
   author: User;
   text: string;
   room: Room;
   owner: User;
   isPrivate: boolean;
   isSyncMsg:boolean;
   msgType:number;
   orgMsgType:number;
   uninhibited:boolean;
   wasUninhibited:boolean;
   messageAttachments: MessageAttachment[];

   constructor(obj?: any) {
     this.id              = obj && obj.id              || uuid();
     this.closedByUserId  = obj && obj.closedByUserId              || '';
     this.isRead          = obj && obj.isRead          || false;
     this.sentAt          = obj && obj.sentAt          || new Date();
     this.author          = obj && obj.author          || null;
     this.text            = obj && obj.text            || '';
     this.room          = obj && obj.room          || null;
     this.owner = obj && obj.isOwner || null;
     this.isPrivate = obj && obj.isPrivate || false;
     this.isSyncMsg = obj && obj.isSyncMsg || false;
     this.msgType = obj && obj.msgType || 0;
     this.uninhibited = obj && obj.uninhibited || false;
     this.wasUninhibited = obj && obj.wasUninhibited || false;
     this.orgMsgType = obj && obj.orgMsgType || 0;//notification
     this.messageAttachments = obj && obj.messageAttachments || [];
   }
 }
