import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AdminService } from '../../service/admin.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';

@Component({
    
    templateUrl: 'passwordreset.component.html',
    selector: 'passwordreset',
    styleUrls: ['passwordreset.component.css']
})

export class PasswordResetComponent implements OnInit {
    myform: UntypedFormGroup;
    password: string;
    confirmPassword: string;
    showPasswordResetForm:boolean = false;
    fullImagePath:string;
    private userId:string;
    private token:string;

    constructor(
        private route: ActivatedRoute,
        private adminService:AdminService,
        private router:Router) { 

        }

    ngOnInit() {
        this.fullImagePath = 'assets/images/logo.png';

        this.myform = new UntypedFormGroup({
            password: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
            confirmPassword: new UntypedFormControl('', [Validators.required, Validators.minLength(6)]),
          });

        if (this.route.snapshot.queryParams["userId"] != undefined 
        && this.route.snapshot.queryParams["token"] != undefined){
             this.userId = this.route.snapshot.queryParams["userId"];
             this.token = this.route.snapshot.queryParams["token"];
            this.validateUserToken(this.userId,this.token);
        }else {
            this.router.navigate(['login']);
        }
    }

     validateUserToken(userid:any,token:any){
        this.adminService.ValidateUserToken(userid,token).subscribe(data => {
            if (data.status == 200 && data.text() == "validtoken"){
               this.showPasswordResetForm = true;
            }
        });
    }

    changePassword(data:any){

        if (data.password != data.confirmPassword){
            alert("Passwords don't match!");
            return false;
        }

        var obj = {};
        obj["userid"] = this.userId;
        obj["token"] = this.token;
        obj["password"] = data.password;
        obj["confirmPassword"] = data.confirmPassword;

        this.adminService.UpdateMentorPassword(obj).subscribe(data => {
            
            if (data == "updated"){
                alert("Password successfuly changed. Redirecting to the login page...");
                this.router.navigate(["login"]);
             }
        });
    }
 
}
