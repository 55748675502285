<!-- <p *ngIf="thisUser !== undefined"> -->

<div class="statusColor" (click)="onClickGetUser($event)" *ngIf="thisUser != null || thisUser != undefined"
     [ngClass]="{'dot': thisUser.status === 'Active',
                    'dot1': thisUser.status === 'Inactive',
                    'dot2': thisUser.status === 'Offline'}"></div>
<!-- <span class="dot"></span>  -->




<p class="memberStatus" (click)="onClickGetUser($event)" *ngIf="thisUser != null || thisUser != undefined">
  {{ thisUser.userName }} <span *ngIf="roomId==='E9908D5D-6110-41CE-880C-D2DBE272A1A9'"> ({{ thisUser.role }})</span><span class="mentor-flag" *ngIf="roomId!=='E9908D5D-6110-41CE-880C-D2DBE272A1A9' && isMentor && !isClocked"> (Mentor)</span>  
  <span class="mentor-flag" *ngIf="roomId!=='E9908D5D-6110-41CE-880C-D2DBE272A1A9' && isClocked"> (Cloaked Mentor)</span>

</p> 

