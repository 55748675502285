import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AdminService } from '../../service/admin.service';

@Component({
  selector: 'app-admin-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class AdminSettingsComponnent implements OnInit {
  
  myform: UntypedFormGroup;
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;

  constructor(private adminService:AdminService) { }

  ngOnInit() {
    this.myform = new UntypedFormGroup({
      currentPassword: new UntypedFormControl('', Validators.required),
      newPassword: new UntypedFormControl('', Validators.required),
      confirmNewPassword: new UntypedFormControl('', Validators.required),
    });
  }

  changePassword(data:any){
    this.adminService.UpdatePassword(data).subscribe(data => {
      alert(data.toString());
      window.location.reload();
    });
  }

}


