import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatLegacyPaginatorModule as MatPaginatorModule} from "@angular/material/legacy-paginator";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {MatSortModule} from "@angular/material/sort";
import {MatLegacyTableModule as MatTableModule} from "@angular/material/legacy-table";
import {MatLegacyFormFieldModule as MatFormFieldModule, MatLegacyFormField as MatFormField} from "@angular/material/legacy-form-field";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import { AdminService } from '../../service/admin.service';
import { ActivatedRoute, Router } from "@angular/router";
import { Voucher } from '../../model/voucher.model';
import { GenericSelectItem } from '../../model/genericselectitem.model';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-voucher-add-edit',
  templateUrl: './voucher-add-edit.component.html',
  styleUrls: ['./voucher-add-edit.component.css']
})

export class AdminVoucherAddEditComponent implements OnInit {

  public checked: Boolean;
  public disableInputs: Boolean = false;
  public date;
  public voucher: Voucher;
  public isPartnerSelected: Boolean = false;
  public partners: GenericSelectItem[];
  public childClients: GenericSelectItem[];
  public noOfVouchers: Number = 10;
  public selectedClient: string;

  public courses = new UntypedFormControl();
  public coursesList;
  public displayCourses: Boolean = false;
  private selectedCourses : string = "";

  constructor(
    private adminService: AdminService,
    private route: ActivatedRoute,
    private router: Router) {

    if (this.route.snapshot.paramMap.get("voucherId") != null) {
      this.getVoucherDetails(this.route.snapshot.paramMap.get("voucherId"));
    } else {
      this.voucher = new Voucher();
      this.date = new UntypedFormControl(new Date(this.voucher.expiryDate));
      this.loadPartners();
    }

    if (this.route.snapshot.paramMap.get("clientName") != null) {
      this.selectedClient = this.route.snapshot.paramMap.get("clientName");
    }
  }

  ngOnInit() {
  }

  numbersOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  getVoucherDetails(id: any): void {
    this.adminService.GetVoucherDetails(id).subscribe(data => {
      if (data) {
        this.voucher = data[0];
        this.disableInputs = true;
        this.date = new UntypedFormControl(new Date(this.voucher.expiryDate));
      } else {
        this.router.navigateByUrl('admin/vouchers');
      }
    });
  }

  onCloseClick() {
    this.router.navigateByUrl('admin/vouchers');
  }

  onSaveClick() {
    if (this.voucher.clientId == -1)
      return;

    if (this.voucher.voucherId != "-1") {
      this.adminService.UpdateVoucher(this.voucher)
        .subscribe(data => {
          alert("Changes successfuly saved!");
        },
          error => {
            alert(error.text());
          });
    }
    else {
      this.voucher.expiryDate = new Date(this.voucher.expiryDate);
      this.adminService.CreateVoucher(this.voucher, this.noOfVouchers,this.selectedCourses)
        .subscribe(data => {
          alert("Successfuly saved!");
        },
          error => {
            alert(error.text());
          });
    }
  }

  onPartnerChange(event) {
    if (event.isUserInput) {
      this.voucher.clientId = Number(event.source.value);
      // this.loadChildClients(event.source.value);

      if (this.voucher.type == 1){
        this.getCourses(this.voucher.clientId);
      }

    }
  }

  onClientChange(event) {
    if (event.isUserInput) {
      this.voucher.clientId = Number(event.source.value);
    }
  }

  loadPartners() {
    this.adminService.GetPartners("Edu").subscribe(data => {
      this.partners = data;
    });
  }

  loadChildClients(partnerId: any) {
    this.adminService.GetChildClients(partnerId).subscribe(data => {
      if (data == "undefined" || data == undefined) {
        this.isPartnerSelected = false;
      } else {
        this.isPartnerSelected = true;
      }
      this.childClients = data;
    });
  }

  onVoucherTypeChange(event:any){
    if (event == "1"){
      this.getCourses(Number(event));
    }else{
      this.coursesList = null;
      this.displayCourses = false;
    }
  }

  private getCourses(clientId: Number){
    this.selectedCourses = "";
    this.adminService.GetCoursesForClient(this.voucher.clientId)
      .subscribe(data => {
        if (data != undefined){
          this.coursesList = data;
          this.displayCourses = true;
        }else{
          this.coursesList = null;
          this.displayCourses = false;
        } 
      });
  }

  onCoursesChange(event:any){
    if (event.length > 0){
      this.selectedCourses = "";
      for (var i=0; i< event.length; i++){
        this.selectedCourses += event[i] + ",";
      }
    }else{
      this.selectedCourses = "";
    }
  }

}
