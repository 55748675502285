
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ChangeDetectorRef
} from '@angular/core';
import {
  MessagesService
} from '../../service/messages.service';
import {
  Ticket
} from '../../model/ticket';
import {
  RoomsService
} from '../../service/rooms.service';
import {
  ChatService
} from '../../service/chatservice';
import {
  TicketService
} from '../../service/ticket.service';
import {
  Observable
  ,
  BehaviorSubject
  ,
  Subject
  ,
  Subscription
} from 'rxjs';

import {
  User
} from "../../model/user";
import {
  CollectionViewer,
  DataSource
} from "@angular/cdk/collections";

import { NgxSpinnerService } from "ngx-spinner";
import { FindValueSubscriber } from "rxjs/internal/operators/find";
import { base64ToFile, ImageCroppedEvent } from 'ngx-image-cropper';
import { takeUntil } from "rxjs/operators";
import { DomSanitizer, SafeUrl } from "@angular/platform-browser";


@Component({
  selector: 'fullscreen-image-modal',
  templateUrl: './fullscreen-image-modal.component.html',
  styleUrls: ['./fullscreen-image-modal.component.css'],
})


export class FullScreenImageModalComponent implements OnInit, OnDestroy {
  allItems: Subject<any> = new Subject<any>();
  tickets: any;
  selectedUserId: any;
  getMore: number = 0;
  dataSource: any;
  noContentMessage: string = null;
  private onDestroy$: Subject<void> = new Subject<void>();
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageName: string = '';
  imageUri: SafeUrl = '';
  constructor(
    private dialogRef: MatDialogRef<FullScreenImageModalComponent>, public messagesService: MessagesService,
    public RoomsService: RoomsService, private chatService: ChatService, public ticketService: TicketService,
    @Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog,
    public viewContainerRef: ViewContainerRef, private ref: ChangeDetectorRef,
    private spinnerService: NgxSpinnerService, private sanitizer: DomSanitizer) {

    const subAllItems = this.ticketService.downloadFile(data.ContainerName, data.ImageUri).
      pipe(takeUntil(this.onDestroy$)).subscribe(
        apiResponse => {
          this.objectUrlGlobal = URL.createObjectURL(apiResponse);
          this.imageUri = this.objectUrlGlobal;
        });
  }

  public objectUrlGlobal: string;

  ngOnInit() {
  }

  save() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

  displayOriginalImageSize() {
    window.open(this.objectUrlGlobal, '_blank');
  }

}
