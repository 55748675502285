import { Component, OnInit, ComponentFactoryResolver, ViewChild, ElementRef } from '@angular/core';
import { AdminService } from '../../service/admin.service';
import { ChatUser } from '../../model/chatuser.model';
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable ,  BehaviorSubject ,  Subject } from 'rxjs';
import {
  MatLegacyInputModule as MatInputModule
} from "@angular/material/legacy-input";
import {
  MatLegacyProgressSpinnerModule as MatProgressSpinnerModule
} from "@angular/material/legacy-progress-spinner";
import {
  MatSortModule
} from "@angular/material/sort";
import {
  MatLegacyTableModule as MatTableModule
} from "@angular/material/legacy-table";
import {
  MatLegacyFormFieldModule as MatFormFieldModule
} from "@angular/material/legacy-form-field";
import {
  MatIconModule
} from "@angular/material/icon";
import {
  MatLegacyButtonModule as MatButtonModule
} from "@angular/material/legacy-button";

import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";

import {MatLegacyDialog as MatDialog,MatLegacyDialogConfig as MatDialogConfig} from "@angular/material/legacy-dialog";


import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";

import { ComponentItem } from '../../home/chat-panel/componentItem';
import { CompHolderDirective } from '../../home/chat-panel/comp-holder.directive';
import { Router } from '@angular/router';
import { ConfirmComponent } from '../../home/confirm/confirm.component';

@Component({
  selector: 'app-admin-mentor-management',
  templateUrl: './mentor-management.component.html',
  styleUrls: ['./mentor-management.component.css']
})

export class AdminMentorsManagementComponent implements OnInit {
  defaultPageNumber: number = 1;
  defaultPageSize: number = 10;
  defaultSearchCriteria: string = "";
  allItems: any[] = [];
  perPage: number = 10;
  fullLength: number;
  currentPage: number;
  totalPages: number;
  currentClickedUser: any;
  public userId: string;
  public chatUsers: ChatUser[];
  dataSource: ChatUserDataSource;
  displayedColumns = ["id", "name", "email", "role","edit","logout", "delete"];

  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild('input',{static:false}) input: ElementRef;
  @ViewChild(CompHolderDirective,{static:true}) adHost: CompHolderDirective;

  menuComponents: ComponentItem[];
  public showParentComponent: Boolean = true;
  public showChildComponent: Boolean;
  public chatUserObject: ChatUser;
  private searchCriteria$ = "";
  public showSpinner: Boolean = true;

  constructor(
    private adminService: AdminService, 
    public componentFactoryResolver: ComponentFactoryResolver,
    private router:Router,
    private dialog: MatDialog) {
    this.countUsers(this.defaultSearchCriteria);
  }

  onChatUserCloseClickHandled(data: any) {
    this.ngOnInitPage();
    this.showChildComponent = false;
    this.showParentComponent = true;
  }

  ngOnInit() {
    this.ngOnInitPage();
    this.showSpinner = false;
  }

  ngOnInitPage() {
    this.dataSource = new ChatUserDataSource(this.adminService);
    this.dataSource.loadChatUsers(this.defaultPageNumber, this.defaultPageSize, this.defaultSearchCriteria);
  }

  onPageChanged(paginator: any) {
    this.dataSource.loadChatUsers(paginator.pageIndex + 1, paginator.pageSize, this.searchCriteria$);
  }

  countUsers(searchCriteria: string) {
    this.adminService.CountUsers(searchCriteria,true, false, false).subscribe(data => {
      this.fullLength = data;
    });
  }

  onEdit(data: any) {
    this.showParentComponent = false;
    this.chatUserObject = data;
    this.showChildComponent = true;
  }

  onSearchClick(searchCriteria: string) {
    this.showSpinner = true;

    if (searchCriteria) {
      this.searchCriteria$ = searchCriteria;
    } else {
      this.searchCriteria$ = "";
    }

    this.countUsers(this.searchCriteria$);
    this.dataSource.loadChatUsers(this.defaultPageNumber, this.defaultPageSize, this.searchCriteria$);
    this.showSpinner = false;
  }

  onCreateNewClick(){
    this.router.navigate(['./add']);
    return false;
  }

  onLogoutClick(userId:any,name:any){
   this.openDialogRemoveActiveSessions(userId,name);
  }

  onDeleteClick(userId:any,name:any){
    this.openDialogDelete(userId,name);
   }

   openDialogRemoveActiveSessions(userId:any,name:any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    var warningMessage = "This will remove all active sessions for mentor '" + name + "' Are you sure you want to proceed?";
    const dialogRef = this.dialog.open(ConfirmComponent, {
      height: '300px',
      width: '400px',
      data: { warningMessage: warningMessage}
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.showSpinner = true;
          this.adminService.ClearActiveSessions(userId).subscribe(data => {
            this.showSpinner = false;
            alert("Success");       
          });
        }
      }
    );
  }

  openDialogDelete(userId:any,name:any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    var warningMessage = "Are you sure you want to deactivate mentor " + name + "?";
    const dialogRef = this.dialog.open(ConfirmComponent, {
      height: '300px',
      width: '400px',
      data: { warningMessage: warningMessage}
    });

    dialogRef.afterClosed().subscribe(
      result => {
        if (result) {
          this.showSpinner = true;
          this.adminService.DeactivateUser(userId).subscribe(data => {
            alert("Success");
            this.countUsers(this.defaultSearchCriteria);
            this.dataSource.loadChatUsers(this.defaultPageNumber, this.defaultPageSize, this.defaultSearchCriteria);
            this.showSpinner = false;   
          });
        }
      }
    );
  }
}

export class ChatUserDataSource implements DataSource<any> {
  private chatUsersSubject = new BehaviorSubject<ChatUser[]>([]);
  constructor(private adminService: AdminService) { }

  connect(collectionViewer: CollectionViewer): Observable<ChatUser[]> {
    return this.chatUsersSubject.asObservable();
  }
  disconnect(collectionViewer: CollectionViewer) {
    this.chatUsersSubject.complete();
  }

  loadChatUsers(pageIndex: number, pageSize: number, searchCriteria: string) {
    this.adminService.GetUsers(pageIndex, pageSize, searchCriteria, true, false, false).subscribe(data => {
      this.chatUsersSubject.next(data);
    });
  }
}


