export class Voucher {
    voucherId: string;
    clientId: Number;
    validFor: Number;
    courseId: Number;
    redeembedBy: Number;
    expiryDate: Date;
    isTest: Boolean;
    type: Number;
    clientName:string;
    redeemedByUser: string;
    courses: string;

    constructor(obj?: any) {
        this.voucherId = obj && obj.voucherId || '-1';
        this.clientId = obj && obj.clientId || "-1";
        this.validFor = obj && obj.validFor || "90"
        this.courseId = obj && obj.courseId || '-1';
        this.redeembedBy = obj && obj.redeembedBy || '-1';
        this.expiryDate = obj && obj.expiryDate || new Date().setDate(new Date().getDate() + 90)
        this.isTest = obj && obj.isTest  || false;
        this.type = obj && obj.type || '0';
        this.clientName = obj && obj.clientName || "";
        this.redeemedByUser = obj && obj.redeemedByUser || "";
        this.courses = obj && obj.courses || "";
    }

    public static CreateVoucher(clientVM: any): Voucher {
        const me: Voucher = new Voucher({
            voucherId: clientVM.VoucherId,
            clientName: clientVM.ClientName,
            redeemedByUser: clientVM.RedeemedByUser,
            validFor: clientVM.ValidFor,
            expiryDate: new Date(clientVM.ExpiryDate).toLocaleDateString("en-US")
        });
        return me;
    }

}
