
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ChangeDetectorRef
} from '@angular/core';
import {
  MessagesService
} from '../../service/messages.service';
import {
  Ticket
} from '../../model/ticket';
import {
  RoomsService
} from '../../service/rooms.service';
import {
  ChatService
} from '../../service/chatservice';
import {
  TicketService
} from '../../service/ticket.service';
import {
  Observable
, 
  BehaviorSubject
, 
  Subject
, 
  Subscription
} from 'rxjs';

import {
  User
} from "../../model/user";
import {
  TranscriptModalComponent
} from '../transcript-modal/transcript-modal.component';
import {
  CollectionViewer,
  DataSource
} from "@angular/cdk/collections";
// import {
//   Ng4LoadingSpinnerService
// } from 'ng4-loading-spinner';
import { NgxSpinnerService } from "ngx-spinner";
import { FindValueSubscriber } from "rxjs/internal/operators/find";
@Component({
  selector: 'app-all-tickets-modal',
  templateUrl: './all-tickets-modal.component.html',
  styleUrls: ['./all-tickets-modal.component.css'],
})

export class AllTicketsModalComponent implements OnInit, OnDestroy {
  allItems: Subject<any> = new Subject<any>();
  tickets: any;
  selectedUserId: any;
  getMore: number = 0;
  displayedColumns = ["TicketNumber", "TicketSourceName", "Title"];
  dataSource: any;
  noContentMessage: string = null;
  private onDestroy$: Subject<void> = new Subject<void>();
  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;

  constructor(
    private dialogRef: MatDialogRef<AllTicketsModalComponent>, public messagesService: MessagesService,
    public RoomsService: RoomsService, private chatService: ChatService, public ticketService: TicketService,
    @Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog,
    public viewContainerRef: ViewContainerRef, private ref: ChangeDetectorRef,
    private spinnerService: NgxSpinnerService) {
    
    const subAllItems = this.allItems.subscribe(items => {
      if (typeof (data.ticketsArray) != 'string') {
        this.dataSource = new MatTableDataSource(this.tickets);
        setTimeout(() => this.dataSource.paginator = this.paginator);
      } else {
        this.noContentMessage = data.ticketsArray;
      }
    });

    this.tickets = data.ticketsArray;
    this.selectedUserId = data.userId;
    this.allItems.next(data);

  }



  ngOnInit() {
  }

  clickedTicket(ticket: Ticket) {
    if (ticket.TicketSourceName == "Chat") {
      this.spinnerService.show();
      const subGetChatTranscriptByTicketId = this.ticketService.getChatTranscriptByTicketId(ticket.ID).subscribe(
        data => {
          this.openCurrentTranscriptDialog(data);
          this.spinnerService.hide();
        }
      );
    } else {
      window.open("https://ww3.autotask.net/Mvc/ServiceDesk/TicketDetail.mvc?openerPage=null&ticketId=" + ticket.ID, "_blank");
    }
  }

  public openCurrentTranscriptDialog(messages: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    dialogConfig.data = messages;
    dialogConfig.height = '600px';
    dialogConfig.width = '800px';
    
    const dialogRef = this.dialog.open(TranscriptModalComponent, dialogConfig);
  }

  seeMore() {
    this.getMore--;
    this.spinnerService.show();

    const subGetTicketByUserPerMonth = this.ticketService.getTicketsByUserPerMonth(this.getMore, this.selectedUserId)
      .subscribe((data) => {
        if (typeof (data.ticketsArray) != 'string') {
          this.tickets = this.tickets.concat(data);
          this.allItems.next(this.tickets);
        } else {
          this.noContentMessage = data.ticketsArray;
        }
        this.spinnerService.hide();
      });

  }

  save() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }


  public ngOnDestroy(): void {
    this.close();
    this.onDestroy$.next();
  }

}
