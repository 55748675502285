export class UserRole {
    id: string;
    name: string;
    

    constructor(obj?: any) {
        this.id = obj && obj.id || "";
        this.name = obj && obj.name || "";
    }

    public static createRole(userRoleVm: any): UserRole {
        const me: UserRole = new UserRole({
            id: userRoleVm.Id,
            name: userRoleVm.Name,
            
        });
        return me;
    }
}
