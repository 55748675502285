import { User } from "./user";
import { Client } from "./client.model";
import { uuid } from "../home/util/uuid";

/**
 * Message represents one message being sent in a Room
 */
export class UsersInfo {
    user: User;
    client: Client;
    activity: string;
    clientId: number;

    constructor(user: User, client: Client, clientId: number) {
        this.user = user;
        this.client = client;
        if(client.isActive == true){
            this.activity = 'Active';
        }else{
            this.activity = 'Not Active'
        }
        this.clientId = clientId;
    }
}