import { Component, OnInit, ViewChild, ViewContainerRef, ComponentFactoryResolver, ReflectiveInjector, Input } from '@angular/core';
import { IssueService } from '../service/issueservice';


import { ComponentItem } from '../home/chat-panel/componentItem';
import { User } from '../model/user';
import { ChatService } from '../service/chatservice';
import { MessagesService } from '../service/messages.service';
import { RoomsService } from '../service/rooms.service';
import { Room } from '../model/room.model';
import { AdminService } from '../service/admin.service';
import { AlertService } from '../service/alert.service';
import { AuthenticationService } from '../service/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import {Router} from '@angular/router';
import { MaintenanceMode } from '../model/maintenancemode.model';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css'],
  providers: [IssueService,AdminService]
})

export class AdminComponent implements OnInit {
  currentComponent = null;
  data: any;
  email: any;
  user: any;
  menuComponents: ComponentItem[];
  currentUser: User;
  currentRoom: Room;
  returnUrl: string;
  loading = false;
  loadingImage: string;
  public isAuthenticated = false;
  private CONST_GMSMENTORS_GROUPID: number = 1;
  private maintenanceMode: MaintenanceMode;
  
  constructor(
    public cookieService:CookieService,
    public chatService: ChatService,
    public messageService: MessagesService,
    public roomService: RoomsService,
    public adminService:AdminService,
    public alertService:AlertService,
    public authenticationService:AuthenticationService,
    private router:Router) {

      this.adminService.CheckIfMaintenanceModeIsEnabled(this.CONST_GMSMENTORS_GROUPID).subscribe(
            data => {
                this.maintenanceMode = data[0];
                if (!this.maintenanceMode.loginAllowed) {
                    this.router.navigate(['/maintenance'], { queryParams: { q: this.CONST_GMSMENTORS_GROUPID } });
                }else{
                  if (this.router.url == "/admin"){
                    this.router.navigate(['/admin/mentors']);
                   }
                }
            });   
  }

  ngOnInit() {
  }

  logout() {          
    this.cookieService.deleteAll();
    localStorage.removeItem('currentUser');
    localStorage.removeItem('currentAdminUser');
    localStorage.removeItem('currAuthor');
    this.authenticationService.logout();
    this.router.navigate(['/admin']);
    window.location.reload();
  }
}



