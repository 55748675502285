

import { takeUntil} from 'rxjs/operators';
import { Component, OnInit, Input, Injector, ViewChild, ViewContainerRef, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable ,  BehaviorSubject, ReplaySubject, of, combineLatest } from 'rxjs';
import { RegisterProcessComponent } from '../register-process';
import { AlertService } from '../../service/alert.service';
import { UsersService } from '../../service/users.service';
import { MappingFields } from '../../model/MappingFields.model';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { User } from '../../model/user';
import { Message } from '../../model/message.model';
import { MessagesService } from '../../service/messages.service';
import { Room } from '../../model/room.model';
import { ChatService } from '../../service/chatservice';
import { RoomsService } from '../../service/rooms.service';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import { LocalizationService } from '../../service/localization.service';
import { MentorSpinnerService } from '../../service/mentorspinner.service';
import {CustomizationService} from '../../service/customization.service';
import { AppConfig } from '../../AppConfig';
import { ChatPersonalization } from '../../model/chatpersonalization.model';
import { RegisterChatEduModel } from '../../model/registerChatEduModel';
import { ChatLabelSetup } from '../../model/ChatLabels.model';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { TicketService } from '../../service/ticket.service';

@Component({
  selector: 'app-register-chat-edu',
  templateUrl: './register-chat-edu.component.html',
  styleUrls: ['./register-chat-edu.component.css']
})
export class RegisterChatEduComponent implements RegisterProcessComponent, OnDestroy {
  [x: string]: any;
  @Input() data: any;

  model: any = {};
  showSelected: boolean = false;
  messageText: string;
  draftMessage: Message;
  paramWidget: boolean = false;
  messages: Observable<any>;
  myform: UntypedFormGroup;
  currentRoom: Room;
  selectedIssue: MappingFields = new MappingFields();
  userObject: User[];
  isChatInit: boolean;
  showFormErrors: boolean = false;
  emailPattern = "[a-zA-Z0-9.-_-]{1,}@[0-9a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  isValidFormSubmitted = null;
  disableSignInButton: boolean = false;
 
  public colorFileFullPath: string = '';
  public chatColor: string = '#0061AA';
  public butonTextColor:string='#FFFFFF';
  private pageName = "RegisterChatEduComponent";
  public pageTranslation: any = "";
  public twoCombined = new Observable<boolean>();
  public personalizationRetrived = new BehaviorSubject<boolean>(false);
  public pageTranslated = new BehaviorSubject<boolean>(false);
  public componentData:RegisterChatEduModel;
  public customChatLabelsSetup : ChatLabelSetup;
  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  public logoRetrived = new BehaviorSubject<boolean>(false);
  public logoFullPath: SafeUrl = '';

  constructor(private customizationService: CustomizationService, private mspinnerService: MentorSpinnerService, 
    private router: Router, private localizationService: LocalizationService, private activatedRoute: ActivatedRoute,
    private userService: UsersService, private alertService: AlertService, fb: UntypedFormBuilder, injector: Injector, 
    public msgService: MessagesService, public chatService: ChatService,
     public roomsService: RoomsService, private config: AppConfig, public ticketService: TicketService,
    private sanitizer: DomSanitizer ) {
      

      const subAllItems = this.ticketService.downloadPartnerLogoFile('partners-logos', this.userService.getClientIdParam().toString() + '.png').
      pipe(takeUntil(this.onDestroy$)).subscribe(
        apiResponse => {

          if(apiResponse.size>0)
          {
            var url = URL.createObjectURL(apiResponse);
            this.logoFullPath = url;
          }else
          {
            this.logoFullPath ='assets/images/logo.png';
          }
          
          this.logoRetrived.next(true);
        },error => { console.log(error)
          this.logoFullPath ='assets/images/logo.png';
          this.logoRetrived.next(true);
         });
      //this.logoFullPath =customizationService.getNoCacheImage(this.config.logoURL + userService.getClientIdParam().toString() + '.png');
    //   this.colorFileFullPath = this.config.logoURL + this.userService.getClientIdParam().toString() + '.txt';

    // this.chatColor = customizationService.getPersonalizedColor(this.colorFileFullPath);
    // this.butonTextColor=userService.invertColor(this.chatColor,true);

    this.userService.cpSubject.subscribe(
      (data: ChatPersonalization) => {
        
        this.chatColor= data.colorCode;
        this.butonTextColor=userService.invertColor(data.colorCode,true);
        this.personalizationRetrived.next(true);
        //
      });

      
      combineLatest([this.pageTranslated, this.personalizationRetrived,  this.logoRetrived]).subscribe(
        ([pageT, personalizationR, logoReturned]) => {
          
            this.twoCombined=of(pageT && personalizationR  && logoReturned);
        });    

    this.SetLocalization();

  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['isWidget']) {
        this.paramWidget = true;
      } 

    });
    this.draftMessage = new Message();
    this.data = false;
    this.myform = new UntypedFormGroup({
      firstName: new UntypedFormControl(),
      lastName: new UntypedFormControl(),
      hostName: new UntypedFormControl(),
      courseName: new UntypedFormControl(),
      email: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(this.emailPattern)
      ])),
      message: new UntypedFormControl('', [Validators.required, this.noWhitespaceValidator]),
      isAtDown: new UntypedFormControl()
    });

    if(this.componentData!=undefined)
    {
      this.myform.patchValue({
        firstName: this.componentData.firstName, 
        lastName: this.componentData.lastName,
        email:this.componentData.email,
        courseName:this.componentData.courseName,
        message:this.componentData.message
      });

      this.draftMessage.text=this.componentData.message;
    }

    this.router.navigate([], {
      queryParams: {
        'firstName': null,
        'lastName': null,
        'courseName': null,
        'email': null,
        'message': null,
      },
      queryParamsHandling: 'merge'
    });
  }

  registerUser(model: any): void {
    this.isValidFormSubmitted = false;
     if (this.myform.invalid) {
        return;
     }
     
     this.disableSignInButton = true;
     this.mspinnerService.ShowSpinner();
     this.isValidFormSubmitted = true;
     this.myform.controls["isAtDown"].setValue(false);
     model.isAtDown = false;

    this.userService.RegisterToChat(model)
      .subscribe(
        data => {          
          this.alertService.success('User exists', true);
            this.data = true;
            this.userService.checkUser(model);
            
            localStorage.setItem("clientInitMessage",model.message);
            this.msgService.clientInitMessage.next(model.message);

            if(model.courseName)
              this.msgService.eduSendClassNameMessage.next(model.courseName);
        },
        error => {
          this.mspinnerService.HideSpinner();
          if (error.status == 404) {
            this.alertService.error("User not found!");
            this.data = false;
          }
          else{
            this.userService.UserNotFound(error.status.toString());
            return;
          }
          
        });
  }

  notValid(){
    this.showFormErrors = true;
  }

  SetLocalization() {
    var localeFromUrl = this.userService.getClientLanguageParam();
    var locale = this.localizationService.ValidateLocale(localeFromUrl);
    var clientId = Number.parseInt(this.userService.getClientIdParam());

    this.localizationService.GetLocalizationData().subscribe(data => {
      this.pageTranslation = data[0][this.pageName][locale];
      this.pageTranslated.next(this.pageTranslation);
    },error => { console.log(error) }, () => { });

    this.localizationService.GetCustomLabels(clientId,locale).subscribe(data => {
      this.customChatLabelsSetup = data;

      if(this.customChatLabelsSetup.Status == true){
        let customWelcomeToYourHelpDeskLbl = this.customChatLabelsSetup.Labels.filter(i => i.Type === 'WelcomeToYourHelpDesk');
        if(customWelcomeToYourHelpDeskLbl != null && customWelcomeToYourHelpDeskLbl != undefined && customWelcomeToYourHelpDeskLbl.length > 0){
          this.pageTranslation.PleaseSignIn = "";
          this.pageTranslation.WelcomeToYourHelpDesk = customWelcomeToYourHelpDeskLbl[0].Value;
        }

        let customSignInLbl = this.customChatLabelsSetup.Labels.filter(i => i.Type === 'SignIn');
        if(customSignInLbl != null && customSignInLbl != undefined && customSignInLbl.length > 0){
          this.pageTranslation.SignIn = customSignInLbl[0].Value;
        }
      }
      
    },error => { console.log(error) }, () => { });
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {
      'whitespace': true
    };
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
