
import {takeUntil} from 'rxjs/operators';
import {
  Component,
  OnInit,
  Inject,
  EventEmitter,
  Output,
  ChangeDetectorRef,
  ElementRef,
  ViewChild,
  OnDestroy,
  ChangeDetectionStrategy,
  Input
} from '@angular/core';
import { Observable, Subject,ReplaySubject } from 'rxjs';
import { RoomsService } from '../../service/rooms.service';
import { GroupByPipe } from '../pipes/GroupByPipe';
import { Room, RoomGroup } from '../../model/room.model';
import { ChatRoomToBeClosedComponent } from '../chat-room-to-be-closed/chat-room-to-be-closed.component';
import { MessagesService } from '../../service/messages.service';
import { messageFactory } from '../../model/messageFactory';
import { ChatRoomComponent } from '../chat-room/chat-room.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from '@angular/material/legacy-dialog';
import { ChatService } from '../../service/chatservice';
import { ConfirmComponent } from '../confirm/confirm.component';

@Component({
  selector: 'chat-room-notification',
  templateUrl: './chat-room-notification.component.html',
  styleUrls: ['./chat-room-notification.component.css']
})
export class ChatRoomNotificationComponent implements OnInit, OnDestroy {
  //@Input() room: Room;
  @Input() room: Room;
  private onDestroy$:ReplaySubject<boolean> = new ReplaySubject(1);
  lastMessageSendAt: Date = new Date();
  selected = false;
  avatarSrc: string;
  roomTimer: string;
  intervalHolder: any;
  ticketCreated: boolean;

  constructor(private dialog: MatDialog, public roomsService: RoomsService, public chatService: ChatService, public el: ElementRef, private cd: ChangeDetectorRef) {


  }


  ngOnInit(): void {
    
    this.ticketCreated = false;
    const subCurrentRoom = this.roomsService.currentRoom.pipe(
    takeUntil(this.onDestroy$))
      .subscribe((currentRoom: Room) => {
        this.selected = currentRoom && this.room && (currentRoom.id === this.room.id);
      });
    this.avatarSrc = 'assets/images/avatar.png';

    // this.intervalHolder = Observable.interval(1 * 1000).subscribe(x => { // will execute every 1 second      
    //   this.roomTimer = this.GetFormatedTime(new Date(this.room.createdAt).getTime());
    //   this.cd.markForCheck();
    // });

  }


  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(ConfirmComponent, {
      height: '250px',
      width: '400px',
      data: { warningMessage: "Enter as cloaked user?" }
    });

    const subDialogRef=  dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(
      val => {
        if (val !== null && val != undefined) {

          this.setCurrentRoom(this.room,val);
        }
      }
    );
  }

  setCurrentRoom(room:Room, isCloacked:boolean):void{

    this.roomsService.setCurrentRoom(room);
    this.chatService.joinRoom(room, isCloacked);

  
  }

  joinRoom(): void {
    this.roomsService.setIsAllSessionsClicked(true);
    this.roomsService.handleChatOrTicketWindow.next('test' + this.room.name);

    if (this.room.name == "Lobby") {

      this.setCurrentRoom(this.room,false);
    }
    else
      if (this.room.mentorCount <= 0 || this.room.currMentorIsPartOfTheRoom) {

        this.setCurrentRoom(this.room,false);
        
        if (this.ticketCreated == false) {
          //this.chatService.createTicket(this.room.id);
          this.ticketCreated = true;
        }
      }
      else {
        this.openDialog();
      }

  }

  getMentorName(): String {

    if (this.room.lastMessage != undefined)
      return this.room.lastMessage.author.userName;
    return "";
  }

 

    
 
 


  getRoomColor(): String {

    let delta = 100;
    if (this.room.createdAt != undefined) {
      let now = new Date().getTime();
      delta = (now - new Date(this.room.createdAt).getTime()) / 1000;
    }

    let rez = '#ffe6be';
    if (this.room.roomStatus == 1 && delta < 30)
      rez = '#ffe6be';
    else
      rez = this.getRoomStatus(this.room.roomStatus);

    return rez;
  }


  getRoomNameColor(): String {

    let delta = 100;
    if (this.room.createdAt != undefined) {
      let now = new Date().getTime();
      delta = (now - new Date(this.room.createdAt).getTime()) / 1000;
    }

    let rez = '#000';
    // if (this.room.roomStatus == 1 && delta < 30)
    //   rez = '#ef9a14';
    // else
    //   rez = this.getRoomNameStatus(this.room.roomStatus);

    return rez;
  }
  getRoomStatus(tmp): string {

    switch (tmp) {
      case 1: {
        return '#fdc7cd';//red;
      }
      case 2: {
        return '#cecece59';///blue(lobby);
      }
      case 3: {
        return '#e0ffed';//green;
      }
      case 4: {
        return '#afddff';//'#D8F9FF';
      }
      default: {
        return '#775fe1';
      }
    }

  }

  getRoomNameStatus(status): string {

    switch (status) {
      case 1: {
        return '#da3939';//red;
      }
      case 2: {
        return 'grey';//blue(lobby);
      }
      case 3: {
        return '#269454';//green;
      }
      case 4: {
        return '#389eea';//blue;#
      }
      default: {
        return '#775fe1';
      }
    }

  }

  private GetFormatedTime(value: any): string {

    let now = new Date().getTime();

    let delta = 10;
    let tmp = (now - value);
    if (tmp > 0)
      delta = tmp / 1000;

    if (this.getHours(delta) > 0)
      return this.getHours(delta) + ':' + this.getMinutes(delta) + ':' + this.getSeconds(delta);
    else
      return this.getMinutes(delta) + ':' + this.getSeconds(delta);
  }

  private getSeconds(ticks: number) {
    return this.pad(Math.floor(ticks % 60));
  }

  private getMinutes(ticks: number) {
    return this.pad((Math.floor(ticks / 60)) % 60);
  }

  private getHours(ticks: number) {
    return this.pad(Math.floor((ticks / 60) / 60));
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }



  ngOnDestroy() {

    clearInterval(this.intervalHolder);
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
