<!-- <div class="media conversation"> -->
<div class="media conversation closechatroom" style="background-color: #cecece;color:black">

  <div class="pull-left">
    <!-- <img class="media-object avatar"
               src="{{room.avatarSrc}}"> -->
    <!-- <img [src]="avatarSrc"> -->
  </div>
  <div class="pull-left nameTime">
    <!-- <img class="media-object avatar"
              src="{{room.avatarSrc}}"> -->

    <!-- <img [src]="avatarSrc"> -->
    <!--<small class="message-preview">{{room.roomStatus}}</small>-->
    <!--<small class="message-preview">{{getTicketTitle()}}</small>-->
    <!--<small class="message-preview">{{getMentorName()}}</small>-->
    <!--<small class="message-preview">{{getChatStartTime()}}</small>-->
    <!--<small class="message-preview">{{roomTimer}}</small>-->
  </div>
  <div class="media-body positionDot">
    <h5 class="media-heading contact-name nameContact" style="font-size: 12px; width: 95%">{{getTicketTitle()}}

    </h5>

    <!-- <span class="positionSpan" *ngIf="selected">&#x2605;</span> -->

    <span class="positionSpan" *ngIf="selected">
      <img src="assets/images/pin-icon.png" />
    </span>

    <small class="message-preview">{{room.session}}</small>  
    <!--<small class="message-preview">{{room.type}}</small>
    <small class="message-preview">{{room.language}}</small>-->


  </div>

  <!-- <a (click)="clicked($event)" class="div-link">Select</a> -->
</div>
