import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { Router, ActivatedRoute } from '@angular/router';
import { AlertService } from '../../service/alert.service';
import { AuthenticationService } from '../../service/authentication.service';
import { ChatService } from '../../service/chatservice';
import { ChatVolumeModalComponent } from '../../home/chat-volume-modal/chat-volume-modal.component';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})

export class LogoutComponent implements OnInit {
  model: any = {};
  loading = false;
  returnUrl: string;

  constructor(
      private route: ActivatedRoute,
      private router: Router,
      private authenticationService: AuthenticationService,
      private alertService: AlertService,
      private dialog: MatDialog,
      private chatService: ChatService  ) { }

  ngOnInit() {
      // reset login status
      //this.authenticationService.logout();

      // get return url from route parameters or default to '/'
    //testsetset
  }

  logout() {  
      this.loading = true;
      this.chatService.LogOut();     
  }

  
}

