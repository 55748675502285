import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpResponse, HttpHandler } from '@angular/common/http';

import { tap } from 'rxjs/operators';
import { CacheMapService } from './cache-map.service';
import { of } from 'rxjs';


const CACHABLE_URL = "/api";

@Injectable()
export class CachingInterceptor implements HttpInterceptor {
    constructor(private cache: CacheMapService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRequestCachable(req)) { 
           return next.handle(req); 
        }
        const cachedResponse = this.cache.get(req);
        if (cachedResponse !== null) {
           return of(cachedResponse);
        }
        return next.handle(req).pipe(
           tap(event => {
              if (event instanceof HttpResponse && this.isRequestCachable(req)) {
                this.cache.put(req, event); 
              }
           })
        );
    }
    private isRequestCachable(req: HttpRequest<any>) {
        //if(req.url.indexOf("/api/autotask/download") > -1) return true;

        return false;
        //return (req.method === 'GET') && (req.url.indexOf(CACHABLE_URL) > -1);
    }
} 