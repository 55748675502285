import { Component, OnInit, ComponentFactoryResolver, ViewChild, ElementRef } from '@angular/core';
import { AdminService } from '../../service/admin.service';
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable ,  BehaviorSubject } from 'rxjs';
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatLegacyPaginatorModule as MatPaginatorModule,MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {MatSortModule} from "@angular/material/sort";
import {MatLegacyTableModule as MatTableModule} from "@angular/material/legacy-table";
import {MatLegacyFormFieldModule as MatFormFieldModule, MatLegacyFormField as MatFormField} from "@angular/material/legacy-form-field";
import {MatIconModule} from "@angular/material/icon";
import { ComponentItem } from '../../home/chat-panel/componentItem';
import { CompHolderDirective } from '../../home/chat-panel/comp-holder.directive';
import { Router } from '@angular/router';
import { Voucher } from '../../model/voucher.model';

@Component({
  selector: 'app-admin-vouchers',
  templateUrl: './vouchers.component.html',
  styleUrls: ['./vouchers.component.css']
})

export class AdminVouchersManagement implements OnInit {
  defaultPageNumber: number = 1;
  defaultPageSize: number = 10;
  defaultSearchCriteria: string = "";
  allItems: any[] = [];
  perPage: number = 10;
  fullLength: number;
  currentPage: number;
  totalPages: number;
  public vouchers: Voucher[];
  dataSource: VoucherDataSource;
  displayedColumns = ["voucherId", "clientName", "redeemedByUser","validFor", "expiryDate","details"];

  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild('input',{static:false}) input: ElementRef;
  @ViewChild(CompHolderDirective,{static:true}) adHost: CompHolderDirective;

  menuComponents: ComponentItem[];
  public showChildComponent: Boolean;
  public voucherObject: Voucher;
  private searchCriteria$ = "";
  public showSpinner: Boolean = true;

  constructor(
    private adminService: AdminService, 
    public componentFactoryResolver: ComponentFactoryResolver,
    private router:Router) {
    this.countVouchers(this.defaultSearchCriteria);
  }

  ngOnInit() {
    this.ngOnInitPage();
    this.showSpinner = false;
  }

  ngOnInitPage() {
    this.dataSource = new VoucherDataSource(this.adminService);
    this.dataSource.loadVouchers(this.defaultPageNumber, this.defaultPageSize, this.defaultSearchCriteria);
  }

  onPageChanged(paginator: any) {
    this.dataSource.loadVouchers(paginator.pageIndex + 1, paginator.pageSize, this.searchCriteria$);
  }

  countVouchers(searchCriteria: string) {
    this.adminService.CountVouchers(searchCriteria).subscribe(data => {
      this.fullLength = data;
    });
  }

  onSearchClick(searchCriteria: string) {
    this.showSpinner = true;
    if (searchCriteria) {
      this.searchCriteria$ = searchCriteria;
    } else {
      this.searchCriteria$ = "";
    }

    this.countVouchers(this.searchCriteria$);
    this.dataSource.loadVouchers(this.defaultPageNumber, this.defaultPageSize, this.searchCriteria$);
    this.showSpinner = false;
  }

  onCreateNewClick(){
    this.router.navigate(['./add']);
    return false;
  }
}

export class VoucherDataSource implements DataSource<any> {
  private vouchersSubject = new BehaviorSubject<Voucher[]>([]);
  constructor(private adminService: AdminService) { }

  connect(collectionViewer: CollectionViewer): Observable<Voucher[]> {
    return this.vouchersSubject.asObservable();
  }
  disconnect(collectionViewer: CollectionViewer) {
    this.vouchersSubject.complete();
  }

  loadVouchers(pageIndex: number, pageSize: number, searchCriteria: string) {
    this.adminService.GetVouchers(pageIndex, pageSize, searchCriteria).subscribe(data => {
      this.vouchersSubject.next(data);
    });
  }
}


