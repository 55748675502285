<ng-template appCompHolder> </ng-template>

<div id="toggleReg"  *ngIf="twoCombined | async">
  <div class="regHeader" [style.background-color]="chatColor">
    <img class="partner-logo" [src]="logoFullPath | safe"  >
  </div>

  <div class="regBody">

    <h1 class="cred1"> 
      <p [innerHtml]="pageTranslation.WelcomeToYourHelpDesk"></p>
      <!-- <br /> -->
      <p [innerHtml]="pageTranslation.PleaseSignIn"></p>    
    </h1>
        
    <form name="form" (ngSubmit)="disableSignInButton || registerUser(myform.value)" [formGroup]="myform">
      <!--EMAIL-->
      <div class="form-group">
        <label for="email"> {{pageTranslation.Email}} </label>
        <input type="email"
               class="form-control emailfield"
               formControlName="email"
               [readonly]="verificationReadonly"
               required />

        <div class="validation-errors">
          <div class="error-message" *ngIf="myform.get('email').hasError('pattern') && (myform.get('email').dirty || myform.get('email').touched)">{{pageTranslation.ValidEmail}}</div>
          <div class="error-message" *ngIf="myform.get('email').hasError('required') && (myform.get('email').dirty || myform.get('email').touched) || (isValidFormSubmitted == false && myform.get('email').hasError('required'))">{{pageTranslation.EmailRequired}}</div>
        </div>
      </div>

      <!--FNAME-->
      <div class="form-group">
        <label for="firstName"> {{pageTranslation.FirstName}} </label>
        <input type="text"
               class="form-control fnamefield"
               formControlName="firstName"
               [readonly]="verificationReadonly"
               required />

        <div class="validation-errors">
          <div class="error-message" *ngIf="myform.get('firstName').hasError('required') && (myform.get('firstName').dirty || myform.get('firstName').touched) || (isValidFormSubmitted == false && myform.get('firstName').hasError('required'))">{{pageTranslation.FNameRequired}}</div>
        </div>
      </div>

      <!--LNAME-->
      <div class="form-group">
        <label for="lastName"> {{pageTranslation.LastName}} </label>
        <input type="text"
               class="form-control lnamefield"
               formControlName="lastName"
               [readonly]="verificationReadonly"
               required />

        <div class="validation-errors">
          <div class="error-message" *ngIf="myform.get('lastName').hasError('required') && (myform.get('lastName').dirty || myform.get('lastName').touched) || (isValidFormSubmitted == false && myform.get('lastName').hasError('required'))">{{pageTranslation.LNameRequired}}</div>
        </div>
      </div>

         <!--HOST NAME-->
         <div class="form-group">
          <label for="hostName">{{pageTranslation.HostName}}</label>
          <i class="fa fa-info-circle info-size" 
          matTooltip="{{pageTranslation.HostNameTooltip}}"
          [matTooltipPosition]="'right'"
          [matTooltipDisabled]="true"
          #myTooltip="matTooltip" 
          (mouseover)="displayTooltip('mouseover')"
          (mouseout)="closeTooltip()"
          (click)="displayTooltip('click')"></i>
          
          <input type="text" class="form-control hostnamefield" formControlName="hostName" />
        </div>

      <!--MESSAGE-->
      <div class="form-group">
        <label for="message">{{pageTranslation.Message}}</label>

        <textarea class="form-control messagefield" placeholder="{{pageTranslation.TypeYourMessage}}" 
                  formControlName="message"  [readonly]="verificationReadonly" required></textarea>

        <div class="validation-errors">
          <div class="error-message" *ngIf="myform.get('message').hasError('required') && (myform.get('message').dirty || myform.get('message').touched) || (isValidFormSubmitted == false && myform.get('message').hasError('required'))">{{pageTranslation.MessageRequired}}</div>
          <div class="error-message" *ngIf="(isValidFormSubmitted == false && myform.get('message').hasError('whitespace'))">{{pageTranslation.ValidMessage}}</div>
        </div>
      </div>

      <br />

      <div class="form-group" *ngIf="showVerificationCode">
        <label for="lastName">{{pageTranslation.VerificationCode}} </label>
        <input type="text"
               class="form-control lnamefield"
               formControlName="verificationCode"
               required />
         
        <div class="validation-errors">
          <div class="error-message" *ngIf="myform.get('verificationCode').hasError('required') && (myform.get('verificationCode').dirty || myform.get('verificationCode').touched) || (isValidFormSubmitted == false && myform.get('verificationCode').hasError('required'))">{{pageTranslation.VereficationCodeRequiered}}</div>
          <div class="error-message" *ngIf="showVerificationCodeError">{{VerificationCodeError}}</div>
        </div>
        <br />
        <div class="warning-message">
          <div class="error-message" >{{pageTranslation.VerificationCodeInfo}}</div>
          <br />
          <div class="error-message" >Didn't recive your code? Click <a (click)="resendCode(myform.value)" class="div-link">here</a> to resend it. </div>
        </div> 
      </div>

      <br />

      <div class="form-group" *ngIf="showRegisterButton">
        <button type="submit" class="btn btn-primary btn-login-chat" [ngClass]="{ 'disabled': disableSignInButton }" 
        [style.color]="butonTextColor" [style.background-color]="chatColor">{{pageTranslation.SignIn}}</button>

      </div>
      <p *ngIf="!showRegisterButton" style="
      font-size: 20px;
      color: red;
      text-align: center;
    ">Chat services are currently disabled.</p>

    </form>

    <h1 class="cred">{{pageTranslation.PoweredByGlobalMentoring}}</h1>
  </div>
</div>
