import { ChatPersonalization } from "./chatpersonalization.model";

export class ChatPersonalizationFactory {

    public static create(pers: any): ChatPersonalization {

        

        return new ChatPersonalization({
            id: pers.id,
            colorCode: pers.colorCode ,
            logo: pers.logo,
            additionalColor: pers.additionalColor
            
        });
    }

 
}
