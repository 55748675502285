import { Component, OnInit, ChangeDetectorRef, AfterViewInit, Input } from '@angular/core';
import { UsersService } from '../../service/users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LocalizationService } from '../../service/localization.service';

@Component({
  selector: 'app-client-not-found',
  templateUrl: './client-not-found.component.html',
  styleUrls: ['./client-not-found.component.css']
})
export class ClientNotFoundComponent implements OnInit {
  @Input() data: any;

  errMsg: string = "";
  public clientIdParam: string; public clientTypeParam: string;
  private pageName = "ClientNotFoundComponent";
  public pageTranslation: any = "";
  public pageTranslated = new BehaviorSubject<string>("");
  public showTryAgain:boolean=true;
  constructor(private userService: UsersService, private ref: ChangeDetectorRef, private activatedRoute: ActivatedRoute, private localizationService: LocalizationService) {
   
  }

  ngAfterViewInit(){}

  initiateErrorMessageText(errMsg: string){
    if(errMsg == "403"){
      this.errMsg = this.pageTranslation.UserPendingActivation;
    }
    else if(errMsg == "409"){
      this.errMsg = this.pageTranslation.AccountBanned;
    }
    else if(errMsg == "401") {
      this.errMsg = this.pageTranslation.AccountNotFound;
    }
    else if(errMsg == "400"){
      this.errMsg = this.pageTranslation.ContactYourProvider;
    }
    else if(errMsg == "406"){
      this.errMsg = this.pageTranslation.B1NotFound;
      }
    else if(errMsg == "502") {
      this.errMsg = this.pageTranslation.IvalidURL;
    }
    else if(errMsg == "412") {
      this.errMsg = this.pageTranslation.UserExists;
    }
    else if(errMsg == "410") {
      this.errMsg = this.pageTranslation.InvalidVoucher;
    }
    else if(errMsg == "failed"){
      this.errMsg = this.pageTranslation.IvalidURL;
    }
    else if(errMsg == "notifyMentors"){
      this.showTryAgain=false;
      this.errMsg = this.pageTranslation.NotifyMentors;
    }
    else if(errMsg == "notifyMentorsBell"){
      this.showTryAgain=false;
      this.errMsg = this.pageTranslation.NotifyMentorsBell;
    }
    else if(errMsg == "440"){
      this.errMsg = this.pageTranslation.UserAlreadyInChat;
    }
  }

  ngOnInit() {
    this.SetLocalization();
  }

  SetLocalization() {
    var localeFromUrl = this.userService.getClientLanguageParam();
    var locale = this.localizationService.ValidateLocale(localeFromUrl);

    this.localizationService.GetLocalizationData().subscribe(dt => {
        this.pageTranslation = dt[0][this.pageName][locale];
        this.pageTranslated.next(this.pageTranslation);
        this.initiateErrorMessageText(this.data);
      },
      error => { console.log(error) }, () => { });
  }

  ReloadPage(){
    document.location.reload();
  }
}
