
import { concatMap, switchMap, take, takeUntil } from 'rxjs/operators';
import {
  Component,
  OnInit,
  ViewChild,
  ViewContainerRef,
  Input,
  OnDestroy,
  ComponentFactoryResolver
} from '@angular/core';


import {
  ComponentItem
} from '../home/chat-panel/componentItem';
import {
  RegisterChatBellComponent
} from './register-chat-bell/register-chat-bell.component';
import {
  RegisterUserBellComponent
} from './register-user-bell/register-user-bell.component';
import {
  RegisterChatTtiComponent
} from './register-chat-tti/register-chat-tti.component';
import {
  IComponentHolder
} from '../home/chat-panel/IComponentHolder';
import {
  CompHolderDirective
} from '../home/chat-panel/comp-holder.directive';
import {
  UsersService
} from '../service/users.service';
import {
  AlertService
} from '../service/alert.service';
import {
  Router
} from '@angular/router';
import {
  ChatWindowComponent
} from '../home/chat-window/chat-window.component';
import {
  User
} from '../model/user';
import {
  ChatService
} from '../service/chatservice';
import {
  AuthenticationService
} from '../service/authentication.service';
import {
  MessagesService
} from '../service/messages.service';
import {
  Message
} from '../model/message.model';
import {
  DecryptedUserInfo
} from '../model/decryptedUserInfo.model';
import { RegisterChatEduModel } from '../model/registerChatEduModel';
import {
  messageFactory
} from '../model/messageFactory';
import {
  RoomsService
} from '../service/rooms.service';
import {
  Room
} from '../model/room.model';
import {
  ClientFeedbackComponent
} from './client-feedback/client-feedback.component';
import {
  ActivatedRoute,
  Params
} from '@angular/router';
import {
  ClientWindowComponent
} from '../client-window/client-window.component';
import {
  RegisterChatMspComponent
} from './register-chat-msp/register-chat-msp.component';
import {
  RegisterUserMspComponent
} from './register-user-msp/register-user-msp.component';
import {
  RegisterChatEduComponent
} from './register-chat-edu/register-chat-edu.component';
import {
  RegisterUserEduComponent
} from './register-user-edu/register-user-edu.component';
import {
  ClientNotFoundComponent
} from './client-not-found/client-not-found.component';

import { MentorSpinnerService } from '../service/mentorspinner.service';
import { Observable, Subject, BehaviorSubject, ReplaySubject } from 'rxjs';
// import { ToastsManager } from 'ng2-toastr/ng2-toastr';
import { ToastrService } from 'ngx-toastr';
import { ChatPersonalizationFactory } from '../model/ChatPersonalizationFactory';
import { UntypedFormGroup, FormBuilder, UntypedFormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { ErrorLogService } from '../service/error-log.service';
import { ClientPreviousTicketComponent } from './client-previous-ticket/client-previous-ticket.component';
import { ConfirmComponent } from '../home/confirm/confirm.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { TicketService } from '../service/ticket.service';
import { AdminService } from '../service/admin.service';
import { MaintenanceMode } from '../model/maintenancemode.model';
import { RegisterChatMspModel } from '../model/registerChatMspModel';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.css']
})


export class ClientComponent implements OnInit, OnDestroy {
  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  currentComponent = null;
  data: any;
  email: any;
  user: any;
  registerComponents: ComponentItem[];
  currentUser: User;
  currentRoom: Room;
  public connectedParam: boolean;
  public clientIdParam: number;
  public clientLanguageParam: string;
  public isWidget: boolean = false;

  @ViewChild(CompHolderDirective, { static: true }) adHost: CompHolderDirective;

  REGISTER_BELL_CHAT: number = 0;
  REGISTER_BELL_USER: number = 1;
  CHAT: number = 2;
  FEEDBACK: number = 3;
  REGISTER_MSP_CHAT: number = 4;
  REGISTER_MSP_USER: number = 5;
  CLIENT_NOT_FOUND: number = 6;
  REGISTER_EDU_CHAT: number = 7;
  REGISTER_EDU_USER: number = 8;
  REGISTER_TTI_USER: number = 9;
  MAINTENANCE_MODE: number = 10;
  PREVIOUS_TICKET: number = 10;

  CONST_MSP_GROUPID : number = 2;
  CONST_TELCO_GROUPID : number = 3;
  CONST_EDU_GROUPID : number = 4;

  MINUTES_UNTIL_AUTO_LOGOUT = 60 // in mins
  CHECK_INTERVAL = 1000 // in ms
  STORE_KEY = 'lastAction';
  emailPattern = "[a-zA-Z0-9.-_-]{1,}@[0-9a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  maintenanceMode: MaintenanceMode;
  ConnectedToChat: Subject<any> = new Subject<any>();

  constructor(
    private mspinnerService: MentorSpinnerService,
    private spinnerService: NgxSpinnerService,
    public viewContainerRef: ViewContainerRef,
    private activatedRoute: ActivatedRoute,
    private componentFactoryResolver: ComponentFactoryResolver,
    private userService: UsersService,
    private msgService: MessagesService,
    private alertService: AlertService,
    private router: Router,
    private authenticationService: AuthenticationService,
    public chatService: ChatService,
    public messageService: MessagesService,
    public toastr: ToastrService,
    private errorService: ErrorLogService,
    private dialog: MatDialog,
    private ticketService: TicketService,
      public roomService: RoomsService,
      public adminService: AdminService  ) {

    // this.toastr.setRootViewContainerRef(viewContainerRef);
    userService.urlReferer = document.referrer;

    let val = this.activatedRoute.snapshot.queryParams["val"];
    if (val != undefined) {
      this.decryptTTIQueryParams(val);
    }
    else
      this.decryptQueryParams();

    this.userService.GetChatPersonalization(parseInt(this.userService.getClientIdParam()), this.userService.getClientTypeParam()).pipe(takeUntil(this.onDestroy$))
    .subscribe((data: any) => {
        let cp = ChatPersonalizationFactory.create(data);
        this.userService.cpSubject.next(cp);
      });

    this.userService.currentUser.pipe(
      takeUntil(this.onDestroy$)).subscribe(
        (user: User) => {
          this.currentUser = user;
        });

    this.getCurrentUser();

    this.chatService.ClientWindowInitialized.pipe(takeUntil(this.onDestroy$)).subscribe(
      (isInit: boolean) => {
        this.loadComponent(this.CHAT);
      });


    this.chatService.UserChatInitialized.pipe(takeUntil(this.onDestroy$)).subscribe(
      (isInit: boolean) => {

        this.messageService.clientInitMessage.pipe(takeUntil(this.onDestroy$)).subscribe((messageText: string) => {
          if (messageText.length <= 0) {
            errorService.logError('Init message is empty.');
            return;
          }
          let msg = messageFactory.createClientInitMessage(messageText);
          msg.room = this.currentRoom;
          msg.author = JSON.parse(localStorage.getItem('currAuthor'));
          localStorage.removeItem("clientInitMessage")
          this.chatService.sendInitMessage(msg);
        });

        this.messageService.eduSendClassNameMessage.pipe(takeUntil(this.onDestroy$)).subscribe((messageText: string) => {
          if (messageText.length <= 0) {
            errorService.logError('Edu Init message is empty.');
            return;
          }
          let msg = messageFactory.createClientInitMessage(messageText);
          msg.room = this.currentRoom;
          msg.author = JSON.parse(localStorage.getItem('currAuthor'));
          this.chatService.sendCourseNameMessage(msg);
        });

        this.messageService.sendHostNameMessage.pipe(takeUntil(this.onDestroy$)).subscribe((messageText: string) => {

          if (messageText.length <= 0) return;
          let msg = messageFactory.createClientInitMessage(messageText);
          msg.room = this.currentRoom;
          msg.author = JSON.parse(localStorage.getItem('currAuthor'));
          this.chatService.sendHostNameMessage(msg);
        });
      });


    this.chatService.UserLeftRoom.pipe(takeUntil(this.onDestroy$)).subscribe(() => {

      //NOTE: refactor feedback, this should be removed!
      this.TempLogout();

      localStorage.setItem("isConnected", "false");
    });

    this.roomService.currentRoom.pipe(takeUntil(this.onDestroy$)).subscribe(
      (room: Room) => {
        this.currentRoom = room;
      });

    this.userService.registeredUserData.pipe(takeUntil(this.onDestroy$)).subscribe(data => {

      if (!data) {

        switch (this.userService.getClientTypeParam()) {
          case "Telco":
            this.loadComponent(this.REGISTER_BELL_USER);
            break;
          case "Msp":
            this.loadComponent(this.REGISTER_MSP_USER);
            break;
          case "Edu":
            this.loadComponent(this.REGISTER_EDU_USER);
            break;
          case "Guest":
            this.loadComponent(this.REGISTER_TTI_USER)
            break;
        }
        this.spinnerService.hide();
      } else {
        if (localStorage.getItem('BearerToken') != '' && localStorage.getItem('BearerToken') != null) {
          //this.chatService.connectToSignalR();
          let currUser = JSON.parse(localStorage.getItem('currentUser'));
          this.userService.currentUser.next(currUser);
          this.ConnectedToChat.next(true);
        }
        else
          this.authenticationService.login(data.email, "")
            .subscribe(
              data1 => {
                localStorage.setItem("isConnected", "true");
                //this.chatService.connectToSignalR();
                let currUser = JSON.parse(localStorage.getItem('currentUser'));
                this.userService.currentUser.next(currUser);
                this.ConnectedToChat.next(true);

              },
              error => {
                this.loadComponent(this.CLIENT_NOT_FOUND);
                localStorage.setItem("isConnected", "false");

              });
      }
    });

    this.chatService.ShowSurveyFlag.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.TempLogout();
      localStorage.setItem("isConnected", "false");
    });


    this.mspinnerService.spinnerState.pipe(takeUntil(this.onDestroy$)).subscribe(val => {
      if (val == true)
        this.spinnerService.show();
      else
        this.spinnerService.hide();
    });


  }

  getCurrentUser(): void {
    if (localStorage.getItem('currentUser')) {
      let currUser = JSON.parse(localStorage.getItem('currentUser'));
      this.userService.currentUser.next(currUser);
    }

  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  private TempLogout() {

    switch (this.userService.getClientTypeParam()) {
      case "Telco":
        this.loadComponent(this.REGISTER_BELL_CHAT);
        break;
      case "Msp":
        this.loadComponent(this.REGISTER_MSP_CHAT);
        break;
      case "Edu":
        this.loadComponent(this.REGISTER_EDU_CHAT);
        break;
      case "Guest":
        this.loadComponent(this.REGISTER_TTI_USER)
        break;
    }
    this.authenticationService.logout();
    location.reload();
  }

  ngOnInit() {
    this.InitComponents();


    this.userService.clientNotFound.pipe(takeUntil(this.onDestroy$)).subscribe((errMsg) => {
      this.loadComponentWithData(this.CLIENT_NOT_FOUND, errMsg);
    });

    this.ConnectedToChat.pipe(takeUntil(this.onDestroy$),
      concatMap(()=> {
      this.mspinnerService.ShowSpinner();
      localStorage.removeItem("delayCurrentLogin");
      localStorage.setItem('previousTicketOpen', 'true');
      return this.ticketService.getTicketsByContact(this.currentUser.id, 10);})).subscribe((data: []) => {

        this.mspinnerService.HideSpinner();
        if (localStorage.getItem('previousTicketOpen'))
        {
          if (data && data.length > 0) {
         
            this.spinnerService.hide();
            this.openConfirmDialog();
          } else
            this.chatService.connectToSignalR();
        }
        
      });
    

    if (this.activatedRoute.snapshot.queryParams["val"] === undefined)
      this.Hangout();

    this.initListener();
    this.initInterval();

    this.userService.clientTostrSuccessNotification.pipe(takeUntil(this.onDestroy$))
      .subscribe((notificationText: string) => {
        this.toastr.success(notificationText, null, { timeOut: 8000 });
      });

    this.userService.clientTostrErrorNotification.pipe(takeUntil(this.onDestroy$))
      .subscribe((notificationText: string) => {
        this.toastr.error(notificationText, null, { timeOut: 8000 });
      });
  }

  openPreviousTicketDialog() {

    this.mspinnerService.ShowSpinner();

    this.ticketService.getTicketsByContact(this.currentUser.id, 10)
      .pipe(takeUntil(this.onDestroy$)).subscribe((data: []) => {

        var sliceNumber = 10;
        if (data.length < 10)
          sliceNumber = data.length;

        var lastFive = data.slice(0, sliceNumber);
        const dialogConfig = new MatDialogConfig();
        dialogConfig.autoFocus = true;

        dialogConfig.data = {
          ticketsArray: lastFive,
          userId: this.currentUser.id
        };

        dialogConfig.height = '600px';
        dialogConfig.width = '800px';
        dialogConfig.disableClose = true;
        this.mspinnerService.HideSpinner();

        const dialogRef = this.dialog.open(ClientPreviousTicketComponent, dialogConfig);

        const subDialogRef = dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(
          val => {
            if (val !== null && val != undefined && val) {

            } else if (val !== null && val != undefined && val == false) {
              this.chatService.connectToSignalR();
            } else {
              this.chatService.connectToSignalR();
            }
          });
        dialogRef.disableClose = false;
      });

  }

  openConfirmDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(ConfirmComponent, {
      height: '250px',
      width: '400px',
      data: { warningMessage: "Is this chat session related to a previous one?", showCancelButton: false }
    });

    const subDialogRef = dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(
      val => {
        if (val !== null && val != undefined && val) {
          this.openPreviousTicketDialog();
        } else if (val !== null && val != undefined && val == false) {
          this.chatService.connectToSignalR();
        } else {
          this.chatService.connectToSignalR();
        }
      }
    );
    dialogRef.disableClose = false;
  }


  decryptTTIQueryParams(val: string): void {

    let clientTtiId = this.activatedRoute.snapshot.queryParams["id"];
    localStorage.setItem("clientId", clientTtiId);
    localStorage.setItem("clientLanguageParam", this.activatedRoute.snapshot.queryParams["lang"]);
    localStorage.setItem("isWidget", JSON.stringify(this.isWidget));
    localStorage.setItem("clientType", "TTI");

    this.userService.decryptUrlParam(val, clientTtiId).subscribe(item => {
      localStorage.setItem("ttiEmail", item.Email);
      localStorage.setItem("ttiFirstName", item.FirstName);
      localStorage.setItem("ttiLastName", item.LastName);
      localStorage.setItem("ttiMessage", item.Message);

      this.Hangout();

    }),
      error => {
        this.loadComponentWithData(this.CLIENT_NOT_FOUND, 'failed');
      };
  }

  decryptQueryParams(): void {
    try {
      let clientType = atob(this.activatedRoute.snapshot.queryParams["clientType"]);
      let clientId = parseInt(atob(this.activatedRoute.snapshot.queryParams["clientId"]));

      this.clientLanguageParam = "en";
      if (this.activatedRoute.snapshot.queryParams["lang"] != undefined)
        this.clientLanguageParam = atob(this.activatedRoute.snapshot.queryParams["lang"]);

      this.isWidget = false;
      if (this.activatedRoute.snapshot.queryParams["isWidget"] != undefined)
        this.isWidget = (atob(this.activatedRoute.snapshot.queryParams["isWidget"]) == "true");

      localStorage.setItem("clientLanguageParam", this.clientLanguageParam);
      localStorage.setItem("isWidget", JSON.stringify(this.isWidget));
      localStorage.setItem("clientType", clientType);
      localStorage.setItem("clientId", JSON.stringify(clientId));

    }
    catch (err) {
      this.loadComponentWithData(this.CLIENT_NOT_FOUND, 'failed');
    }
  }

  decryptEduRegistrationForm(): RegisterChatEduModel {

    var retModel = new RegisterChatEduModel();
    retModel.isUrlCreated = false;

    try {

      if (this.activatedRoute.snapshot.queryParams["email"] != undefined)
        retModel.email = this.activatedRoute.snapshot.queryParams["email"];

      if (this.activatedRoute.snapshot.queryParams["firstName"] != undefined)
        retModel.firstName = this.activatedRoute.snapshot.queryParams["firstName"];

      if (this.activatedRoute.snapshot.queryParams["lastName"] != undefined)
        retModel.lastName = this.activatedRoute.snapshot.queryParams["lastName"];

      if (this.activatedRoute.snapshot.queryParams["courseName"] != undefined)
        retModel.courseName = this.activatedRoute.snapshot.queryParams["courseName"];

      if (this.activatedRoute.snapshot.queryParams["message"] != undefined)
        retModel.message = this.activatedRoute.snapshot.queryParams["message"];

      if (retModel.firstName != undefined && retModel.lastName != undefined && retModel.email != undefined
        && retModel.message != undefined) {
        retModel.isUrlCreated = true;
      } else {
        return retModel;
      }


      var newform = new UntypedFormGroup({
        firstName: new UntypedFormControl(retModel.firstName, Validators.required),
        lastName: new UntypedFormControl(retModel.lastName, Validators.required),
        courseName: new UntypedFormControl(retModel.courseName),
        email: new UntypedFormControl(retModel.email, Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern)
        ])),
        message: new UntypedFormControl(retModel.message, [Validators.required, this.noWhitespaceValidator]),
        isAtDown: new UntypedFormControl(false)
      });

      if (!newform.valid) {
        retModel.isUrlCreated = false;
      }

    }
    catch (err) {

    }

    return retModel;
  }


  decryptMspRegistrationForm(): RegisterChatMspModel {

    var retModel = new RegisterChatMspModel();
    retModel.isUrlCreated = false;

    try {

      if (this.activatedRoute.snapshot.queryParams["email"] != undefined)
        retModel.email = this.activatedRoute.snapshot.queryParams["email"];

      if (this.activatedRoute.snapshot.queryParams["firstName"] != undefined)
        retModel.firstName = this.activatedRoute.snapshot.queryParams["firstName"];

      if (this.activatedRoute.snapshot.queryParams["lastName"] != undefined)
        retModel.lastName = this.activatedRoute.snapshot.queryParams["lastName"];

      if (this.activatedRoute.snapshot.queryParams["hostName"] != undefined)
        retModel.hostName = this.activatedRoute.snapshot.queryParams["hostName"];

      if (this.activatedRoute.snapshot.queryParams["message"] != undefined)
        retModel.message = this.activatedRoute.snapshot.queryParams["message"];

      if (retModel.firstName != undefined && retModel.lastName != undefined && retModel.email != undefined
        && retModel.message != undefined) {
        retModel.isUrlCreated = true;
      } else {
        return retModel;
      }


      var newform = new UntypedFormGroup({
        firstName: new UntypedFormControl(retModel.firstName, Validators.required),
        lastName: new UntypedFormControl(retModel.lastName, Validators.required),
        courseName: new UntypedFormControl(retModel.hostName),
        email: new UntypedFormControl(retModel.email, Validators.compose([
          Validators.required,
          Validators.pattern(this.emailPattern)
        ])),
        message: new UntypedFormControl(retModel.message, [Validators.required, this.noWhitespaceValidator]),
        isAtDown: new UntypedFormControl(false)
      });

      if (!newform.valid) {
        retModel.isUrlCreated = false;
      }

    }
    catch (err) {

    }

    return retModel;
  }

  private Hangout(): void {

    if (JSON.parse(localStorage.getItem('isConnected'))) {

      if (localStorage.getItem('previousTicketOpen'))
        this.ConnectedToChat.next(true);
      else
        this.chatService.connectToSignalR();

      if(localStorage.getItem("clientInitMessage"))
        this.messageService.clientInitMessage.next(localStorage.getItem("clientInitMessage"));

      return;
    }

    switch (this.userService.getClientTypeParam()) {
      case "Telco":
        this.adminService.CheckIfMaintenanceModeIsEnabled(this.CONST_TELCO_GROUPID).subscribe(
          data => {
            this.maintenanceMode = data[0];
            if (this.maintenanceMode.loginAllowed) {
              this.loadComponent(this.REGISTER_BELL_CHAT);
            } else {
              this.router.navigate(['/maintenance'], { queryParams: { q: this.CONST_TELCO_GROUPID }});
            }
          });
        break;

      case "Msp":

      var mspModel = this.decryptMspRegistrationForm();

      if (mspModel.isUrlCreated) {
        this.registerMspUser(mspModel);
        break;
      }else
      {
        this.adminService.CheckIfMaintenanceModeIsEnabled(this.CONST_MSP_GROUPID).subscribe(
          data => {
            this.maintenanceMode = data[0];
            if (this.maintenanceMode.loginAllowed) {
              this.loadComponent(this.REGISTER_MSP_CHAT);
            } else {
              this.router.navigate(['/maintenance'], { queryParams: { q: this.CONST_MSP_GROUPID }});
            }
          });
        break;
      }

      case "Edu":
        var eduModel = this.decryptEduRegistrationForm();

        if (eduModel.isUrlCreated) {
          this.registerEduUser(eduModel);
          break;
        }
        else {
          this.adminService.CheckIfMaintenanceModeIsEnabled(this.CONST_EDU_GROUPID).subscribe(
            data => {
              this.maintenanceMode = data[0];
              if (this.maintenanceMode.loginAllowed) {
                this.loadComponent(this.REGISTER_EDU_CHAT);
              } else {
                this.router.navigate(['/maintenance'], { queryParams: { q: this.CONST_EDU_GROUPID }});
              }
            });

          break;
        }


      case "Guest":
        this.loadComponent(this.REGISTER_TTI_USER)
        break;
      case "TTI":

        let decrUserInfo = new DecryptedUserInfo(localStorage.getItem('ttiEmail'),
          localStorage.getItem('ttiFirstName'),
          localStorage.getItem('ttiLastName'),
          localStorage.getItem('ttiMessage'));

        this.userService.RegisterToChatGuest(new User(decrUserInfo)).subscribe(
          data => {
            this.userService.checkUser(decrUserInfo);
            this.messageService.clientInitMessage.next(decrUserInfo.message);
          }
          ,
          error => {
            this.spinnerService.hide();

            if (error.status == 404 || error.status == 417) {
              this.userService.checkUser(this.data);
            }
            else {
              this.userService.UserNotFound(error.status.toString());
              return;
            }

          });

        break;
    }
  }

  private InitComponents(): void {
    this.registerComponents = [
      new ComponentItem(RegisterChatBellComponent, {
        title: "Register to chat"
      }),
      new ComponentItem(RegisterUserBellComponent, {
        title: "Register user"
      }),
      new ComponentItem(ClientWindowComponent, {
        title: "Chat"
      }),
      new ComponentItem(ClientFeedbackComponent, {
        title: "Feedback"
      }),
      new ComponentItem(RegisterChatMspComponent, {
        title: "Register to MSP chat"
      }),
      new ComponentItem(RegisterUserMspComponent, {
        title: "Register MSP user"
      }),
      new ComponentItem(ClientNotFoundComponent, {
        title: "ClientNotFoundMessage"
      }),
      new ComponentItem(RegisterChatEduComponent, {
        title: "Register to EDU chat"
      }),
      new ComponentItem(RegisterUserEduComponent, {
        title: "Register EDU user"
      }),
      new ComponentItem(RegisterChatTtiComponent, {
        title: "Register Tti user"
      })
    ];
  }

  registerMspUser(mspModel: RegisterChatMspModel) {
    var usrModel = new User();
    usrModel.firstName = mspModel.firstName;
    usrModel.lastName = mspModel.lastName;
    usrModel.email = mspModel.email;
    usrModel.message = mspModel.message;

    this.userService.RegisterToChatMsp(usrModel)
      .subscribe(
        data => {

          this.router.navigate([], {
            queryParams: {
              'firstName': null,
              'lastName': null,
              'hostName': null,
              'email': null,
              'message': null,
            },
            queryParamsHandling: 'merge'
          });

          this.alertService.success('User exists', true);
          this.data = true;
          this.userService.checkUser(mspModel);

          this.msgService.clientInitMessage.next(mspModel.message);

          if (mspModel.hostName)
            this.msgService.sendHostNameMessage.next(mspModel.hostName);
        },
        error => {
          this.mspinnerService.HideSpinner();
          if (error.status == 404) {
            this.alertService.error("User not found!");
            this.data = false;
          }
          else {
            this.userService.UserNotFound(error.status.toString());
            return;
          }

        });


  }

  registerEduUser(eduModel: RegisterChatEduModel) {
    var usrModel = new User();
    usrModel.firstName = eduModel.firstName;
    usrModel.lastName = eduModel.lastName;
    usrModel.email = eduModel.email;
    usrModel.message = eduModel.message;

    this.userService.RegisterToChat(usrModel)
      .subscribe(
        data => {

          this.router.navigate([], {
            queryParams: {
              'firstName': null,
              'lastName': null,
              'courseName': null,
              'email': null,
              'message': null,
            },
            queryParamsHandling: 'merge'
          });

          this.alertService.success('User exists', true);
          this.data = true;
          this.userService.checkUser(eduModel);

          this.msgService.clientInitMessage.next(eduModel.message);

          if (eduModel.courseName)
            this.msgService.eduSendClassNameMessage.next(eduModel.courseName);
        },
        error => {
          this.mspinnerService.HideSpinner();
          if (error.status == 404) {
            this.alertService.error("User not found!");
            this.data = false;
          }
          else {
            this.userService.UserNotFound(error.status.toString());
            return;
          }

        });


  }

  loadComponent(currentAdIndex: number) {
    let componentItem = this.registerComponents[currentAdIndex];
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentItem.component);
    let viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();
    let componentRef = viewContainerRef.createComponent(componentFactory);
    (<IComponentHolder>componentRef.instance).data = componentItem.data;

    // if(currentAdIndex==7)
    // {
    //   var eduModel=this.decryptEduRegistrationForm();
    //   componentRef.instance.componentData = eduModel;
    // }

  }

  loadComponentWithData(currentAdIndex: number, data: string) {
    let componentItem = this.registerComponents[currentAdIndex];
    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentItem.component);
    let viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();
    let componentRef = viewContainerRef.createComponent(componentFactory);
    (<IComponentHolder>componentRef.instance).data = data;
  }

  initListener() {
    document.body.addEventListener('click', () => this.reset());
    document.body.addEventListener('mouseover', () => this.reset());
    document.body.addEventListener('mouseout', () => this.reset());
    document.body.addEventListener('keydown', () => this.reset());
    document.body.addEventListener('keyup', () => this.reset());
    document.body.addEventListener('keypress', () => this.reset());
  }

  reset() {
    this.setLastAction(Date.now());
  }

  setLastAction(lastAction: number) {
    localStorage.setItem(this.STORE_KEY, lastAction.toString());
  }

  getLastAction() {
    return parseInt(localStorage.getItem(this.STORE_KEY));
  }

  initInterval() {
    setInterval(() => {
      this.check();
    }, this.CHECK_INTERVAL);
  }

  check() {
    const now = Date.now();
    const timeleft = this.getLastAction() + (this.MINUTES_UNTIL_AUTO_LOGOUT * 60 * 1000);
    const diff = timeleft - now;
    const isTimeout = diff < 0;

    if (localStorage.getItem('currentUser') && isTimeout) {

      localStorage.removeItem('lastAction');
      this.chatService.closeRoom(this.currentRoom);
      this.chatService.LogOut();
      alert("Your session has expired due to inactivity!");
    }

  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {
      'whitespace': true
    };
  }
}
