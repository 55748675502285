<mat-dialog-actions>
    <button class="mat-raised-button close" (click)="close()"> X </button>
</mat-dialog-actions>
<h2 mat-dialog-title *ngIf='allItems'>All Tickets</h2>

<div mat-dialog-body>

    <div *ngIf='noContentMessage'>{{noContentMessage}}</div>

        <mat-table *ngIf = "dataSource" [dataSource] = "dataSource" class="mat-elevation-z8">
            <ng-container matColumnDef="TicketNumber">
                <mat-header-cell *matHeaderCellDef> Ticket No. </mat-header-cell>
                <mat-cell *matCellDef="let element"> <a href="#" (click)="$event.preventDefault(); clickedTicket(element)">{{element.TicketNumber}}</a> </mat-cell>
            </ng-container>

            <ng-container matColumnDef="TicketSourceName">
                <mat-header-cell *matHeaderCellDef> Source </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.TicketSourceName}} </mat-cell>
            </ng-container>

            <ng-container matColumnDef="Title">
                <mat-header-cell *matHeaderCellDef> Title </mat-header-cell>
                <mat-cell *matCellDef="let element"> {{element.Title}} </mat-cell>
            </ng-container>

            <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
            <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        </mat-table>
        <div mat-dialog-actions>
            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
        <button mat-stroked-button (click)="seeMore()">See More...</button>
</div>

