import { Injectable, NgZone } from '@angular/core';
import { Observable ,  Subject } from 'rxjs';
import * as Msal from 'msal';
import { UserFactory } from '../model/userFactory';
import { ChatService } from './chatservice';





@Injectable()
export class MsalService {
  authSubject: Subject<any>;

  msalConfig = {
    auth: {
      clientId: 'c2d3ca0d-3771-4c81-a768-42de761d8a69',
      authority: "https://login.microsoftonline.com/6284ba90-b4d1-4fc6-9391-47930def9f33" //This is your tenant info
    }
  };
  graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
  };


  clientApplication: any;
  requestObj = {
    scopes: ['https://globalmentoring.com/WebApiAuth/user_impersonation']
    // scopes: ["user.read","Directory.Read.All","user_impersonation"]
  };

  constructor(private ngZone: NgZone) {

    this.authSubject = new Subject<any>();
    this.clientApplication = new Msal.UserAgentApplication(this.msalConfig);
  }




  // create a request object for login or token request calls
  // In scenarios with incremental consent, the request object can be further customized

  public login(): void {


    //this.clientApplication.loginPopup(this.requestObj).then(function (loginResponse) {
    this.clientApplication.loginPopup(this.requestObj).then((loginResponse) => {

      // if the user is already logged in you can acquire a token
      if (this.clientApplication.getAccount()) {
        
        this.clientApplication.acquireTokenSilent(this.requestObj)
          .then(response => {
            // get access token from response                                    
            this.saveAccessTokenToCache(response.accessToken);
      
            let user = UserFactory.createOffice365User(response.account.accountIdentifier , response.account.name,  response.account.userName);
            localStorage.setItem('currentUser', JSON.stringify(user));      
            this.authSubject.next(true);

          })
          .catch(err => {
            console.log("error");
            // could also check if err instance of InteractionRequiredAuthError if you can import the class.
            if (err.name === "InteractionRequiredAuthError") {
              return this.clientApplication.acquireTokenPopup(this.requestObj)
                .then(response => {
                  // get access token from response
                  // response.accessToken
                  this.saveAccessTokenToCache(response.accessToken);
                })
                .catch(err => {
                  // handle error
                  console.log("Error acquiring the popup:\n" + err);
                });
            }
          });
      } else {
        // user is not logged in, you will need to log them in to acquire a token
        console.log(" user is not logged in, you will need to log them in to acquire a token");
      }










      // let user = UserFactory.createOffice365User(loginResponse.idToken.rawIdToken, loginResponse.account.name);
      // localStorage.setItem('currentUser', JSON.stringify(user));
      // localStorage.setItem('BearerToken', loginResponse.idToken.rawIdToken);

      // //this.saveAccessTokenToCache(this.newMethod());
      // this.authSubject.next(true);

    }).catch(function (error) {
      //Please check the console for errors

    });


  }


  saveAccessTokenToCache(accessToken: string): void {
    console.log("t");
    localStorage.setItem('BearerToken',  accessToken);

  }
  ;
  logout(): void {
    this.clientApplication.logout();
  }
  ;
  isOnline(): any {
    return this.clientApplication.getUser() != null; 
  }
  ;
}
