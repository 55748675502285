
import {tap} from 'rxjs/operators';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig,MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { Component, Inject, OnInit,Input } from '@angular/core';
import { MessagesService } from '../../service/messages.service';
import { messageFactory } from '../../model/messageFactory';
import { Message } from '../../model/message.model';
import { Room } from '../../model/room.model';
import { RoomsService } from '../../service/rooms.service';
import { ChatService } from '../../service/chatservice';
import { PagerService } from '../../service/pagerservice';
// import { Http, Headers, RequestOptions, Response } from '@angular/http';
import { Observable ,  Subject } from 'rxjs';

import { User } from "../../model/user";
import { UserFactory } from "../../model/userFactory";
import {TranscriptModalComponent} from '../transcript-modal/transcript-modal.component';

@Component({
  selector: 'app-all-transcripts-modal',
  templateUrl: './all-transcripts-modal.component.html',
  styleUrls: ['./all-transcripts-modal.component.css']
})
export class AllTranscriptsModalComponent{
	  
    private onDestroy$: Subject<void> = new Subject<void>();
    //START - paging properties NEW
    // array of all items to be paged
    allItems: any[] = [];
    perPage: number;
    defaultPerPage: number = 10;
    fullLength: number;
    currentPage: number;
    totalPages: number;
    loading: boolean;
    currentClickedUser: any;
    public userId: string;
    //END - paging properties NEW

  constructor(       
    private dialogRef: MatDialogRef<AllTranscriptsModalComponent>, public messagesService: MessagesService, 
    public RoomsService: RoomsService, private chatService: ChatService, @Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog) {

}

ngOnInit() {
    //this.getPageOnInit();

    const subClickAllTranscript = this.RoomsService.clickAllTranscript.subscribe((rooms) => {
        this.allItems = [];
        for(var i = 0; i < rooms.length; i++){
            this.allItems[i] = new Room(rooms[i]);
        }
        this.perPage = this.allItems.length;
    });
    
    const subAllTranscript = this.RoomsService.allTranscriptsLen.subscribe(fullLen => {
       
        this.fullLength = fullLen;
    });

    const subSetCurrentPage = this.RoomsService.setCurrentPage.subscribe(current => {
        
        this.currentPage = current;
    });

    const subCurrentTranscriptUser = this.RoomsService.currentTranscriptUser.pipe(tap(currUser => {
        this.currentClickedUser = new User(currUser);
    })).subscribe()
}

clickedRoom(room: Room){
    this.chatService.getCurrentTranscript(this.currentClickedUser.id, room.id);
    this.openCurrentTranscriptDialog();
}

openCurrentTranscriptDialog() {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(TranscriptModalComponent, {
      height: '500px',
      width: '700px'
    });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  } 
//START - pagination logic
//getPage(page: number) {
    
//    this.chatService.getAllTranscripts(page, this.currentClickedUser.id);
//    this.loading = true;
//    this.totalPages = Math.ceil(this.fullLength / this.defaultPerPage);
//}

//getPageOnInit(){
//    this.totalPages = Math.ceil(this.fullLength / this.defaultPerPage);
//}

//END - pagination logic
//save() {
//    this.dialogRef.close(true);
//}

//close() {
//    this.dialogRef.close(false);
//}

}
