import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {Component, Inject} from '@angular/core';
import { MessagesService } from '../../service/messages.service';
import { messageFactory } from '../../model/messageFactory';
import { Message } from '../../model/message.model';
//import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { NgxSpinnerService } from "ngx-spinner";


@Component({
  selector: 'app-transcript-modal',
  templateUrl: './transcript-modal.component.html',
  styleUrls: ['./transcript-modal.component.css']
})
export class TranscriptModalComponent{

    currentTranscript: any[] = [];
    noContentMessage: any = null;

  constructor( private dialogRef: MatDialogRef<TranscriptModalComponent>, public messagesService: MessagesService, private spinnerService: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) data) {
        if(typeof(data) != 'string'){
            this.currentTranscript = data;
        }else{
            this.noContentMessage = data;
        }
    }

ngOnInit() {}

save() {
    this.dialogRef.close(true);
}

close() {
    this.dialogRef.close(false);
}
}
