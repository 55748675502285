<h2 mat-dialog-title *ngIf='allItems'>All Transcripts</h2>

<!-- <div class="mat-dialog-question">TRANSCRIPT TABLE</div> -->

<div mat-dialog-body>
    <!-- <p>THIS WILL BE THE TABLE</p> -->
    <ul class="rooms-list">
            <li *ngFor="let item of allItems | paginate: { id: 'server', itemsPerPage: perPage, currentPage: currentPage, totalItems: fullLength }" (click)="clickedRoom(item)">
                {{ item.name }}
            </li>
        </ul>
        
        <div class="has-text-centered">
            <div class="spinner" [ngClass]="{ 'hidden': !loading }"></div>
            <!-- <pagination-controls (pageChange)="getPage($event)" id="server"></pagination-controls> -->
        </div>
    <!-- <p *ngFor='let item of allItems'><span id="curr-transcript-sentAt">{{item.name}}</span></p> -->

</div>