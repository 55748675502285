import { Component, OnInit, ChangeDetectorRef, AfterViewInit, Input, OnDestroy, Inject } from '@angular/core';
import { UsersService } from '../../service/users.service';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject, ReplaySubject } from 'rxjs';
import { LocalizationService } from '../../service/localization.service';
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { TicketService } from '../../service/ticket.service';
import { MentorSpinnerService } from '../../service/mentorspinner.service';
import { takeUntil } from 'rxjs/operators';
import { User } from '../../model/user';
import { ChatService } from '../../service/chatservice';
import { Ticket } from '../../model/ticket';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";


@Component({
  selector: 'app-previous-ticket',
  templateUrl: './client-previous-ticket.component.html',
  styleUrls: ['./client-previous-ticket.component.css']
})
export class ClientPreviousTicketComponent implements OnInit, OnDestroy {
  @Input() data: any;

  errMsg: string = "";
  public clientIdParam: string; public clientTypeParam: string;
  private pageName = "ClientPreviousTicketComponent";
  public pageTranslation: any = "";
  public pageTranslated = new BehaviorSubject<boolean>(false);
  public showTryAgain: boolean = true;
  displayedColumns = ["ParentTicketNumber", "Title", "CreatedDateStr", "Select"];
  displayedColumnsTelco = ["Title", "CreatedDateStr", "Select"];
  dataSource: any;
  currentUser: User;
  displayColumns: string = "";
  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private userService: UsersService, private ref: ChangeDetectorRef, private activatedRoute: ActivatedRoute, private localizationService: LocalizationService,
    private mspinnerService: MentorSpinnerService, private ticketService: TicketService, public chatService: ChatService,
    @Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog, private dialogRef: MatDialogRef<ClientPreviousTicketComponent>) {

    this.dataSource = new MatTableDataSource(data.ticketsArray);
    switch (this.userService.getClientTypeParam()) {
      case "Telco":
        this.displayColumns = "telco";
        break;
      case "Msp":
        this.displayColumns = "msp";
        break;
      case "Edu":
        this.displayColumns = "telco";
        break;

    }

    if (this.displayColumns == "telco") {
      this.displayedColumns = this.displayedColumnsTelco;
    }

  }
  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  ngAfterViewInit() { }

  ngOnInit() {
    this.SetLocalization();
    this.userService.currentUser.pipe(
      takeUntil(this.onDestroy$)).subscribe(
        (user: User) => {
          this.currentUser = user;
        });
  }

  SetLocalization() {
   
  }

  ContinueToChat() {
    this.chatService.connectToSignalR();
  }

  AssociateChat() {
    this.mspinnerService.ShowSpinner();

    const subGetTicketByUserPerMonth = this.ticketService.getTicketsByContact(this.currentUser.id,10)
      .subscribe((data: []) => {
        this.dataSource = new MatTableDataSource(data);
        this.mspinnerService.HideSpinner();
      });
  }

  clickedTicket(ticket: Ticket) {
    localStorage.setItem('previousTicketId', ticket.TicketNumber);
    this.chatService.connectToSignalR();
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }

}
