<div style="position: fixed; bottom: 20px; right: 17px; width: 350px;" *ngIf = "(noUnsweredRooms | async)?.length > 0">

  <button (click)="toggle()" id="toggle-button" *ngIf = "(noUnsweredRooms | async)?.length > 1" class="btnNotifications">
    {{toggleButtonName}}
  </button>

  <div id="notAnsweredID" class="conversation-wrap col-lg-12 col-md-12 col-sm-12" *ngIf = "showMore" style="border: 1px solid #a1a1a1;" >
    <chat-room-notification *ngFor="let myroom of noUnsweredRooms | async" [room]="myroom"> </chat-room-notification>
  </div>
  <div id="notAnsweredID" class="conversation-wrap col-lg-12 col-md-12 col-sm-12 " *ngIf = "!showMore" style="border: 1px solid #a1a1a1;">
    <chat-room-notification *ngFor="let myroom of noUnsweredRooms | async | slice:0:1" [room]="myroom"> </chat-room-notification>
  </div>
</div>
