
export class ResponseDataImage {
    Status: number;
    Message: string;
    HasError:boolean;
    ImageUrl:string;
    ThumbUrl: string;
    ContainerName: string;

    
    constructor(obj?: any) {
      
      this.Status = obj && obj.Status || -1;
      this.Message = obj && obj.Message || '';
      this.HasError = obj && obj.HasError || false;
      this.ImageUrl = obj && obj.ImageUrl || '';
      this.ThumbUrl = obj && obj.ThumbUrl || '';
      this.ContainerName = obj && obj.ContainerName || '';

    }
  }


  export class ResponseDataImageFactory{

    public static CreateResponse(response: any): ResponseDataImage {

        return new ResponseDataImage({
            Status: response.Status,
            Message: response.Message,
            HasError: response.HasError,
            ImageUrl:response.Data.ImageUrl,
            ThumbUrl:response.Data.ThumbUrl,
            ContainerName:response.Data.ContainerName
        });
    } 

    
}