<h2 mat-dialog-title>Add New Contact</h2>


<div mat-dialog-body>
  <form name="form" id="addContact" (ngSubmit)="save(myform.value)" [formGroup]="myform">
    <label for="First Name" class="label-contact">First Name</label>
    <input type="text" class="form-control" id="contactFirstName" name="firstname" formControlName="firstname" required />
    <div class="validation-errors">
      <div class="error-message" *ngIf="myform.get('firstname').hasError('required') && (myform.get('firstname').dirty || myform.get('firstname').touched) || (isValidFormSubmitted == false && myform.get('firstname').hasError('required'))">First Name is required</div>
    </div>
    <label for="Last Name" class="label-contact">Last Name</label>
    <input type="text" class="form-control" id="contactLastName" name="lastname" formControlName="lastname" required />
    <div class="validation-errors">
      <div class="error-message" *ngIf="myform.get('lastname').hasError('required') && (myform.get('lastname').dirty || myform.get('lastname').touched) || (isValidFormSubmitted == false && myform.get('lastname').hasError('required'))">Last Name is required</div>
    </div>
    <label for="Email" class="label-contact">Email</label>
    <input type="text" class="form-control" id="contactEmail" name="email" formControlName="email" required />
    <div class="validation-errors">
      <div class="error-message" *ngIf="myform.get('email').hasError('required') && (myform.get('email').dirty || myform.get('email').touched) || (isValidFormSubmitted == false && myform.get('email').hasError('required'))">Email is required</div>
    </div>
    <button type="submit" class="btn-chat btn-contact-add">Add Contact</button>
    <button class="btn-chat btn-contact-cancel" (click)="cancel()">Cancel</button>
  </form>
</div>
