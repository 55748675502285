<ng-template appCompHolder> </ng-template>


<div id="toggleReg" class="{{paramWidget}}" *ngIf="pageTranslated">
  <div class="regHeader" [style.background-color]="chatColor">
   <img class="partner-logo" src = "{{logoFullPath}}" onerror="this.onerror=null; this.src='assets/images/logo.png'">
  </div>

  <div class="regBody">

    <h1 class="cred1"> 
      <p [innerHtml]="pageTranslation.WelcomeToYourHelpDesk"></p>
      <!-- <br /> -->
      <p [innerHtml]="pageTranslation.PleaseSignIn"></p>    
    </h1>
    
    <form name="form" (ngSubmit)="disableSignInButton || registerUser(myform.value)" [formGroup]="myform">
      <!--EMAIL-->
      <div class="form-group">
        <label for="email"> {{pageTranslation.Email}} </label>
        <input type="email"
               class="form-control emailfield"
               formControlName="email"
               required />

        <div class="validation-errors">
          <div class="error-message" *ngIf="myform.get('email').hasError('pattern') && (myform.get('email').dirty || myform.get('email').touched)">{{pageTranslation.ValidEmail}}</div>
          <div class="error-message" *ngIf="myform.get('email').hasError('required') && (myform.get('email').dirty || myform.get('email').touched) || (isValidFormSubmitted == false && myform.get('email').hasError('required'))">{{pageTranslation.EmailRequired}}</div>
        </div>
      </div>

      <!--FNAME-->
      <div class="form-group">
        <label for="firstName"> {{pageTranslation.FirstName}} </label>
        <input type="text"
               class="form-control fnamefield"
               formControlName="firstName"
               required />

        <div class="validation-errors">
          <div class="error-message" *ngIf="myform.get('firstName').hasError('required') && (myform.get('firstName').dirty || myform.get('firstName').touched) || (isValidFormSubmitted == false && myform.get('firstName').hasError('required'))">{{pageTranslation.FNameRequired}}</div>
        </div>
      </div>

      <!--LNAME-->
      <div class="form-group">
        <label for="lastName"> {{pageTranslation.LastName}} </label>
        <input type="text"
               class="form-control lnamefield"
               formControlName="lastName"
               required />

        <div class="validation-errors">
          <div class="error-message" *ngIf="myform.get('lastName').hasError('required') && (myform.get('lastName').dirty || myform.get('lastName').touched) || (isValidFormSubmitted == false && myform.get('lastName').hasError('required'))">{{pageTranslation.LNameRequired}}</div>
        </div>
      </div>

      <!--MESSAGE-->
      <div class="form-group">
        <label for="message">{{pageTranslation.Message}}</label>

        <textarea class="form-control messagefield" placeholder="{{pageTranslation.TypeYourMessage}}" [(ngModel)]="draftMessage.text"
                  formControlName="message" required></textarea>

        <div class="validation-errors">
          <div class="error-message" *ngIf="myform.get('message').hasError('required') && (myform.get('message').dirty || myform.get('message').touched) || (isValidFormSubmitted == false && myform.get('message').hasError('required'))">{{pageTranslation.MessageRequired}}</div>
          <div class="error-message" *ngIf="(isValidFormSubmitted == false && myform.get('message').hasError('whitespace'))">{{pageTranslation.ValidMessage}}</div>
        </div>
      </div>

      <br />

      <div class="form-group">
        <button type="submit" class="btn btn-primary" [ngClass]="{ 'disabled': disableSignInButton }" [style.background-color]="chatColor">{{pageTranslation.SignIn}}</button>

      </div>

    </form>
    <h1 class="cred">{{pageTranslation.PoweredByGlobalMentoring}}</h1>
  </div>

</div>