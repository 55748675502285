<div *ngIf="showParentComponent">
       <div>
               <br/>

               <div>
                 <mat-form-field>
                     <input #searchUsers matInput placeholder="Search mentors by name">                  
                 </mat-form-field>
                 <div class="button-row" style="display: inline-block">
                    <a>
                        <button mat-stroked-button (click)="onSearchClick(searchUsers.value)">Search</button>
                    </a>
                 </div>
                 <div class="button-row" style="float:right;">
                        <a routerLink="./add">
                            <button  mat-stroked-button> Create New</button>
                        </a>
                </div>
               </div>
    
                <br />

                <mat-spinner *ngIf="showSpinner == true" color="warn" mode="indeterminate" style="margin: 0 auto !important;">
                        
                </mat-spinner>
                
                <mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef> ID </mat-header-cell>
                        <mat-cell *matCellDef="let user"> {{user.id}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef> Full Name </mat-header-cell>
                        <mat-cell *matCellDef="let user"> {{user.name}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef> E-Mail Address </mat-header-cell>
                        <mat-cell *matCellDef="let user"> {{user.email}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="role">
                        <mat-header-cell *matHeaderCellDef> Role </mat-header-cell>
                        <mat-cell *matCellDef="let user"> {{user.role}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="edit">
                                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                <mat-cell *matCellDef="let user">  
                                    <a routerLink="./edit/{{user.id}}">
                                            <button  mat-stroked-button>
                                                    Edit Mentor
                                            </button>
                                    </a>
                                </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="logout">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let user">  
                                <a (click)="onLogoutClick(user.id,user.name)"><button  mat-stroked-button>Remove Active Sessions</button>
                                </a>
                            </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="delete">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let user">  
                                <a (click)="onDeleteClick(user.id,user.name)"><button  mat-stroked-button>Deactivate Mentor</button>
                                </a>
                            </mat-cell>
                        </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;">
                        </mat-row>
                 </mat-table>
                 <mat-paginator #paginator (page)="onPageChanged($event)" [pageSizeOptions]="[10,20,50,100]" [pageSize]="10" [length]="fullLength">
                 </mat-paginator>
        </div>
    </div>

<div>
   <router-outlet></router-outlet>
</div>
