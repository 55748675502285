import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';

@Pipe({
  name: 'timeDiffPipe',
  pure: false
})
export class TimeDiffPipePipe implements PipeTransform {

  private currentValue: any = 0;

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  //transform(value: Date, debounceTime?: number): any {
  transform(value: any): string {
    
    if (this.currentValue !== this.GetFormatedTime(value)) {
      this.currentValue = this.GetFormatedTime(value);
      // setTimeout(() => {
      //   this.changeDetector.detectChanges();
      // });
      
    }
    
    return this.currentValue;
  }


  private GetFormatedTime(value: any): any {
    let now = new Date().getTime();

    let delta = (now - value) / 1000;
    if (delta < 0) return '';
    if (this.getHours(delta) > 0)
      return this.getHours(delta) + ':' + this.getMinutes(delta) + ':' + this.getSeconds(delta);
    else
      return this.getMinutes(delta) + ':' + this.getSeconds(delta);
  }

  private getSeconds(ticks: number) {
    return this.pad(Math.floor(ticks % 60));
  }

  private getMinutes(ticks: number) {
    return this.pad((Math.floor(ticks / 60)) % 60);
  }

  private getHours(ticks: number) {
    return this.pad(Math.floor((ticks / 60) / 60));
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }


}
