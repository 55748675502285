
import {map, distinctUntilChanged, filter, takeUntil, take} from 'rxjs/operators';
import {
  Component,
  OnInit,
  Inject,
  Input,
  Output,
  EventEmitter,
  ViewChild,  
  TemplateRef,
  ViewContainerRef
} from '@angular/core';
import { Observable, Subject,ReplaySubject, fromEvent, Subscription } from 'rxjs';
import { RoomsService } from '../../service/rooms.service';
import { User } from '../../model/user';
import { ContextMenuComponent } from '@perfectmemory/ngx-contextmenu';
import { ChatService } from '../../service/chatservice';
import { Room } from '../../model/room.model';
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatLegacyPaginatorModule as MatPaginatorModule} from "@angular/material/legacy-paginator";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {MatSortModule} from "@angular/material/sort";
import {MatLegacyTableModule as MatTableModule} from "@angular/material/legacy-table";
import {MatLegacyFormFieldModule as MatFormFieldModule, MatLegacyFormField as MatFormField} from "@angular/material/legacy-form-field";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import {MatLegacyTooltipModule as MatTooltipModule} from "@angular/material/legacy-tooltip";
import { MatMenuTrigger } from '@angular/material/menu';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
@Component({
  selector: 'chat-room-members',
  templateUrl: './chat-room-members.component.html',
  styleUrls: ['./chat-room-members.component.css']
})
export class ChatRoomMembersComponent implements OnInit {
  roomMembers: Observable<User[]>;
  roomId: string;
  private onDestroy$:ReplaySubject<boolean> = new ReplaySubject(1);
   @ViewChild(MatMenuTrigger)
   contextMenu: MatMenuTrigger;
  sub: Subscription;

  contextMenuPosition = { x: '0px', y: '0px' };

  //  @ViewChild('contextMenu') userMenu: TemplateRef<any>;

  //  overlayRef: OverlayRef | null;

  //  close() {
  //   this.sub && this.sub.unsubscribe();
  //   if (this.overlayRef) {
  //     this.overlayRef.dispose();
  //     this.overlayRef = null;
  //   }
  // }

  //  open({ x, y }: MouseEvent, user: User) {
  //   this.close();
  //   const positionStrategy = this.overlay.position()
  //     .flexibleConnectedTo({ x, y })
  //     .withPositions([
  //       {
  //         originX: 'end',
  //         originY: 'bottom',
  //         overlayX: 'end',
  //         overlayY: 'top',
  //       }
  //     ]);
  
  //   this.overlayRef = this.overlay.create({
  //     positionStrategy,
  //     scrollStrategy: this.overlay.scrollStrategies.close()
  //   });
  
  //   this.overlayRef.attach(new TemplatePortal(this.userMenu, this.viewContainerRef, {
  //     $implicit: user
  //   }));
  
  //   this.sub = fromEvent<MouseEvent>(document, 'click')
  //     .pipe(
  //       filter(event => {
  //         const clickTarget = event.target as HTMLElement;
  //         return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
  //       }),
  //       take(1)
  //     ).subscribe(() => this.close())
  
  // }

  onContextMenu(event: MouseEvent, item: User) {
    event.preventDefault();
    this.contextMenuPosition.x = event.clientX + 'px';
    this.contextMenuPosition.y = event.clientY + 'px';
    this.contextMenu.menuData = { 'item': item };
    //this.contextMenu.menu.focusFirstItem('mouse');
    
    this.contextMenu.openMenu();
    this.contextMenu.menu.resetActiveItem();
  }

  

  constructor(public roomsService: RoomsService, private chatService: ChatService,public overlay: Overlay,
    public viewContainerRef: ViewContainerRef) {
    const subCurrentRoom = this.roomsService.currentRoom.pipe(
    takeUntil(this.onDestroy$))
    .subscribe(room => {
      this.roomId = room.id
    });
    //this.ngZone.run(() => {
      
    //});
  }

  ngOnInit() {
    this.roomMembers = this.roomsService.currentRoomMembers.pipe(
    takeUntil(this.onDestroy$),
    filter((users:User[])=>{return users!==null&&users!==undefined}),
    distinctUntilChanged(),map((users:User[])=>{
      return users;
    }),);
  }

  removeUser(user: any): void {
    let userId = user.id;
    this.chatService.RemoveUser(userId, this.roomId);
  }

  banUser(user: any): void {
    this.chatService.BanUser(user.id, this.roomId);
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  } 
}
