<div id="wrapper">

        <nav id="sidebar" class="active">
            <div class="sidebar-header">
                <a class="navbar-brand" routerLink="/admin">
                    <img src="assets/images/logo.png" />
                </a>
                <strong>GMS</strong>
            </div>
    
            <ul class="list-unstyled components">
    
                <li>
                    <a routerLink="/admin/mentors">
                        <i class="glyphicon glyphicon-user"></i> Mentors Management
                    </a>
                </li>

                <li>
                    <a routerLink="/admin/users">
                        <i class="glyphicon glyphicon-user"></i> Chat Users Management
                    </a>
                </li>
    
                <li>
                        <a routerLink="/admin/mappings">
                            <i class="glyphicon glyphicon-th"></i> Mentors Skills Mapping
                        </a>
                </li>

                <li>
                    <a routerLink="/admin/vouchers">
                        <i class="glyphicon glyphicon-gift"></i> Vouchers
                    </a>
            </li>

                <li>
                    <a routerLink="/admin/settings">
                        <i class="glyphicon glyphicon-cog"></i> Settings
                    </a>
                </li>
                    
                <li>
                        <a (click)="logout()">
                            <i class="glyphicon glyphicon-off"></i> LogOut
                        </a>
                </li>
                   
            </ul>
        </nav>       
        
        <div class="adminWindow" id="content">
                <router-outlet></router-outlet>
        </div>
</div>


