<nav class="navbar navbar-default">
  <div class="container-fluid">
    <div class="navbar-header">
        
    </div>
    <p class="navbar-text navbar-right">
      <app-logout></app-logout>
   
    </p>
    <p class="navbar-text navbar-right">
      <app-user-settings></app-user-settings>
   
    </p>
    
  </div>
</nav>
