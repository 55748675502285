
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable, pipe } from 'rxjs';
import { Ticket } from '../model/ticket';
// import { Http, Response, URLSearchParams, RequestOptions,Headers } from '@angular/http';
import { TicketFactory } from '../model/TicketFactory';
import { messageFactory } from '../model/messageFactory';
import { User } from '../model/user';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../AppConfig';
import { TicketDraft } from '../model/ticketDraft';
import * as _ from 'lodash';
import { TicketDraftFactory } from '../model/TicketDraftFactory';
import { TicketData } from '../model/ticketData';
import { DomSanitizer } from '@angular/platform-browser';
import { ResponseDataImageFactory } from '../model/ResponseDataImage.model';

@Injectable()
export class TicketService {

  currentTicket: Subject<TicketData> = new BehaviorSubject<TicketData>(null);
  sTicketNumbers: Subject<Array<TicketData>> = new BehaviorSubject<Array<TicketData>>(null);
  clickedTicketInfo: Subject<any> = new Subject<any>();

  constructor(private _httpClient: HttpClient, private config: AppConfig,private sanitizer: DomSanitizer) {

  }

  public setCurrentTicket(newTicket: TicketData): void {
    localStorage.setItem('currentTicket', JSON.stringify(newTicket));
    this.currentTicket.next(newTicket);
  }

  public sendTicketNumbers(ticketArray: Array<TicketData>): void {
    
    this.sTicketNumbers.next(ticketArray);
  }

  public getTicketsByUserPerMonth(getMore: number, userId: string) {
    return this._httpClient.get(this.config.origin + "/api/autotask/getticketspermonth/" + userId + "/" + getMore).pipe(
      map((response: any) => {
        if (response && response.length > 0) {
          return (response).map(item => {
            return TicketFactory.createTicket(item);
          });
        }
        else {
          return {};
        }
      })
    );
  }

  public getTicketsByContact(userId: string, count: number) {
    return this._httpClient.get(this.config.origin + "/api/autotask/getticketsperContact/" + userId + "?count=" + count).pipe(
      map((response: any) => {
        if (response && response.length > 0) {
          return (response).map(item => {
            return TicketFactory.createTicket(item);
          });
        }
        else {
          return {};
        }
      }));
  }

  public getChatTranscriptByTicketId(ticketId: string) {
    return this._httpClient.get(this.config.origin + "/api/autotask/getchattranscriptbyticketid/" + ticketId).pipe(
      map((response: any) => {
        if (response.length > 0) {
          return (response).map(item => {
            return messageFactory.createMessagev3(item, item.User);
          });
        } else {
          return response.statusText;
        }
      }));
  };

  public checkPreviousTicketQueue(ticketNumber: string) {
    return this._httpClient.post(this.config.origin + "/api/autotask/checkpreviousticketqueue/" + ticketNumber, '', { responseType: 'text' })
  };

  public uploadImage(formData: FormData)
  {
    let headers = new HttpHeaders({
      
      });
       
    return this._httpClient.post<any>(this.config.origin + "/api/autotask/upload",formData, { headers: headers } ).pipe(map(resut=>{
      return ResponseDataImageFactory.CreateResponse(resut)}));
    
  }

  public downloadImage(formData: FormData)
  {
    let headers = new HttpHeaders({
      
      });
       
    return this._httpClient.post<any>(this.config.origin + "/api/autotask/download",formData, { headers: headers } )
  }

  public downloadFile(containerName:string, imageName:string) {
    
    return this._httpClient.get(this.config.origin +"/api/autotask/download/"+containerName+"/"+imageName.split('.')[0]+"/"+imageName.split('.')[1]+"", { responseType: 'blob'  });
  
  }

  public downloadPartnerLogoFile(containerName:string, imageName:string) {
    let headers = new HttpHeaders({
      'x-connectionid':  btoa(this.config.apikey+'') 
    });
    return this._httpClient.get(this.config.origin +"/api/autotask/downloadPartnerLogo/"+containerName+"/"+imageName.split('.')[0]+"/"+imageName.split('.')[1]+"", { responseType: 'blob' ,headers: headers });
  
  }

  public  blobToBase64 = blob => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise(resolve => {
      reader.onloadend = () => {
        resolve(reader.result);
      };
    });
  };

  savePendingChanges(ticketDraft: TicketDraft, roomId: string) {
    let headers: HttpHeaders = new HttpHeaders();
    return this._httpClient.post<any>(this.config.origin + '/api/popup/TicketDraft/' + roomId, ticketDraft, { headers: headers });
  }

  GetTicketDraft(roomId: any): Observable<TicketDraft> {
    return this._httpClient.get(this.config.origin + "/api/popup/TicketDraft/" + roomId).pipe(
      map(data => {
        if (data !== null && data !== undefined && data !== "undefined")
          return TicketDraftFactory.CreateDraft(data);
      }));
  }
}