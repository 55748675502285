import { Component, OnInit, Input, Output, EventEmitter,OnChanges, SimpleChanges, SimpleChange} from '@angular/core';
import { ChatUser } from '../../model/chatuser.model';

import { BehaviorSubject } from 'rxjs';
import { AdminService } from '../../service/admin.service';
import { ActivatedRoute, Router } from "@angular/router";
import { UsersService } from '../../service/users.service';
//import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { NgxSpinnerService } from "ngx-spinner";
import { customB1Validator } from '../../directive/customB1Validor';

@Component({
    selector: 'app-chat-user-edit',
    templateUrl: './chat-user-edit.component.html',
    styleUrls: ['./chat-user-edit.component.css']
  })

  export class AdminChatUserEditComponent implements OnInit {

    public chatUser:ChatUser
    @Output() onChatUserCloseClick = new EventEmitter();
    public fileList:any;
    public chatUserLogo = new BehaviorSubject<string>(null);
    public chatUserLogo$;
    hideActivateButton: boolean = false;
    hideRegisterButton: boolean = false;
    loading: boolean = false;
    b1pattern = "^b1([a-zA-Z]{4}[0-9]{2})$";
    emailPattern = "[a-zA-Z0-9.-_-]{1,}@[0-9a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
    myform: UntypedFormGroup;
    invalidB1:boolean = false;

    constructor(
      private spinnerService: NgxSpinnerService,
      private adminService:AdminService,
      private userService: UsersService,
      private route: ActivatedRoute,
      private router:Router){
      this.getChatUserDetails()
    }
    
    

    ngOnInit() {
      
    }

    getChatUserDetails(): void {
      const id = this.route.snapshot.paramMap.get("id");
      this.adminService.GetUserDetails(id).subscribe(data => {
        if (data){
          this.chatUser = data[0];
          this.chatUserLogo$ = this.chatUser.logo;

            if(this.chatUser.clientType==0)
            {

              this.myform = new UntypedFormGroup({
                email: new UntypedFormControl('', Validators.compose([
                  Validators.required,
                  Validators.pattern(this.emailPattern)
                ])),
                b1number: new UntypedFormControl('',  
                  Validators.compose([
                    Validators.required,
                    Validators.pattern(this.b1pattern)
                  ]))
              });
            }else
            {

              this.myform = new UntypedFormGroup({
                email: new UntypedFormControl('', Validators.compose([
                  Validators.required,
                  Validators.pattern(this.emailPattern)
                ])),
                b1number: new UntypedFormControl()
              });

            }

          

        }else {
          this.router.navigateByUrl('admin/users'); // no messing with query parameters!
        }
      });
    }

    onCloseClick(){
      this.router.navigateByUrl('admin/users');
    }

    onSaveClick(){

      if (this.myform.invalid) {
        alert("Please check your input values!")
        return;
     }

      if (this.fileList != null){
        if (this.fileList.length > 0) { 
          let file: File = this.fileList[0];  
          let formData: FormData = new FormData();  
          let fileName = file.name.split(".");
          let extension = fileName[1];
          let imageFullName = this.chatUser.id + "." + extension;
          formData.append('uploadFile', file, imageFullName);  
          this.adminService.UploadLogo(formData)
          .subscribe(data => {
            this.chatUserLogo.next(data.toString());
            this.chatUserLogo$ = this.chatUserLogo.getValue();
          },
          error => console.log(error) );
        }
      }
      
      this.adminService.UpdateUser(this.chatUser)
      .subscribe(data => {
        this.chatUser.isAtDown = false;
        alert("Changes successfuly saved!");
      },
      error => { console.log(error); alert("Problem occurred while saving changes, please try again.") });
  
    }

    onRegisterClick(event: any):void {
      this.spinnerService.show();
      //this is used if AT was down at the time the user was created.
      this.loading = true;
      this.adminService.RegisterUserInAt(this.chatUser).subscribe(data => {
        this.loading = false;
        this.hideRegisterButton = true;
        this.spinnerService.hide();
        alert("This user has been registered successfully");
      },
      error => {console.log(error); alert("A problem occured while registering this user, please try again."); this.spinnerService.hide();});
    }

    onCancelActivationClick(){
      //this is used if AT was down at the time the user was created.
      this.adminService.CancelRegistration(this.chatUser);
    }

    onActivateClick(){
      this.spinnerService.show();
      this.adminService.ActivateUser(this.chatUser).subscribe(data => {
        this.spinnerService.hide();
        this.hideActivateButton = true;
        alert("This user has been registered successfully");
      },
      error => {console.log(error); alert("A problem occured while registering this user, please try again."); this.spinnerService.hide(); });
    }

  fileChange(event) {  
    this.fileList = event.target.files;
  } 

  }
