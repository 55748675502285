export class MaintenanceMode{
    groupId: Number;
    loginAllowed: boolean;
    greetingMessage: string;

    constructor(obj?: any) {
        this.groupId = obj && obj.groupId;
        this.loginAllowed = obj && obj.loginAllowed;
        this.greetingMessage = obj && obj.greetingMessage;
    }
}