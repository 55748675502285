export class createTicket {
    ticketNumber:string;
    selectedStatus:number;
    selectedQueue:number;
    selectedContact:number;
    selectedIssue:number;
    selectedSubIssue:number;
    internalNote:string;
    privateNote:string;
    ticketTitle:string;
    currentRoomId:string;
    popupOpenedAt:string;
    isNhdTicket:boolean;
    selectedChildClient:number;
    parentAtAccountId:number;
    selectedPriority:number;
    isDefault:boolean;
    constructor(obj?: any) {
        this.ticketNumber = obj && obj.ticketNumber || '';
        this.selectedStatus = obj && obj.selectedStatus || -1;
        this.selectedQueue = obj && obj.selectedQueue || -1;
        this.selectedContact = obj && obj.selectedContact || -1;
        this.selectedIssue = obj && obj.selectedIssue || -1;
        this.selectedSubIssue = obj && obj.selectedSubIssue || -1;
        this.internalNote = obj && obj.internalNote || '';
        this.privateNote = obj && obj.privateNote || '';
        this.ticketTitle = obj && obj.ticketTitle || '';
        this.currentRoomId = obj && obj.currentRoomId || '';
        this.popupOpenedAt = obj && obj.popupOpenedAt || '';
        this.isNhdTicket = obj && obj.isNhdTicket || false;
        this.selectedChildClient = obj && obj.selectedChildClient || -1;
        this.parentAtAccountId = obj && obj.parentAtAccountId || -1;
        this.selectedPriority = obj && obj.selectedPriority || -1;
        this.isDefault = obj && obj.isDefault || false;
    }

}