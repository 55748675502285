
import { takeUntil} from 'rxjs/operators';
import { AfterViewInit, Component, OnInit, OnDestroy, Input, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable ,  BehaviorSubject, ReplaySubject,combineLatest, of } from 'rxjs';
import { RegisterProcessComponent } from '../register-process';
import { AlertService } from '../../service/alert.service';
import { UsersService } from '../../service/users.service';
import { MappingFields } from '../../model/MappingFields.model';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { User } from '../../model/user';
import { Message } from '../../model/message.model';
import { MessagesService } from '../../service/messages.service';
import { Room } from '../../model/room.model';
import { ChatService } from '../../service/chatservice';
import { RoomsService } from '../../service/rooms.service';

import { LocalizationService } from '../../service/localization.service';
import { MentorSpinnerService } from '../../service/mentorspinner.service';

import {CustomizationService} from '../../service/customization.service';
import { AppConfig } from '../../AppConfig';
import { ChatPersonalization } from '../../model/chatpersonalization.model';
import { ChatLabelSetup } from '../../model/ChatLabels.model';
import { MatTooltip } from '@angular/material/tooltip';
import { TicketService } from '../../service/ticket.service';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-register-chat-bell',
  templateUrl: './register-chat-bell.component.html',
  styleUrls: ['./register-chat-bell.component.css']
})
export class RegisterChatBellComponent implements RegisterProcessComponent, OnDestroy {
  @Input() data: any;

  model: any = {};
  showSelected: boolean = false;
  messageText: string;
  draftMessage: Message;
  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  messages: Observable<any>;
  myform: UntypedFormGroup;
  currentRoom: Room;
  userObject: User[];
  isChatInit: boolean;
  paramWidget: boolean = false;
  showFormErrors: boolean = false;
  emailPattern = "[a-zA-Z0-9.-_-]{1,}@[0-9a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  isValidFormSubmitted = null;
  b1pattern = "^[bB]1([a-zA-Z]{4}[0-9]{2})$|^[0-9]{7}$|^[bB]1([0-9]{1}[a-zA-Z0-9]{5})";
  aliantIdNumberPattern = "^[0-9]{7}$";
  disableSignInButton: boolean = false;
  public colorFileFullPath: string = '';
  public chatColor: string = '#0061AA';
  public butonTextColor:string='#FFFFFF';
  public clientIdParam: string; public clientTypeParam: string; public clientSubType: string = "Central";
  //localization - s
  private pageName = "RegisterChatBellComponent";
  public pageTranslation: any = "";
  public pageTranslated = new BehaviorSubject<boolean>(false);
  public twoCombined = new Observable<boolean>();
  public personalizationRetrived = new BehaviorSubject<boolean>(false);
  //localization - e
  public customChatLabelsSetup : ChatLabelSetup;
  @ViewChild("myTooltip") myTooltip: MatTooltip;
  public logoRetrived = new BehaviorSubject<boolean>(false);
  public logoFullPath: SafeUrl = '';

  constructor(private customizationService: CustomizationService, public mspinnerService: MentorSpinnerService, private router: Router, private activatedRoute: ActivatedRoute, private localizationService: LocalizationService,
    private userService: UsersService, private alertService: AlertService, fb: UntypedFormBuilder, injector: Injector, 
    public msgService: MessagesService, public chatService: ChatService, public roomsService: RoomsService,private config: AppConfig,
    private ticketService:TicketService,private sanitizer: DomSanitizer) {
    let clientId = userService.getClientIdParam();
    let clientType = userService.getClientTypeParam();
    
    const subAllItems = this.ticketService.downloadPartnerLogoFile('partners-logos', this.userService.getClientIdParam().toString() + '.png').
      pipe(takeUntil(this.onDestroy$)).subscribe(
        apiResponse => {

          if(apiResponse.size>0)
          {
            var url = URL.createObjectURL(apiResponse);
            this.logoFullPath = url;
          }else
          {
            this.logoFullPath ='assets/images/logo.png';
          }
          
          this.logoRetrived.next(true);
        },error => { console.log(error)
          this.logoFullPath ='assets/images/logo.png';
          this.logoRetrived.next(true);
         });

    //this.logoFullPath =customizationService.getNoCacheImage( this.config.logoURL + clientId.toString() + '.png');
    // this.colorFileFullPath = this.config.logoURL + this.userService.getClientIdParam().toString() + '.txt';

    // this.chatColor = customizationService.getPersonalizedColor(this.colorFileFullPath);
    // this.butonTextColor=userService.invertColor(this.chatColor,true);

    this.userService.cpSubject.subscribe(
      (data: ChatPersonalization) => {
        
        this.chatColor= data.colorCode;
        this.butonTextColor=userService.invertColor(data.colorCode,true);
        this.personalizationRetrived.next(true);
        //
      });

      
      // this.twoCombined= this.pageTranslated.pipe(combineLatest(
      //   this.personalizationRetrived, (pageT: boolean, personalizationR: boolean) => {

      //     return pageT&&personalizationR;
      //   }));

      combineLatest([this.pageTranslated, this.personalizationRetrived,  this.logoRetrived]).subscribe(
        ([pageT, personalizationR, logoReturned]) => {
          
            this.twoCombined=of(pageT && personalizationR  && logoReturned);
        });    

    this.SetLocalization();

    this.clientIdParam = clientId;
    this.clientTypeParam = clientType;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['isWidget']) {
        this.paramWidget = true;
      }

    });
    this.draftMessage = new Message();
    this.data = false;
    this.myform = new UntypedFormGroup({
      email: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(this.emailPattern)
      ])),
      b1number: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(this.b1pattern)
      ])),
      firstName: new UntypedFormControl(),
      lastName: new UntypedFormControl(),
      hostName: new UntypedFormControl(),
      message: new UntypedFormControl('', [Validators.required, this.noWhitespaceValidator]),
      isAtDown: new UntypedFormControl()
    });
  }

  public displayTooltip(event:string){

    if(event='mouseover')
    {
      this.myTooltip.disabled = false;
      this.myTooltip.show()

    }
    else
    {
      this.myTooltip.disabled = false;
      this.myTooltip.show()
      setTimeout(() => {
        this.myTooltip.disabled = true;
      }, 3000);
  
    }
    
  }

  public closeTooltip(){
    this.myTooltip.disabled = true;
    this.myTooltip.hide()
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then();
  }

  registerUser(model: any): void {

    this.isValidFormSubmitted = false;
    if (this.myform.invalid) {
      return;
    }

    this.isValidFormSubmitted = true;
    this.mspinnerService.ShowSpinner();
    let delayFlag: Boolean = false;

    if (localStorage.getItem("delayCurrentLogin")) {
      delayFlag = true;
    }
    else {
      localStorage.setItem("delayCurrentLogin", "true");
    }

    if (delayFlag) {      
      this.delay(5000).then(() => {
        this.registerUserGo(model);
      });
    } else {      
      this.registerUserGo(model);
    }

  }

  private registerUserGo(model: any) {

    this.myform.controls["isAtDown"].setValue(false);
    model.isAtDown = false;
    model.email = model.email.trim();

    if(model.hostName != null)
      model.hostName = model.hostName.trim();

    this.userService.RegisterToChatTelco(model)
      .subscribe(
        data => {
          this.alertService.success('User exists', true);
          this.userService.checkUser(model);
          localStorage.setItem("clientInitMessage",model.message);
          this.msgService.clientInitMessage.next(model.message);

          if(model.hostName){
            this.msgService.sendHostNameMessage.next(model.hostName);
          }

          this.data = true;
        },
        error => {
          this.mspinnerService.HideSpinner();
          if (localStorage.getItem("failedLogInAttempts") == "1") {
            localStorage.removeItem("failedLogInAttempts");
            this.userService.UserNotFound("notifyMentorsBell");
          }
          else  if (error.status == 404) {
            localStorage.setItem("failedLogInAttempts", "1");
            this.alertService.error("User not found!");
            this.data = false;
            this.userService.checkUser(this.data);
            this.userService.setAlreadyEnteredUserData(model);
          }
          else if (error.status == 417) {
            localStorage.setItem("failedLogInAttempts", "1");
            this.alertService.error("User not found!");
            this.data = false;
            this.userService.checkUser(this.data);
            this.myform.controls["isAtDown"].setValue(true);
            model.isAtDown = true;
            this.userService.setAlreadyEnteredUserData(model);
          }
          else {
            localStorage.setItem("failedLogInAttempts", "1");
            this.userService.UserNotFound(error.status.toString());
            return;
          }
        });
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {
      'whitespace': true
    };
  }

  SetLocalization() {
    var localeFromUrl = this.userService.getClientLanguageParam();
    var locale = this.localizationService.ValidateLocale(localeFromUrl);
    var clientId = Number.parseInt(this.userService.getClientIdParam());

    this.localizationService.GetLocalizationData().subscribe(data => {
      this.pageTranslation = data[0][this.pageName][locale];
      this.pageTranslated.next(this.pageTranslation);
    },error => { console.log(error) }, () => { });

    this.localizationService.GetCustomLabels(clientId,locale).subscribe(data => {
      this.customChatLabelsSetup = data;

      if(this.customChatLabelsSetup.Status == true){
        let customWelcomeToYourHelpDeskLbl = this.customChatLabelsSetup.Labels.filter(i => i.Type === 'WelcomeToYourHelpDesk');
        if(customWelcomeToYourHelpDeskLbl != null && customWelcomeToYourHelpDeskLbl != undefined && customWelcomeToYourHelpDeskLbl.length > 0){
          this.pageTranslation.PleaseSignIn = "";
          this.pageTranslation.WelcomeToYourHelpDesk = customWelcomeToYourHelpDeskLbl[0].Value;
        }

        let customSignInLbl = this.customChatLabelsSetup.Labels.filter(i => i.Type === 'SignIn');
        if(customSignInLbl != null && customSignInLbl != undefined && customSignInLbl.length > 0){
          this.pageTranslation.SignIn = customSignInLbl[0].Value;
        }
      }
      
    },error => { console.log(error) }, () => { });
  }

  notValid() {
    this.showFormErrors = true;
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
