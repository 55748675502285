import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { HttpInterceptor } from '@angular/common/http';
import { HttpRequest } from '@angular/common/http';
import { HttpHandler } from '@angular/common/http';
import { HttpEvent } from '@angular/common/http';

import { HttpHeaders } from '@angular/common/http';

import { AppConfig } from '../../AppConfig';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  constructor(private config: AppConfig) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    let token = localStorage.getItem('BearerToken');

    if (token !== '' && token != '' && token != null) {      

      var contentType='';
      if (request.headers.has('Content-Type'))
      contentType = request.headers.get('Content-Type');

      var req=request;
      if(request.url.includes('api/autotask/upload')){
         req = request.clone({
          url:request.url,
          setHeaders: {
            
            //'Content-Type' :'application/x-www-form-urlencoded',
            // 'Accept': 'z',
            //'Authorization': `Bearer ${token}`,
            'Authorization': `${token}`
            
          },
        });
      }else
      {
         req = request.clone({
          url:request.url,
          setHeaders: {
            'Content-Type':  (contentType != '' ? contentType : 'application/json'),
            //'Content-Type' :'application/x-www-form-urlencoded',
            // 'Accept': 'z',
            //'Authorization': `Bearer ${token}`,
            'Authorization': `${token}`
            
          },
        });
      }
      
      
      return next.handle(req);
      
    }
   

    return next.handle(request);
  }

}