import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CustomizationService {

  constructor() {

  }

  public getPersonalizedColor(colorFileFullPath: string){
    var allText = '';
    var raw = new XMLHttpRequest(); // create a request
    raw.open("GET", colorFileFullPath, false); // open file
    raw.onreadystatechange = function (){ // file is ready to read
      if(raw.readyState === 4){
        if(raw.status === 200 || raw.status == 0){
          allText = raw.responseText;
          }
        }
      }
      raw.send(null);
    return allText != '' ? allText : '#4d5198';
  }


  public getNoCacheImage(imgUrl: string): string{    
    return imgUrl + "?" + this.getRandomInt(1,1000000);
  }

  private getRandomInt(min, max): number {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

}
