import { SafeUrl } from "@angular/platform-browser";

export class MessageAttachment {
    ImageId: string;
    ImageUri: string;
    ThumbnailUri: string;
    ContainerName:string;
    FileName:string;
    MessageId:string;
    UserId:string;
    ImageUriUrl: SafeUrl;
    ThumbnailUriUrl: SafeUrl;

    constructor(obj?: any) {
      
      this.ImageId = obj && obj.ImageId || '';
      this.ImageUri = obj && obj.ImageUri || '';
      this.ThumbnailUri = obj && obj.ThumbnailUri || '';
      this.FileName = obj && obj.FileName || '';
      this.MessageId = obj && obj.MessageId || '';
      this.UserId = obj && obj.UserId || '';
      this.ContainerName= obj && obj.ContainerName || '';
      this.ImageUriUrl=obj && obj.ImageUriUrl || '';
      this.ThumbnailUriUrl=obj && obj.ThumbnailUriUrl || '';
    }
  }
