<div *ngIf="showParentComponent">
       <div>
               <br/>

               <div>
                 <mat-form-field>
                     <input #searchUsers matInput placeholder="Search users by name">                  
                 </mat-form-field>
                 <div class="button-row" style="display: inline-block">
                     <button mat-stroked-button (click)="onSearchClick(searchUsers.value)">Search</button>
                 </div>
                 <mat-checkbox labelPosition="before" [(ngModel)]="PendingActivationFilter">Pending Activation</mat-checkbox>
                 <mat-checkbox labelPosition="before" [(ngModel)]="AtDownStateFilter">AutoTask Down Time</mat-checkbox>
               </div>
    
                <br />

                <mat-spinner *ngIf="showSpinner == true" color="warn" mode="indeterminate">

                </mat-spinner>
                
                <mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="id">
                        <mat-header-cell *matHeaderCellDef> UUID </mat-header-cell>
                        <mat-cell *matCellDef="let user"> {{user.id}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="name">
                        <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
                        <mat-cell *matCellDef="let user"> {{user.name}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="email">
                        <mat-header-cell *matHeaderCellDef> E-Mail </mat-header-cell>
                        <mat-cell *matCellDef="let user"> {{user.email}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="edit">
                                <mat-header-cell *matHeaderCellDef></mat-header-cell>
                                <mat-cell *matCellDef="let user">
                                    
                                    <a routerLink="./edit/{{user.id}}">
                                            <button  mat-stroked-button>
                                                    Edit
                                            </button>
                                    </a>
                                    
                                </mat-cell>
                        </ng-container>

                        <ng-container matColumnDef="unban">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let user">
                                <a *ngIf="user.isBanned" (click)="unbanUser(user)">
                                        <button  mat-stroked-button>
                                                Unban
                                        </button>
                                </a> 
                            </mat-cell>
                         </ng-container>

                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;">
                        </mat-row>
                 </mat-table>
                 <mat-paginator #paginator (page)="onPageChanged($event)" [pageSizeOptions]="[10,50, 100]" [pageSize]="10" [length]="fullLength">
                 </mat-paginator>
        </div>
    </div>

<div>
   <router-outlet></router-outlet>
</div>
