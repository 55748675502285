
        <div class="col-md-12 col-lg-12 col-sm-12 chat-window-wrapper" style="padding: 0;display: inline-block;height: 100%;">
            
            <div class="col-md-9 col-lg-9 col-sm-9" style="display: inline-block;height: 100%;">
                <div class="chat-room-middle" style="display: inline-block;height: 100%;width:100%;">
                    <chat-window></chat-window>

                </div>
            </div>

            <div class="col-md-3 col-lg-3 col-sm-3" style="display: inline-block;height: calc(100vh - 70px);padding: 0;background-color: white;">
                <div class="col-md-12 col-lg-12 col-sm-12 chat-room-right">
                    <chat-room-members> </chat-room-members>
                    <hr>
                    <app-user-info-window></app-user-info-window>
                    <chat-room-notes></chat-room-notes>
                </div>
            </div>
            <div class="clear"></div>
        </div>


