export class UserChatTopic {
    issue:string;
    subissue:string;

    constructor(obj?: any) {
        this.issue = obj && obj.issue || null;
        this.subissue = obj && obj.subissue || null;
    }
}

//todo: do something
