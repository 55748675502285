import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuardAdmin implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (localStorage.getItem('currentAdminUser')) {
            let currentAdminUser = JSON.parse(localStorage.getItem('currentAdminUser'));
            if (currentAdminUser != null) {
                if (currentAdminUser.role.toUpperCase() == 'ADMIN' 
                || currentAdminUser.role.toUpperCase() == 'MSP ADMIN' 
                || currentAdminUser.role.toUpperCase() == 'TELCO ADMIN')
                    return true;
            }
        }

        this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}