
import { interval as observableInterval, Observable, Subject, Subscription, from, of } from 'rxjs';
import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  Renderer2,
  AfterViewInit,
  Output,
  EventEmitter
} from '@angular/core';




import { UsersService } from '../../service/users.service';

import { User } from '../../model/user';
import { Message } from '../../model/message.model';
import { ActivatedRoute } from '@angular/router';
import { LocalizationService } from '../../service/localization.service';
import { MessageType } from '../../model/messageType';
import { mergeMap, takeUntil, tap, toArray } from 'rxjs/operators';
import { TicketService } from '../../service/ticket.service';
import { DomSanitizer } from '@angular/platform-browser';
import { FullScreenImageModalComponent } from '../../modals/fullscreen-image-modal/fullscreen-image-modal.component';
import { MessageAttachment } from '../../model/MessageAttachment.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";


@Component({
  selector: 'chat-message',
  templateUrl: './chat-message.component.html',
  styleUrls: ['./chat-message.component.css']
})
export class ChatMessageComponent implements OnInit, AfterViewInit {
  @Input() message: Message;
  currentUser: User;
  incoming: boolean;
  intervalHolder: any;
  localLang: any = "en";
  private onDestroy$: Subject<void> = new Subject<void>();
  private focusElementSubject: Subscription;
  @Input() events: Observable<string>;
  @Output()
  sendChangeSignal: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild("msgContainer", { static: false }) MyProp: ElementRef;

  constructor(public UsersService: UsersService, private cd: ChangeDetectorRef, private activatedRoute: ActivatedRoute,
    private localizationService: LocalizationService, private renderer: Renderer2, public ticketService: TicketService,
    private sanitizer: DomSanitizer, private dialog: MatDialog,) {

  }
  ngAfterViewInit(): void {

    if (this.events != undefined)
      this.focusElementSubject = this.events.subscribe((msgId: string) => {
        if (this.message.id === msgId) {

          setTimeout(() => {

            if(document.getElementById('msgid-' + this.message.id))
              document.querySelector('.msg-container-base').scrollTop = document.getElementById('msgid-' + this.message.id).scrollHeight;
              
          });
        }
      });

    // if(this.message.messageAttachments && this.message.messageAttachments.length>0)
    // {
    //   from(this.message.messageAttachments).pipe(
    //     mergeMap(module => 
    //       {
    //         if(module.ThumbnailUriUrl=='')
    //           return this.ticketService.downloadFile(module.ContainerName,module.ThumbnailUri).pipe(
    //           tap(apiResponse => {
    //             let objectURL = URL.createObjectURL(apiResponse);       
    //             module.ThumbnailUriUrl = this.sanitizer.bypassSecurityTrustUrl(objectURL);
    //             this.sendChangeSignal.emit('send');
    //           }))
    //         else
    //           return of('').pipe(
    //             tap(() => {console.warn("already a blob")})
    //           );
    //     }
    //     ),
    //     toArray()
    //   ).subscribe(allResponses => {

    //   });

    // }

  }

  ngOnInit(): void {
    let i = 0;



    const subCurrentUser = this.UsersService.currentUser
      .subscribe(
        (user: User) => {
          this.currentUser = user;
          if (this.message.author && user) {

            if (this.message.author.id === user.id && (this.message.msgType == MessageType.Default || this.message.msgType == MessageType.Private))
              this.incoming = false;
            else this.incoming = true;

          }
        });

    //this.intervalHolder = observableInterval(1 * 60 * 1000).subscribe(x => { // will execute every 15 seconds      
    //  this.cd.markForCheck();
    //});
    var localeFromUrl = this.activatedRoute.snapshot.queryParams["lang"];
    this.localLang = this.localizationService.ValidateLocale(localeFromUrl);


  }

  openAttachemnt(attachment: MessageAttachment) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;


    dialogConfig.data = {
      ContainerName: attachment.ContainerName,
      ImageUri: attachment.ImageUri
    };

    dialogConfig.height = 'auto';
    dialogConfig.width = '90%';
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(FullScreenImageModalComponent, dialogConfig);
    const subDialogRef = dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(result => {

    });
  }

  getMessageRecivedClass(sent: Message): string {
    if (sent.msgType == MessageType.Private) {
      return 'private-message'
    } else if (sent.msgType == MessageType.System || sent.msgType == MessageType.Notification) {
      if (sent.text.includes('Please be aware that this user came in through "Whitelist Authentication" and does not exist in GMS Autotask.')
        || sent.text.includes('Please be aware that this user came in through "2 Factor Authentication" and does not exist in GMS Autotask.')
        || sent.text.includes('User associate this chat with previous ticket ')
      )
        return 'warning-message';
      else
        return 'notification-message'
    } else if (sent.msgType == MessageType.MentorSystem) {

      return 'warning-message';
    } else {
      return 'public-message'
    }
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
    clearInterval(this.intervalHolder);
  }


}
