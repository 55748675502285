<div>
       <div>
               <br/>
               <div>
                 <mat-form-field>
                     <input #searchVouchers matInput placeholder="Search by voucher ID">                  
                 </mat-form-field>
                 <div class="button-row" style="display: inline-block">
                    <a>
                        <button mat-stroked-button (click)="onSearchClick(searchVouchers.value)">Search</button>
                    </a>
                 </div>
                 <div class="button-row" style="float:right;">
                        <a routerLink="./add">
                            <button  mat-stroked-button>Create New</button>
                        </a>
                </div>
               </div>
    
                <br />

                <mat-spinner *ngIf="showSpinner == true" color="warn" mode="indeterminate">
                </mat-spinner>
                
                <mat-table [dataSource]="dataSource">
                        <ng-container matColumnDef="voucherId">
                        <mat-header-cell *matHeaderCellDef> UUID </mat-header-cell>
                        <mat-cell *matCellDef="let voucher"> {{voucher.voucherId}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="clientName">
                        <mat-header-cell *matHeaderCellDef> Client </mat-header-cell>
                        <mat-cell *matCellDef="let voucher"> {{voucher.clientName}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="redeemedByUser">
                        <mat-header-cell *matHeaderCellDef> Redeemed By </mat-header-cell>
                        <mat-cell *matCellDef="let voucher"> {{voucher.redeemedByUser}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="validFor">
                        <mat-header-cell *matHeaderCellDef> Valid For </mat-header-cell>
                        <mat-cell *matCellDef="let voucher"> {{voucher.validFor}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="expiryDate">
                        <mat-header-cell *matHeaderCellDef> Expires At </mat-header-cell>
                        <mat-cell *matCellDef="let voucher"> {{voucher.expiryDate}} </mat-cell>
                        </ng-container>
                        <ng-container matColumnDef="details">
                            <mat-header-cell *matHeaderCellDef></mat-header-cell>
                            <mat-cell *matCellDef="let voucher">
                            <a routerLink="./details/{{voucher.voucherId}}/{{voucher.clientName}}./details/{{voucher.voucherId}}/{{voucher.clientName}}">
                                <button  mat-stroked-button>Details</button>
                            </a>  
                            </mat-cell>
                         </ng-container>
                        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
                        <mat-row *matRowDef="let row; columns: displayedColumns;">
                        </mat-row>
                 </mat-table>
                 <mat-paginator #paginator (page)="onPageChanged($event)" [pageSizeOptions]="[10, 20,50, 100]" [pageSize]="10" [length]="fullLength">
                 </mat-paginator>
        </div>
    </div>

<div>
   <router-outlet></router-outlet>
</div>
