
import {interval as observableInterval,  Observable, Subject,ReplaySubject } from 'rxjs';

import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import { Room } from '../../model/room.model';
import { RoomsService } from '../../service/rooms.service';
import { Ticket } from '../../model/ticket';
import { ChatService } from '../../service/chatservice';


@Component({
  selector: 'chat-room-to-be-closed',
  templateUrl: './chat-room-to-be-closed.component.html',
  styleUrls: ['./chat-room-to-be-closed.component.css']
})
export class ChatRoomToBeClosedComponent implements OnInit {
  @Input() room: Room;
  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  intervalHolder: any;
  roomTimer: string;
  currentTicket: Ticket;
  private subjectClickToOpenPopup: Subject<any> = new Subject<any>();
  selected = false;

  constructor(private roomService: RoomsService,
    private chatService: ChatService,
    private changeDetectorRef: ChangeDetectorRef, ) {

  }

  ngOnInit() {
    this.intervalHolder = observableInterval(15 * 1000).pipe(takeUntil(this.onDestroy$)).subscribe(x => { // will execute every 15 seconds      
      this.changeDetectorRef.markForCheck();
    });

    const subCurrentRoom = this.roomService.currentRoom.pipe(
    takeUntil(this.onDestroy$))
      .subscribe((currentRoom: Room) => {
        this.selected = currentRoom && this.room && (currentRoom.id === this.room.id);
      });
  }

  joinSelectedRoom(curRoom: Room): void {       
    this.roomService.setCurrentRoom(curRoom);    
    this.roomService.handleCloseRoomSubject.next(curRoom.id);
  }
 
  getMentorName(): String {

    if (this.room.lastMessage != undefined)
      return this.room.lastMessage.author.userName;
    return "";
  }

  getTicketTitle(): String { 
    return "Chat: " + this.room.name;
  }

  private GetFormatedTime(value: Date): string {

    let now = new Date().getTime();

    let delta = 10;
    let tmp = (now - new Date(value).getTime());
    if (tmp > 0)
      delta = tmp / 1000;

    if (this.getHours(delta) > 0)
      return this.getHours(delta) + ':' + this.getMinutes(delta) + ':' + this.getSeconds(delta);
    else
      return this.getMinutes(delta) + ':' + this.getSeconds(delta);
  }

  private getSeconds(ticks: number) {
    return this.pad(Math.floor(ticks % 60));
  }

  private getMinutes(ticks: number) {
    return this.pad((Math.floor(ticks / 60)) % 60);
  }

  private getHours(ticks: number) {
    return this.pad(Math.floor((ticks / 60) / 60));
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }

  getStyle() {
    return 'gray';
  }
  public ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  } 
}
