
import { MatLegacyDialog as MatDialog,MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import { Component, Inject} from '@angular/core';

import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { TestBed } from "@angular/core/testing";

@Component({
    selector: 'app-volume-modal',
    templateUrl: './chat-volume-modal.component.html',
    styleUrls: ['./chat-volume-modal.component.css']
  })
  export class ChatVolumeModalComponent{
    model: any = {};
    showFormErrors: boolean = false;
    isValidFormSubmitted = null;
    dataModel:any={};
    volume:number=0;
    roomVolume:number=0;

    constructor(       
        private dialogRef: MatDialogRef<ChatVolumeModalComponent>,
                @Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog, fb: UntypedFormBuilder) {
                
                    this.dataModel=data;
                    
    }

    ngOnInit() {

      if(this.dataModel && !(this.dataModel && (Object.keys(this.dataModel).length === 0)))
      {
        if(this.dataModel.volume)
          this.volume= this.dataModel.volume
          if(this.dataModel.roomVolume)
          this.roomVolume= this.dataModel.roomVolume
      }
      else
      {
        this.volume=100;
        this.roomVolume=100;
      }
    }

    formatLabel(value: number): string {
        
        return `${value}`;
      }
    
    save(): void {
        var data={
          ChatVolume:this.volume,
          RoomVolume:this.roomVolume
        }
        
        this.dialogRef.close(data);
    }
    
    close() {
        this.dialogRef.close(false);
        
    }
    cancel() {
        
        this.dialogRef.close(false);
        
    }
  }
