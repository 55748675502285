<div class="msg-container" >
     

  <div class="avatar" *ngIf="incoming">

    <div class="messages-received" >
      <span class="authorname authorname1" >{{message.author.userName}}</span>
      <br/>
      <div class="msg-content msg-content1">
        <p class="chatWindow msg-text" [innerHtml]="message.text | linkify" class="public-message"></p>
      </div>
      
    </div>
  </div>

  <div class="avatar" *ngIf="!incoming">


    <div class="messages-sent">
      <span class="authorname authorname2" >{{message.author.userName}}</span>
      <br/>
      <div class="msg-content msg-content2">
        <p class="chatWindow msg-text" [innerHtml]="message.text | linkify" class="public-message"></p>
      </div>
      
    </div>
  </div>
</div>
