import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { LocalizationService } from '../../service/localization.service';
import { UsersService } from '../../service/users.service';

@Component({
  selector: 'app-client-feedback',
  templateUrl: './client-feedback.component.html',
  styleUrls: ['./client-feedback.component.css']
})
export class ClientFeedbackComponent implements OnInit {
  private pageName = "ClientFeedback";
  public pageTranslation: any = "";
  public pageTranslated = new BehaviorSubject<string>("");
  constructor(private userService: UsersService, private router: Router, private activatedRoute: ActivatedRoute, private localizationService: LocalizationService) {
    this.SetLocalization();
  }

  ngOnInit() {
  }

  onSurveyClick(surveyUrl: any, event: any) {
    var isWidget = this.userService.getClientWidgetParameter();;

    if (isWidget == "true") {
      window.open(surveyUrl, "_blank");
      window.location.reload();
    } else {
      event.preventDefault();
      setTimeout(function(){
      }, 2000);
      window.location.assign(surveyUrl);
    }
    localStorage.removeItem('isWidget');
  }

  SetLocalization() {
    var localeFromUrl = this.userService.getClientLanguageParam();
    var locale = this.localizationService.ValidateLocale(localeFromUrl);

    this.localizationService.GetLocalizationData().subscribe(data => {
      this.pageTranslation = data[0][this.pageName][locale];
      this.pageTranslated.next(this.pageTranslation);
    },
      error => { console.log(error) }, () => { });
  }

}
