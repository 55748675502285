import { Message } from "./message.model";
import { RoomFactory } from "./RoomFactory"
import { Room } from "./room.model";
import { User } from "./user";
import { UserFactory } from "./userFactory";
import { MessageType } from "./messageType";
import { MessageAttachment } from "./MessageAttachment.model";
import { MessageAttachmentFactory } from "./MessageAttachmentFactory.model";


export class messageFactory {

  public static createLeaveRoomMsg(roomId: String, userId: String): Message {
    return new Message({

    });
  }
  public static createMessagePending(messageVM: any, room: Room, usr: User): Message {
    return new Message({
      id: messageVM.Id,
      sentAt: messageVM.When,
      author: usr,
      text: messageVM.Content,
      room: room,
      owner: usr,
      msgType: MessageType.MarkAsPendingClosed,
      uninhibited: messageVM.Uninhibited,
      wasUninhibited: messageVM.WasUninhibited
    });
  }

  public static createEmptyMessage(): Message {
    return new Message({ id: "-1", room: new Room(), author: new User() });
  }
  public static createEmptyMessageForRoom(msg: Message): Message {

    //return new Message({msgType: MessageType.System, room:currRoom, author: currUser});

    return new Message({
      closedByUserId: msg.closedByUserId,
      sentAt: msg.sentAt,
      author: msg.author,
      text: msg.text,
      room: msg.room,
      owner: msg.author,
      msgType: msg.msgType,
      orgMsgType: msg.orgMsgType,
      uninhibited: msg.uninhibited,
      wasUninhibited: msg.wasUninhibited
    });
  }

  public static createMessage(messageVM: any, room: Room, usr: User): Message {
    return new Message({
      id: messageVM.Id,
      closedByUserId: messageVM.ClosedByUserId,
      sentAt: messageVM.When,
      author: usr,
      text: messageVM.Content,
      room: room,
      owner: usr,
      msgType: messageVM.MessageType,
      orgMsgType: messageVM.OrgMessageType,
      uninhibited: messageVM.Uninhibited,
      wasUninhibited: messageVM.WasUninhibited,
      messageAttachments:MessageAttachmentFactory.CreateMessageAttachmentFromList(messageVM.MessageAttachments)
    });
  }

  public static createMessageLowercase(messageVM: any, room: Room, usr: User): Message {
    return new Message({
      id: messageVM.id,
      closedByUserId: messageVM.closedByUserId,
      sentAt: messageVM.when,
      author: usr,
      text: messageVM.content,
      room: room,
      owner: usr,
      msgType: messageVM.messageType,
      orgMsgType: messageVM.orgMessageType,
      uninhibited: messageVM.uninhibited,
      wasUninhibited: messageVM.wasUninhibited,
      messageAttachments:MessageAttachmentFactory.CreateMessageAttachmentFromListLowerCase(messageVM.messageAttachments)
    });
  }


  public static createClientInitMessage(msgText: string): Message {
    return new Message({ text: msgText })
  }

  public static createMessagev2(message: any): Message {
    const me: any = new Message({
      id: message.Id,
      text: message.Content,
      msgType: message.MessageType,
      sentAt: new Date(message.When),
      messageAttachments:MessageAttachmentFactory.CreateMessageAttachmentFromList(message.MessageAttachments)
      //: clientVM.Name,
      // // status: clientVM.Status,
      // isActive: clientVM.IsActive,
      // status: clientVM.status
    });
    return me;
  }

  public static createMessagev3(message: any, usr: User): Message {
    const me: any = new Message({
      id: message.Id,
      text: message.Content,
      msgType: message.MessageType,
      sentAt: new Date(message.When),
      author: usr,
      orgMsgType:message.OrgMessageType,
      messageAttachments:MessageAttachmentFactory.CreateMessageAttachmentFromList(message.MessageAttachments)
    });
    return me;
  }


}
