<div id="login_form" class="col-lg-5 col-md-6 col-sm-8 col-xs-12 jsLoginForm" *ngIf="showLoginForm">

    <img [src]="fullImagePath">
    <h1 class="h1 mb-3 font-weight-normal" style="text-align: center; font-size: 40px;">Helpdesk Chat</h1>
    <form name="form" (ngSubmit)="f.form.valid && login()" #f="ngForm" novalidate>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
            <label for="username" class="label-text-new">Email address</label>
            <input type="text" class="form-control unamefield" name="username" [(ngModel)]="model.username" #username="ngModel" required />
            <div *ngIf="f.submitted && !username.valid" class="help-block">Email address is required</div>
        </div>
        <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
            <label for="password" class="label-text-new">Password</label>
            <input type="password" class="form-control passfield input-password-new" name="password" [(ngModel)]="model.password" #password="ngModel" required />
            <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
        </div>
        <div class="form-group">
            <button [disabled]="loading" class="btn btn-primary">Login</button>
        </div>
        <div *ngIf="showErrorMessage" [innerHtml] ="errorMessage" class="form-group" style="color:red !important;">           
        </div>
        <div class="clear"></div>
        <b class="version-display" style="color: white;">{{appVersion}}</b>
    </form>
</div>
