
import {ReplaySubject, combineLatest, of} from 'rxjs';
import { Component, OnInit, Input, Injector, ViewChild, ViewContainerRef, ComponentFactoryResolver, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable, Subject ,  BehaviorSubject } from 'rxjs';
import { RegisterProcessComponent } from '../register-process';
import { AlertService } from '../../service/alert.service';
import { UsersService } from '../../service/users.service';
import { MappingFields } from '../../model/MappingFields.model';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { User } from '../../model/user';
import { Message } from '../../model/message.model';
import { MessagesService } from '../../service/messages.service';
import { Room } from '../../model/room.model';
import { ChatService } from '../../service/chatservice';
import { RoomsService } from '../../service/rooms.service';
import { AdminService } from '../../service/admin.service';
import { LocalizationService } from '../../service/localization.service';
import { MentorSpinnerService } from '../../service/mentorspinner.service';
import { CustomizationService } from '../../service/customization.service';
import { MsalService } from '../../service/msal.service';
import { IssueService } from '../../service/issueservice';
import { AppConfig } from '../../AppConfig';
import { ChatPersonalization } from '../../model/chatpersonalization.model';
import { ChatLabelSetup } from '../../model/ChatLabels.model';
import { ChatLabel } from '../../model/ChatLabels.model';
import { MatTooltip } from '@angular/material/tooltip';
import { TicketService } from '../../service/ticket.service';
import { takeUntil } from 'rxjs/operators';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-register-chat-msp',
  templateUrl: './register-chat-msp.component.html',
  styleUrls: ['./register-chat-msp.component.css']
})
export class RegisterChatMspComponent implements RegisterProcessComponent, OnDestroy {
  @Input() data: any;
  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  model: any = {};
  showSelected: boolean = false;
  messageText: string;
  draftMessage: Message;
  paramWidget: boolean = false;
  messages: Observable<any>;
  myform: UntypedFormGroup;
  currentRoom: Room;
  userObject: User[];
  isChatInit: boolean;
  emailPattern = "[a-zA-Z0-9.-_-]{1,}@[0-9a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  isValidFormSubmitted = null;
  disableSignInButton: boolean = false;
  public logoFullPath: SafeUrl = '';
  public colorFileFullPath: string = '';
  public chatColor: string ;
  public butonTextColor: string;
  //localization - s
  private pageName = "RegisterChatMspComponent";
  public pageTranslation: any = "";
  public pageTranslated = new BehaviorSubject<boolean>(false);
  public personalizationRetrived = new BehaviorSubject<boolean>(false);
  public logoRetrived = new BehaviorSubject<boolean>(false);
  public clientsBanned = new BehaviorSubject<boolean>(false);
  public twoCombined = new Observable<boolean>();
  public showVerificationCode:boolean=false;
  public showVerificationCodeError:boolean=false;
  public VerificationCodeError:string="";
  public verificationReadonly:boolean=false;
  public showRegisterButton:boolean=true;
  //localization - e
public hasCustomChatLabels:boolean = false;
public customChatLabelsSetup : ChatLabelSetup;
@ViewChild("myTooltip") myTooltip: MatTooltip;


  constructor(private customizationService: CustomizationService, private mspinnerService: MentorSpinnerService, private router: Router,
    private userService: UsersService, private alertService: AlertService, fb: UntypedFormBuilder, injector: Injector, public msgService: MessagesService, public chatService: ChatService, public roomsService: RoomsService,
    private activatedRoute: ActivatedRoute,
    private localizationService: LocalizationService,
    private adminService: AdminService,
    private msalService: MsalService,
    private issueService: IssueService,
    private ticketService:TicketService,
    private config: AppConfig,
    private sanitizer: DomSanitizer) {

   
  }

  ngOnInit() {

    const subAllItems = this.ticketService.downloadPartnerLogoFile('partners-logos', this.userService.getClientIdParam().toString() + '.png').
      pipe(takeUntil(this.onDestroy$)).subscribe(
        apiResponse => {

          if(apiResponse.size>0)
          {
            var url = URL.createObjectURL(apiResponse);
            this.logoFullPath = url;
          }else
          {
            this.logoFullPath ='assets/images/logo.png';
          }
          
          this.logoRetrived.next(true);
        },error => { console.log(error)
          this.logoFullPath ='assets/images/logo.png';
          this.logoRetrived.next(true);
         });

    //this.logoFullPath = this.customizationService.getNoCacheImage(this.config.logoURL + this.userService.getClientIdParam().toString() + '.png');
    this.userService.cpSubject.subscribe(
      (data: ChatPersonalization) => {
        
        this.chatColor= data.colorCode;
        this.butonTextColor=this.userService.invertColor(data.colorCode,true);
        this.personalizationRetrived.next(true);
      });

      
      combineLatest([this.pageTranslated, this.personalizationRetrived, this.clientsBanned, this.logoRetrived]).subscribe(
        ([pageT, personalizationR, clientsBan, logoReturned]) => {
          
            this.twoCombined=of(pageT && personalizationR  && clientsBan && logoReturned);
        });
        
    this.SetLocalization();

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['isWidget']) {
        this.paramWidget = true;
      }
    });
    this.draftMessage = new Message();
    this.data = false;

    this.myform = new UntypedFormGroup({
      firstName: new UntypedFormControl(),
      lastName: new UntypedFormControl(),
      hostName: new UntypedFormControl(),
      email: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(this.emailPattern)
      ])),
      message: new UntypedFormControl('', [Validators.required, this.noWhitespaceValidator]),
      isAtDown: new UntypedFormControl(),
      verificationCode: new UntypedFormControl(),
    });
  }

  SetLocalization() {
    var localeFromUrl = this.userService.getClientLanguageParam();
    var locale = this.localizationService.ValidateLocale(localeFromUrl);
    var clientId = Number.parseInt(this.userService.getClientIdParam());

    

    this.localizationService.GetLocalizationData().subscribe(data => {
      this.pageTranslation = data[0][this.pageName][locale];
      this.pageTranslated.next(true);
    },error => { console.log(error) }, () => { });

    this.localizationService.GetBannedClients().subscribe(data => {
      if(data.includes(clientId.toString()))
      this.showRegisterButton=false;
      this.clientsBanned.next(true);
    },error => { console.log(error) }, () => { });

    this.localizationService.GetCustomLabels(clientId,locale).subscribe(data => {
      this.customChatLabelsSetup = data;

      if(this.customChatLabelsSetup.Status == true){
        let customWelcomeToYourHelpDeskLbl = this.customChatLabelsSetup.Labels.filter(i => i.Type === 'WelcomeToYourHelpDesk');
        if(customWelcomeToYourHelpDeskLbl != null && customWelcomeToYourHelpDeskLbl != undefined && customWelcomeToYourHelpDeskLbl.length > 0){
          this.pageTranslation.PleaseSignIn = "";
          this.pageTranslation.WelcomeToYourHelpDesk = customWelcomeToYourHelpDeskLbl[0].Value;
        }

        let customSignInLbl = this.customChatLabelsSetup.Labels.filter(i => i.Type === 'SignIn');
        if(customSignInLbl != null && customSignInLbl != undefined && customSignInLbl.length > 0){
          this.pageTranslation.SignIn = customSignInLbl[0].Value;
        }
      }
      
    },error => { console.log(error) }, () => { });
  }

  public displayTooltip(event:string){

    if(event='mouseover')
    {
      this.myTooltip.disabled = false;
      this.myTooltip.show()

    }
    else
    {
      this.myTooltip.disabled = false;
      this.myTooltip.show()
      setTimeout(() => {
        this.myTooltip.disabled = true;
      }, 3000);
  
    }
    
  }

  public closeTooltip(){
    this.myTooltip.disabled = true;
    this.myTooltip.hide()
  }


  login365(): void {
    this.disableSignInButton = true;
    this.mspinnerService.ShowSpinner();
    this.msalService.login();  
    this.msalService.authSubject.subscribe(() => {
    localStorage.setItem("isConnected", "true");
    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.callRegisterToChatMsp(currentUser);
    });
  }

  resendCode(model: any):void{
    model.firstName = model.firstName.trim();
    model.lastName = model.lastName.trim();
    model.email = model.email.trim();

    this.mspinnerService.ShowSpinner();

    this.userService.ResendVerification(model)
      .subscribe(
        data => {
         this.mspinnerService.HideSpinner();
         this.userService.clientTostrSuccessNotification.next("A verification code has been sent to your email address!");

        },
        error => {
          this.mspinnerService.HideSpinner();
          this.userService.clientTostrErrorNotification.next("Error sending verification code!");
        });

  }

  registerUser(model: any): void {

    this.isValidFormSubmitted = false;
    if (this.myform.invalid) {
      return;
    }
    this.disableSignInButton = true;
    this.mspinnerService.ShowSpinner();
    this.isValidFormSubmitted = true;
    this.myform.controls["isAtDown"].setValue(false);
    model.isAtDown = false;
    model.firstName = model.firstName.trim();
    model.lastName = model.lastName.trim();
    model.email = model.email.trim();
    
    if(model.hostName != null)
      model.hostName = model.hostName.trim();

    if(this.showVerificationCode)
    {
      model.verificationCode = model.verificationCode.trim();
      this.callRegisterVerificationChatMsp(model);
    }else
    {
      this.callRegisterToChatMsp(model);
    }
  }

  private callRegisterToChatMsp(model:any)
  {
    this.userService.RegisterToChatMsp(model)
      .subscribe(
        data => {
          this.alertService.success('User exists', true);
          this.userService.checkUser(model);
          localStorage.setItem("clientInitMessage",model.message)
          this.msgService.clientInitMessage.next(model.message);

          if(model.hostName){
            this.msgService.sendHostNameMessage.next(model.hostName);
          }

          this.data = true;
        },
        error => {
          this.mspinnerService.HideSpinner();
          if (localStorage.getItem("failedLogInAttempts") == "1" && error.status != 441) {
            this.userService.CreateFailedLoginTicket(model).subscribe(
              data => {
              },
              error => {
              });
            localStorage.removeItem("failedLogInAttempts");
            this.userService.UserNotFound("notifyMentors");
          }
          else if (error.status == 404) {
            localStorage.setItem("failedLogInAttempts", "1");
            this.alertService.error("User not found!");
            this.data = false;
            this.userService.checkUser(this.data);
            this.userService.setAlreadyEnteredUserData(model);
          }
          else if (error.status == 417) {
            localStorage.setItem("failedLogInAttempts", "1");
            this.alertService.error("User not found!");
            this.data = false;
            this.userService.checkUser(this.data);
            this.myform.controls["isAtDown"].setValue(true);
            model.isAtDown = true;
            this.userService.setAlreadyEnteredUserData(model);
          }
          else if (error.status == 440) {
            this.userService.UserNotFound(error.status.toString());  
          }
          else if (error.status == 441) {
            this.showVerificationCode=true;
            this.disableSignInButton = false;
            this.verificationReadonly=true;
            localStorage.removeItem("failedLogInAttempts");
            //localStorage.setItem("failedLogInAttempts", "0");
            //this.userService.UserNotFound(error.status.toString());  
          }
          else {
            localStorage.setItem("failedLogInAttempts", "1");
            this.userService.UserNotFound(error.status.toString());
          }
        });
  }

  private callRegisterVerificationChatMsp(model:any)
  {
    
    this.userService.RegisterMspWithVerification(model)
      .subscribe(
        data => {
          this.alertService.success('User exists', true);
          this.userService.checkUser(model);
          localStorage.setItem("clientInitMessage",model.message)
          this.msgService.clientInitMessage.next(model.message);

          if(model.hostName){
            this.msgService.sendHostNameMessage.next(model.hostName);
          }

          this.data = true;

        },
        error => {
          this.mspinnerService.HideSpinner();
          this.showVerificationCodeError=true;
          
          if (error.status == 442) {
            this.VerificationCodeError=error.error.toString();
            this.showVerificationCodeError=true;
            this.disableSignInButton=false;
          }
          else {
            localStorage.setItem("failedLogInAttempts", "1");
            this.userService.UserNotFound(error.status.toString());
          }
        });
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {
      'whitespace': true
    };
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
