<div #msgContainer class="msg-container"  *ngIf="!(message.text==='' && message.messageAttachments && message.messageAttachments.length==0)"  id='msgid-{{message.id}}'>

  <div class="avatar" *ngIf="incoming">

    <div class="messages-received">
      <span class="authorname authorname1"
        *ngIf="message.msgType === 5">Chat Auto Assistant (System)</span>
      <span class="authorname authorname1"
        *ngIf="message.msgType !== 5 && message.msgType !== 2 && message.msgType !== 6
        && message.msgType !== 7">{{message.author.userName}}</span>
      <br />
      <div class="msg-content msg-content1">
        <p id="chatMessageTextId" class="chatWindow msg-text"  [innerHtml]="message.text | linkify"
          [ngClass]="getMessageRecivedClass(message)"></p>
        <div *ngIf="message.messageAttachments && message.messageAttachments.length>0">

          <span *ngFor="let attachment of message.messageAttachments">
            <img alt="image" *ngIf="attachment.ThumbnailUriUrl!=''" [src]="attachment.ThumbnailUriUrl | safe"
              (click)="openAttachemnt(attachment)" style="width: 100%; height: 100%; cursor: pointer;" />
            <img *ngIf="attachment.ThumbnailUriUrl==''" src="assets/images/image-loader.gif"
              style="width: 50px; height: 50px;" />
          </span>

        </div>
      </div>
      <p class="time time1"> • {{message.sentAt | fromNow : localLang}}</p>
    </div>
  </div>

  <div class="avatar" *ngIf="!incoming">

    <div class="messages-sent">
      <span class="authorname authorname1"
        *ngIf="message.msgType === 5">Chat Auto Assistant (System)</span>
      <span class="authorname authorname2"
        *ngIf="message.msgType !== 5  && message.msgType !== 2 
        && message.msgType !== 7" >{{message.author.userName}}</span>
      <br />
      <div class="msg-content msg-content2">
        <p id="chatMessageTextId" class="chatWindow msg-text" [innerHtml]="message.text | linkify"
          [ngClass]="(message.orgMsgType==3)?'private-message':'public-message'"></p>
        <div *ngIf="message.messageAttachments && message.messageAttachments.length>0">
          <span *ngFor="let attachment of message.messageAttachments">

            <div id="component-id" [style.background-image]="'url('+(attachment.ThumbnailUriUrl | safe)+')'">

            </div>

            <img alt="image" *ngIf="attachment.ThumbnailUriUrl!=''" [src]="attachment.ThumbnailUriUrl | safe"
              (click)="openAttachemnt(attachment)" style="width: 100%; height: 100%; cursor: pointer;" />

            <img *ngIf="attachment.ThumbnailUriUrl==''" src="assets/images/image-loader.gif"
              style="width: 50px; height: 50px;" />
          </span>
        </div>
      </div>
      <p class="time time2"> • {{message.sentAt | fromNow : localLang}}</p>
    </div>
  </div>
</div>