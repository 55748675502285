<div id="chatRoomNotificationContent" class="media conversation" style.background-color="{{getRoomColor()}}" style.color="{{getRoomNameColor()}}" style="font-weight: bolder; height: 70px; border-radius: 1px;">

  <div class="pull-left nameTime">
  
    <small class="message-preview">{{getMentorName()}}</small>
    <small class="message-preview">{{room.createdAt | timeDiffPipe}}</small>
  </div>
  <div class="media-body positionDot">
    <h5 class="media-heading contact-name nameContact" style="font-weight:bold;">{{room.name}}</h5>
    <span class="positionSpan" *ngIf="selected">
      <img src="assets/images/pin-icon.png" />
    </span>

    <small class="message-preview">{{room.session}}</small>
    <small class="message-preview">{{room.type}}</small>
    <small class="message-preview">{{room.language}}</small>

  </div>

  <a (click)="joinRoom()" class="div-link">Select</a>
</div>
