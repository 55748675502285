
export class Contact {
  id: number;
  name: string;
  isActive: boolean;
  email:string;

  constructor(obj?: any) {
    this.id = obj && obj.id || '-1';
    if(obj.id == 0){
      this.id = obj.id;
    }

    this.name = obj && obj.name || '';
    this.isActive = obj && obj.isActive || false;
    this.email = obj && obj.email || '';
  }


}