import {
  Component,
  Inject,
  OnInit
} from '@angular/core';
import * as _ from 'lodash';


import { RoomsService } from '../../service/rooms.service';
import { Room } from '../../model/room.model';
import { MessagesService } from '../../service/messages.service';
import { Message } from '../../model/message.model';
import { AdminService } from '../../service/admin.service';

@Component({
  selector: 'chat-nav-bar',
  templateUrl: './chat-nav-bar.component.html',
  styleUrls: ['./chat-nav-bar.component.css']
})
export class ChatNavBarComponent implements OnInit {
  unreadMessagesCount: number;
  
  constructor(public messagesService: MessagesService,
              public roomsService: RoomsService) {
  }

  ngOnInit(): void {

    


    // this.messagesService.messages
    //   .combineLatest(
    //     this.roomsService.currentRoom,
    //     (messages: Message[], currentRoom: Room) =>
    //       [currentRoom, messages] )

    //   .subscribe(([currentRoom, messages]: [Room, Message[]]) => {
    //     this.unreadMessagesCount =
    //       _.reduce(
    //         messages,
    //         (sum: number, m: Message) => {
    //           const messageIsInCurrentRoom: boolean = m.room &&
    //             currentRoom &&
    //             (currentRoom.id === m.room.id);
    //           // note: in a "real" app you should also exclude
    //           // messages that were authored by the current user b/c they've
    //           // already been "read"
    //           if (m && !m.isRead && !messageIsInCurrentRoom) {
    //             sum = sum + 1;
    //           }
    //           return sum;
    //         },
    //         0);
    //   });
  }
}
