import { TicketDraft } from "./ticketDraft";

export class TicketDraftFactory {

    public static CreateDraft(draft: any): TicketDraft {

        return new TicketDraft({
          ID: draft.ID,
          TicketNumber: draft.TicketNumber,
          Title:draft.Title,
          Status:draft.Status,
          Queue:draft.Queue,
          SelectedChild:draft.SelectedChild,
          Contact:draft.Contact,
          Issue: draft.Issue,
          SubIssue: draft.SubIssue,
          InternalNote:draft.InternalNote,
          PrivateNote:draft.PrivateNote,
          PopupOpenedAt:draft.PopupOpenedAt,
          SelectedTemplate:draft.SelectedTemplate,   
          SelectedChildClientInitial:draft.SelectedChildClientInitial,   
        });
    } 
}
