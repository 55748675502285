export class DecryptedUserInfo {
    email: string;
    firstName: string;
    lastName: string;
    message: string;
  
    constructor(email: string, fname: string, lname: string, message: string) {
      this.email = email;
      this.firstName = fname;
      this.lastName = lname;
      this.message = message;
    }
  }