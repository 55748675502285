
import {filter, map, refCount, publishReplay, scan, mergeMap, tap, toArray, flatMap} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Subject, Observable, BehaviorSubject, from, of } from 'rxjs';
import { Message } from '../model/message.model';
import { Room } from '../model/room.model';
import { User } from '../model/user';
import { MessageType } from '../model/messageType';
import { MessageAttachment } from '../model/MessageAttachment.model';
import { DomSanitizer } from '@angular/platform-browser';
import { TicketService } from './ticket.service';




const initialMessages: Message[] = [];

interface IMessagesOperation extends Function {
  (messages: Message[]): Message[];
}

@Injectable()
export class MessagesService {
  // a stream that publishes new messages only once
  newMessages: Subject<Message> = new Subject<Message>();
  prevMessages: Subject<Message[]> = new Subject<Message[]>();
  deleteRoomMessages: Subject<any> = new Subject<any>();
  clearNotAcitveRooms: Subject<any> = new Subject<any>();
  clearAllMessages: Subject<any> = new Subject<any>();
  updateMsgsOnReset: Subject<any> = new Subject<any>();


  markAsClosedMessages: Subject<any> = new Subject<any>();
  markAsAttendedMessages: Subject<any> = new Subject<any>();
  refreshMessages: Subject<any> = new Subject<any>();

  clickCurrentTranscript: Subject<Array<Message>> = new Subject<Array<Message>>();
  clientInitMessage: Subject<string> = new BehaviorSubject<string>('');
  newMessageNotification: Subject<any> = new Subject<any>();

  eduSendClassNameMessage: Subject<string> = new BehaviorSubject<string>('');

  currentRoomMsgsAreLoaded: Subject<any> = new Subject<any>();

  typingNotification: Subject<User> = new Subject<User>();
  typingNotificationCancel: Subject<User> = new Subject<User>();

  roomsLatestMessages: Message[];
  roomsLatestMessagesChange: Subject<Message[]> = new Subject<Message[]>();

  // `messages` is a stream that emits an array of the most up to date messages
  messages: Observable<Message[]>;

  // `updates` receives _operations_ to be applied to our `messages`
  // it's a way we can perform changes on *all* messages (that are currently
  // stored in `messages`)
  updates: Subject<any> = new Subject<any>();


  // action streams
  createPrevMessages: Subject<Message[]> = new Subject<Message[]>();
  create: Subject<Message> = new Subject<Message>();
  markRoomAsRead: Subject<any> = new Subject<any>();

  clear: Subject<any> = new Subject<any>();
  clearAll: Subject<any> = new Subject<any>();
  delete: Subject<any> = new Subject<any>();
  markAsClosed: Subject<any> = new Subject<any>();
  markAsAttended: Subject<any> = new Subject<any>();
  leave: Subject<any> = new Subject<any>();

 sendHostNameMessage: Subject<string> = new BehaviorSubject<string>('');

  constructor( public sanitizer: DomSanitizer, public ticketService:TicketService) {


    this.messages = this.updates.pipe(
      // watch the updates and accumulate operations on the messages
      scan((messages: Message[],
        operation: IMessagesOperation) => {

        return operation(messages);
      },
        initialMessages),
      // make sure we can share the most recent list of messages across anyone
      // who's interested in subscribing and cache the last known list of
      // messages      

      publishReplay(1),
      refCount(),);

    // `create` takes a Message and then puts an operation (the inner function)
    // on the `updates` stream to add the Message to the list of messages.
    //
    // That is, for each item that gets added to `create` (by using `next`)
    // this stream emits a concat operation function.
    //
    // Next we subscribe `this.updates` to listen to this stream, which means
    // that it will receive each operation that is created
    //
    // Note that it would be perfectly acceptable to simply modify the
    // "addMessage" function below to simply add the inner operation function to
    // the update stream directly and get rid of this extra action stream
    // entirely. The pros are that it is potentially clearer. The cons are that
    // the stream is no longer composable.
    this.create.pipe(
      map(function (message: Message): IMessagesOperation {
        return (messages: Message[]) => {
          console.log("cnt# " + messages.length);
          try {



            //check if msg is duplicate            
            var index = messages.findIndex((msg) => msg.id == message.id);
            console.log('cnt# ' + messages.length);


            if (index !== -1) {
              //update message time
              messages.splice(index, 1, message);
              return messages;
            }


            if (message.msgType === MessageType.Notification) {

              var tmpMsgs = messages;
              let firstMsg = messages.find((msg) => msg.author.id === message.author.id && msg.room.id === message.room.id && msg.msgType === MessageType.Notification && msg.uninhibited == false);

              tmpMsgs.filter((msg) => msg.author.id === message.author.id && msg.room.id === message.room.id && msg.msgType === MessageType.Notification && msg.uninhibited == false)
                .map((message: Message) => {
                  if (firstMsg.id !== message.id) {
                    let index = messages.findIndex(m => m.id === message.id);
                    tmpMsgs.splice(index, 1);
                  }
                });

              messages = tmpMsgs;
            }

            

            return messages.concat(message);
          }
          catch (err) {

          }
        };
      }))
      .subscribe(this.updates);

    this.roomsLatestMessagesChange.subscribe((latests:Message[])=>{
        this.roomsLatestMessages= latests;
    });

    this.newMessages
      .subscribe(this.create);

    // this.createPrevMessages.pipe(
    //   mergeMap((msgs: Message[])=>{
        
    //   })
    // )

    this.createPrevMessages.pipe(
      map(function (msgs: Message[]): IMessagesOperation {
        return (messages: Message[]) => {

          msgs.forEach(function (msg) {

            var index = messages.findIndex((elt) => elt.id === msg.id);
            if (index === -1)
              messages.push(msg);
          });
          
          return messages;
        };
      }))
      .subscribe(this.updates);




    this.prevMessages.subscribe(this.createPrevMessages);

    // delete msgs when leaving the room
    this.delete.pipe(
      map(function (roomId: String): IMessagesOperation {
        return (messages: Message[]) => {

          var index = messages.findIndex((elt) => elt.room.id === roomId);
          while (index !== -1) {
            messages.splice(index, 1);
            index = messages.findIndex((elt) => elt.room.id === roomId);
          }
          return messages;
        };
      }))
      .subscribe(this.updates);

    this.deleteRoomMessages
      .subscribe(this.delete);

    /////////////////////////////////////////
    this.clear.pipe(
      map(function (roomId: String): IMessagesOperation {
        return (messages: Message[]) => {

          if (messages.length > 800) {
            console.log("CLEAR IN ACTION....")
            var index = messages.findIndex((elt) => elt.room.id !== roomId);
            while (index !== -1) {
              messages.splice(index, 1);
              index = messages.findIndex((elt) => elt.room.id !== roomId);
            }
          }
          return messages;
        };
      }))
      .subscribe(this.updates);

    this.clearNotAcitveRooms
      .subscribe(this.clear);

      this.clearAll.pipe(
        map(function (roomId: String): IMessagesOperation {
          return (messages: Message[]) => {
            messages.splice(0,messages.length)
           
            return messages;
          };
        }))
        .subscribe(this.updates);
  
      this.clearAllMessages
        .subscribe(this.clearAll);

    ////////////////////////



    this.markAsClosed.pipe(
      map(function (ids: any[]): IMessagesOperation {
        return (messages: Message[]) => {

          let roomId = ids[0];
          let authorId = ids[1];
          let notificationMessge = ids[2];


          var index = messages.findIndex((elt) => elt.room.id === roomId);
          while (index !== -1) {
            messages.splice(index, 1);
            index = messages.findIndex((elt) => elt.room.id === roomId);
          }

          notificationMessge.msgType = MessageType.MarkAsPendingClosed;
          notificationMessge.closedByUserId = notificationMessge.author.id;

          messages.push(notificationMessge);

          return messages;
        };
      }))
      .subscribe(this.updates);

    this.markAsClosedMessages
      .subscribe(this.markAsClosed);



    this.markAsAttended.pipe(
      map(function (ids: any[]): IMessagesOperation {
        return (messages: Message[]) => {


          var index = messages.findIndex((elt) => elt.room.id === ids[0] && elt.author.id == ids[1] && elt.uninhibited);
          while (index !== -1) {
            var msg = messages[index];
            msg.uninhibited = false;
            messages.splice(index, 1, msg);

            index = messages.findIndex((elt) => elt.room.id === ids[0] && elt.author.id == ids[1] && elt.uninhibited);
          }
          return messages;
        };
      }))
      .subscribe(this.updates);

    this.markAsAttendedMessages
      .subscribe(this.markAsAttended);



    this.leave.pipe(
      map(function (ids: any[]): IMessagesOperation {
        return (messages: Message[]) => {

          var index = messages.findIndex((elt) => elt.room.id === ids[0] && elt.author.id == ids[1] && elt.uninhibited == false);
          while (index !== -1) {
            try {

              var msg = messages[index];
              msg.uninhibited = true;
              msg.wasUninhibited = true;

              messages.splice(index, 1, msg);

              index = messages.findIndex((elt) => elt.room.id === ids[0] && elt.author.id == ids[1] && elt.uninhibited == false);
            }
            catch (err) {

              console.log('ERROR LEAVE: ' + index + ' ' + err);
            }
          }

          return messages;
        };
      }))
      .subscribe(this.updates);


    const compareFn = (a, b) => {
      if (a.sentAt > b.sentAt) {
        return 1;
      } else if (b.sentAt > a.sentAt) {
        return -1;
      } else {
        return 0;
      }
    };
    const compareFnDesc = (a, b) => {
      if (a.sentAt > b.sentAt) {
        return -1;;
      } else if (b.sentAt > a.sentAt) {
        return 1;;
      } else {
        return 0;
      }
    };

    this.refreshMessages.subscribe(this.leave);


    // similarly, `markRoomAsRead` takes a Room and then puts an operation
    // on the `updates` stream to mark the Messages as read
    this.markRoomAsRead.pipe(
      map((room: Room) => {
        return (messages: Message[]) => {
          return messages.map((message: Message) => {
            // note that we're manipulating `message` directly here. Mutability
            // can be confusing and there are lots of reasons why you might want
            // to, say, copy the Message object or some other 'immutable' here
            if (message.room.id === room.id) {
              message.isRead = true;
            }
            return message;
          });
        };
      }))
      .subscribe(this.updates);

  }

  addMessage(message: Message): any {
    this.newMessages.next(message);

  }
  // abv
  addMessages(messages: Message[]): void {
    this.prevMessages.next(messages);
  }


  markMsgsAsClosedForRoom(roomId: String, authorId: String, notificationMsg: Message): void {
    let ids = [roomId, authorId, notificationMsg];
    this.markAsClosedMessages.next(ids);
  }

  deleteMessageForRoom(roomId: String): void {
    this.deleteRoomMessages.next(roomId);
  }

  clearNotActiveRooms(roomId: String): void {
    this.clearNotAcitveRooms.next(roomId);
  }

  clearAllRoomsMessages(roomId: String): void {
    this.clearAllMessages.next(roomId);
  }

  markMsgsAsAttendedForRoom(roomId: String, userId: String): void {
    let ids = [roomId, userId];
    this.markAsAttendedMessages.next(ids);
  }

  leaveRoom(roomId: String, userId: String): void {
    let ids = [roomId, userId];
    this.refreshMessages.next(ids);
  }

  messagesForRoomUser(room: Room, user: User): Observable<Message> {
    return this.newMessages.pipe(
      filter((message: Message) => {
        // belongs to this room
        return (message.room.id === room.id) &&
          // and isn't authored by this user
          (message.author.id !== user.id);
      }));
  }
  addTypingNotification(usr: User): void {
    this.typingNotification.next(usr);
  }

  cancelTypingNotification(usr: User): void {
    this.typingNotificationCancel.next(usr);
  }

  showCurrentTranscript(messages: Array<Message>): void {
    this.clickCurrentTranscript.next(messages);
  }

  getLastMessageForTheRoom(roomId:string):string{
    var filteredMessage=this.roomsLatestMessages.filter(msg=>msg.room.id==roomId);
    if(filteredMessage.length>0)
    return filteredMessage[0].id;
    return null;
  }

}

export const messagesServiceInjectables: Array<any> = [
  MessagesService
];
