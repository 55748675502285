
<br /><br />
<form name="myform" [formGroup]="myform" *ngIf="chatUser" class="example-form">
    <mat-form-field class="example-full-width">
        <input style="font-weight: bold;" matInput disabled placeholder="UUID" value="{{chatUser.id}}">
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input matInput placeholder="Fullname" [(ngModel)]="chatUser.name" [ngModelOptions]="{standalone: true}">
    </mat-form-field>

    <mat-form-field class="example-full-width">
        <input matInput formControlName="email" required placeholder="Email Address" [(ngModel)]="chatUser.email">
    </mat-form-field>

    <mat-form-field class="example-full-width" *ngIf="chatUser.clientType==0">
        <input matInput formControlName="b1number"  placeholder="B1 number (e.g. format b1xxxx##) (ONLY FOR TELCO USERS)" [(ngModel)]="chatUser.b1number">
    </mat-form-field>

    <br />

    <!-- <div *ngIf="chatUser.logo.length > 0" class="example-full-width mat-input-container mat-form-field ng-tns-c9-1 mat-primary mat-form-field-type-mat-input mat-form-field-can-float mat-form-field-should-float mat-form-field-hide-placeholder">
        <div class="mat-input-wrapper mat-form-field-wrapper">
        <div class="mat-input-flex mat-form-field-flex">
        <div class="mat-input-infix mat-form-field-infix">
        <span class="mat-form-field-label-wrapper mat-input-placeholder-wrapper mat-form-field-placeholder-wrapper">
        <label class="mat-form-field-label mat-input-placeholder mat-form-field-placeholder ng-tns-c9-1 ng-star-inserted" for="mat-input-1" aria-owns="mat-input-1">Current Logo</label></span></div></div>
        <img class="chatUserLogo" [src]="chatUserLogo$">
        <br /><br />
        <div class="mat-input-underline mat-form-field-underline">
        <span class="mat-input-ripple mat-form-field-ripple"></span></div>
        <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
        <div class="mat-input-hint-wrapper mat-form-field-hint-wrapper ng-tns-c9-1 ng-trigger ng-trigger-transitionMessages ng-star-inserted" style="opacity: 1; transform: translateY(0%);">
        <div class="mat-input-hint-spacer mat-form-field-hint-spacer">
        </div></div></div></div>
    </div>

    <div class="example-full-width mat-input-container mat-form-field ng-tns-c9-1 mat-primary mat-form-field-type-mat-input mat-form-field-can-float mat-form-field-should-float mat-form-field-hide-placeholder">
        <div class="mat-input-wrapper mat-form-field-wrapper">
        <div class="mat-input-flex mat-form-field-flex">
        <div class="mat-input-infix mat-form-field-infix">
        <span class="mat-form-field-label-wrapper mat-input-placeholder-wrapper mat-form-field-placeholder-wrapper">
        <label class="mat-form-field-label mat-input-placeholder mat-form-field-placeholder ng-tns-c9-1 ng-star-inserted" for="mat-input-1" aria-owns="mat-input-1">Upload New Logo</label></span></div></div>
        <input class="fileUpload" accept="image/png" value="Upload logo" type="file" (change)="fileChange($event)" class="upload" >
        <br />
        <div class="mat-input-underline mat-form-field-underline">
        <span class="mat-input-ripple mat-form-field-ripple"></span></div>
        <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
        <div class="mat-input-hint-wrapper mat-form-field-hint-wrapper ng-tns-c9-1 ng-trigger ng-trigger-transitionMessages ng-star-inserted" style="opacity: 1; transform: translateY(0%);">
        <div class="mat-input-hint-spacer mat-form-field-hint-spacer">
        </div></div></div></div>
    </div> -->

    <br />

    <div>
        <div *ngIf="chatUser.isAtDown" [ngClass]="{ 'hidden': hideRegisterButton }">
            <p>This user was created when AutoTask was down.<br />Please register this user if you find the information provided to be authentic.</p>
            <button mat-stroked-button (click)="onRegisterClick($event)">Register</button>
            <hr />
        </div>
        <div *ngIf="chatUser.pendingActivation == 1" [ngClass]="{ 'hidden': hideActivateButton }">
            <p>This user hasn't been activated. If you find the information above to be authentic, click "Activate".</p>
            <button mat-stroked-button (click)="onActivateClick()">Activate</button>
            <hr />
        </div>
    </div>

    <div class="button-row">
        <button mat-stroked-button (click)="onSaveClick()">Save</button>
        <button mat-stroked-button color="warn" (click)="onCloseClick()">Close</button>
    </div>

</form>
