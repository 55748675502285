<br /><br />

<form *ngIf="mentorUser" class="example-form" name="form" (ngSubmit)="disableSignInButton || onSaveClick(myform.value)" [formGroup]="myform">

    <mat-form-field class="example-full-width" *ngIf="mentorUser.id != '-1'">
        <input style="font-weight: bold;" matInput disabled placeholder="UUID" value="{{mentorUser.id}}">
    </mat-form-field>

    <div class="form-group">
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Fullname" formControlName="name" required>
      </mat-form-field>
      <!-- <label for="name"> Name </label>
      <input type="text"
               class="form-control fnamefield"
               formControlName="name"
               [readonly]="verificationReadonly"
               
               required /> -->
      <div class="validation-errors">
        <div class="error-message" *ngIf="myform.get('name').hasError('required') && (myform.get('name').dirty || myform.get('name').touched) || (isValidFormSubmitted == false && myform.get('name').hasError('required'))">FullName is required!</div>
      </div>
    </div>
    <div class="form-group">
      <mat-form-field class="example-full-width">
        <input matInput placeholder="Display Name" formControlName="displayName" >
      </mat-form-field>
      <!--<label for="displayName"> DisplayName </label>
       <input type="text"
               class="form-control fnamefield"
               formControlName="displayName"
               [readonly]="verificationReadonly"
               
               required /> -->
      <!-- <div class="validation-errors">
        <div class="error-message" *ngIf="myform.get('displayName').hasError('required') && (myform.get('displayName').dirty || myform.get('displayName').touched) || (isValidFormSubmitted == false && myform.get('displayName').hasError('required'))">DisplayName is required!</div>
      </div> -->
    </div>
    <div class="form-group">
      <mat-form-field class="example-full-width">
          <input matInput placeholder="Email Address" formControlName="email" required>
      </mat-form-field>
      <!-- <label for="email"> Email Address </label>
      <input type="text"
               class="form-control fnamefield"
               formControlName="email"
               [readonly]="verificationReadonly"
               
               required /> -->
      <div class="validation-errors">
        <div class="error-message" *ngIf="myform.get('email').hasError('required') && (myform.get('email').dirty || myform.get('email').touched) || (isValidFormSubmitted == false && myform.get('email').hasError('required'))">Email is required!</div>
      </div>
    </div>

    <div class="form-group">
      <!-- <label for="role"> Role </label>

               <select class="form-control" formControlName="role">
                  
                  <option *ngFor="let role of userRoles" value={{role.name}} [selected]="role.name === mentorUser.role" >
                    {{role.name}}
                  </option>
                </select> -->
     <mat-form-field appearance="fill">
        <mat-label>Role</mat-label>
        <mat-select formControlName="role"   name="role" required>
          <mat-option *ngFor="let rle of userRoles" [value]="rle.name" >
            {{rle.name}}
          </mat-option>
        </mat-select>
      </mat-form-field> 
    
      <div class="validation-errors">
        <div class="error-message" *ngIf="myform.get('role').hasError('required') && (myform.get('role').dirty || myform.get('role').touched) || (isValidFormSubmitted == false && myform.get('role').hasError('required'))">Role is required!</div>
      </div>
    </div> 
    <!-- <div *ngIf="mentorUser.logo.length > 0" class="example-full-width mat-input-container mat-form-field ng-tns-c9-1 mat-primary mat-form-field-type-mat-input mat-form-field-can-float mat-form-field-should-float mat-form-field-hide-placeholder">
        <div class="mat-input-wrapper mat-form-field-wrapper">
        <div class="mat-input-flex mat-form-field-flex">
        <div class="mat-input-infix mat-form-field-infix">
        <span class="mat-form-field-label-wrapper mat-input-placeholder-wrapper mat-form-field-placeholder-wrapper">
        <label class="mat-form-field-label mat-input-placeholder mat-form-field-placeholder ng-tns-c9-1 ng-star-inserted" for="mat-input-1" aria-owns="mat-input-1">Current Logo</label></span></div></div>
        <img class="mentorUserLogo" [src]="mentorUserLogo$">
        <br /><br />
        <div class="mat-input-underline mat-form-field-underline">
        <span class="mat-input-ripple mat-form-field-ripple"></span></div>
        <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
        <div class="mat-input-hint-wrapper mat-form-field-hint-wrapper ng-tns-c9-1 ng-trigger ng-trigger-transitionMessages ng-star-inserted" style="opacity: 1; transform: translateY(0%);">
        <div class="mat-input-hint-spacer mat-form-field-hint-spacer">
        </div></div></div></div>
    </div> -->

    <!-- <div *ngIf="mentorUser.id != -1" class="example-full-width mat-input-container mat-form-field ng-tns-c9-1 mat-primary mat-form-field-type-mat-input mat-form-field-can-float mat-form-field-should-float mat-form-field-hide-placeholder">
        <div class="mat-input-wrapper mat-form-field-wrapper">
        <div class="mat-input-flex mat-form-field-flex">
        <div class="mat-input-infix mat-form-field-infix">
        <span class="mat-form-field-label-wrapper mat-input-placeholder-wrapper mat-form-field-placeholder-wrapper">
        <label class="mat-form-field-label mat-input-placeholder mat-form-field-placeholder ng-tns-c9-1 ng-star-inserted" for="mat-input-1" aria-owns="mat-input-1">Upload Logo</label></span></div></div>
        <input class="fileUpload" accept="image/png" value="Upload logo" type="file" (change)="fileChange($event)" class="upload" >
        <br />
        <div class="mat-input-underline mat-form-field-underline">
        <span class="mat-input-ripple mat-form-field-ripple"></span></div>
        <div class="mat-input-subscript-wrapper mat-form-field-subscript-wrapper">
        <div class="mat-input-hint-wrapper mat-form-field-hint-wrapper ng-tns-c9-1 ng-trigger ng-trigger-transitionMessages ng-star-inserted" style="opacity: 1; transform: translateY(0%);">
        <div class="mat-input-hint-spacer mat-form-field-hint-spacer">
        </div></div></div></div>
    </div> -->

    <!-- <mat-checkbox [checked]= "mentorUser.isControlPanelAdministrator" (change)="mentorUser.isControlPanelAdministrator = !mentorUser.isControlPanelAdministrator" > 
        <label> Is Administrator </label> 
    </mat-checkbox> -->

    <br />

    <div class="button-row">
        <button mat-stroked-button type="submit" [ngClass]="{ 'disabled': disableSignInButton }">Save</button>
        <button *ngIf="mentorUser.id != '-1'" mat-stroked-button color="primary" (click)="onSendInvitationClick()">Re-invite</button>
        <button mat-stroked-button color="warn" (click)="onCloseClick()">Close</button>
    </div>

</form>
