<div id="customerThermometerWrapper">

  <p style="font-family: Arial; font-size: 12px;"><b>{{pageTranslation.AreYouHappy}}</b></p>

  <div class="survey-wrapper" style="font-family: Arial; font-size: 12px; overflow:hidden;">
    <table style="border-collapse: separate;  margin: 0 0; width: 320px;" cellspacing="0" cellpadding="0" border="0">
      <tbody>

        <tr>
          <td style="vertical-align:bottom; text-align:center;" width="25%">
            <a href="#" style="text-decoration:none; cursor: pointer;" (click)="onSurveyClick('https://app.customerthermometer.com/?template=log_feedback&amp;hash=6120ec40&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9MSZ0aGVybW9tZXRlcl9pZD02MjU3MA==&amp;e=EMAIL&amp;f=FIRST_NAME&amp;l=LAST_NAME&amp;c=&amp;c1=CUSTOM_1&amp;c2=CUSTOM_2&amp;c3=CUSTOM_3&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10=', $event)">
              <img style="border: none;" src="assets/images/Gold-1.jpg" alt="Gold Star" title="Gold Star" class="_temp">
            </a>
          </td>
          <td style="text-align:center;"><span style="color:#B17657;">{{pageTranslation.GreatJob}}</span></td>
        </tr>

        <tr>
          <td style="vertical-align:bottom; text-align:center;" width="25%">
            <a href="#" style="text-decoration:none; cursor: pointer;" (click)="onSurveyClick('https://app.customerthermometer.com/?template=log_feedback&amp;hash=15e3e842&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9MiZ0aGVybW9tZXRlcl9pZD02MjU3MA==&amp;e=EMAIL&amp;f=FIRST_NAME&amp;l=LAST_NAME&amp;c=&amp;c1=CUSTOM_1&amp;c2=CUSTOM_2&amp;c3=CUSTOM_3&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10=', $event)">
              <img style="border: none;" src="assets/images/Green-1.jpg" alt="Green Light" title="Green Light" class="_temp">
            </a>
          </td>
          <td style="text-align:center;"><span style="color:#66CC33;">{{pageTranslation.NicelyDone}}</span></td>
        </tr>

        <tr>
          <td style="vertical-align:bottom; text-align:center;" width="25%">
            <a href="#" style="text-decoration:none; cursor: pointer;" (click)="onSurveyClick('https://app.customerthermometer.com/?template=log_feedback&amp;hash=56fd950b&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9MyZ0aGVybW9tZXRlcl9pZD02MjU3MA==&amp;e=EMAIL&amp;f=FIRST_NAME&amp;l=LAST_NAME&amp;c=&amp;c1=CUSTOM_1&amp;c2=CUSTOM_2&amp;c3=CUSTOM_3&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10=', $event)">
              <img style="border: none;" src="assets/images/Amber-1.jpg" alt="Yellow Light" title="Yellow Light" class="_temp">
            </a>
          </td>
          <td style="text-align:center;"><span style="color:#FFB40D;">{{pageTranslation.RoomForImprovement}}</span></td>
        </tr>

        <tr>
          <td style="vertical-align:bottom; text-align:center;" width="25%">
            <a href="#" style="text-decoration:none; cursor: pointer;" (click)="onSurveyClick('https://app.customerthermometer.com/?template=log_feedback&amp;hash=254ab76f&amp;embed_data=dGVtcGVyYXR1cmVfaWQ9NCZ0aGVybW9tZXRlcl9pZD02MjU3MA==&amp;e=EMAIL&amp;f=FIRST_NAME&amp;l=LAST_NAME&amp;c=&amp;c1=CUSTOM_1&amp;c2=CUSTOM_2&amp;c3=CUSTOM_3&amp;c4=&amp;c5=&amp;c6=&amp;c7=&amp;c8=&amp;c9=&amp;c10=', $event)">
              <img style="border: none;" src="assets/images/Red-1.jpg" alt="Red Light" title="Red Light" class="_temp">
            </a>
          </td>
          <td style="text-align:center;"><span style="color:#CC3333;">{{pageTranslation.NeedToTalk}}</span></td>
        </tr>

      </tbody>
    </table>
  </div>
  <p style="font-family: Arial; font-size: 12px;">{{pageTranslation.ValuableFeedback}}<br />{{pageTranslation.ClickYourExperience}}</p>

</div>
