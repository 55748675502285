import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuardClient implements CanActivate {

    constructor(private router: Router) { 
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        // authenticated mentor should not have access to the client section
        // authenticated user should not open the same link in multiple browsers (vase stop)

        let prevent = false;

        if (localStorage.getItem('currentUser')) {
            let currentUser = JSON.parse(localStorage.getItem('currentUser'));
            if (currentUser != null) {
              if (currentUser.isMentor){
                prevent = true;
              }
            }   
        }
        
        if (prevent){
            this.router.navigate(['']);
        }
        else {
            return true;
        }
        
    }
}