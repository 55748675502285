import { Component, OnInit, ComponentFactoryResolver, ViewChild, ElementRef } from '@angular/core';
import { AdminService } from '../../service/admin.service';
import { ChatUser } from '../../model/chatuser.model';
import { CollectionViewer, DataSource } from "@angular/cdk/collections";
import { Observable ,  BehaviorSubject ,  Subject } from 'rxjs';


import {
  MatLegacyInputModule as MatInputModule
} from "@angular/material/legacy-input";
import {
  MatLegacyProgressSpinnerModule as MatProgressSpinnerModule
} from "@angular/material/legacy-progress-spinner";
import {
  MatSortModule
} from "@angular/material/sort";
import {
  MatLegacyTableModule as MatTableModule
} from "@angular/material/legacy-table";
import {
  MatLegacyFormFieldModule as MatFormFieldModule
} from "@angular/material/legacy-form-field";
import {
  MatIconModule
} from "@angular/material/icon";

import { MatLegacyPaginatorModule as MatPaginatorModule,MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import { ComponentItem } from '../../home/chat-panel/componentItem';
import { CompHolderDirective } from '../../home/chat-panel/comp-holder.directive';
import { AdminChatUserEditComponent } from '../chat-user-edit/chat-user-edit.component';

@Component({
  selector: 'app-admin-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})

export class AdminUsersManagementComponent implements OnInit {
  defaultPageNumber: number = 1;
  defaultPageSize: number = 10;
  defaultSearchCriteria: string = "";
  allItems: any[] = [];
  perPage: number = 10;
  fullLength: number;
  currentPage: number;
  totalPages: number;
  currentClickedUser: any;
  public userId: string;
  public chatUsers: ChatUser[];
  dataSource: ChatUserDataSource;
  displayedColumns = ["id", "name", "email", "edit","unban"];

  PendingActivationFilter: boolean = false;
  AtDownStateFilter: boolean = false;

  @ViewChild(MatPaginator,{static:false}) paginator: MatPaginator;
  @ViewChild('input',{static:false}) input: ElementRef;
  @ViewChild(CompHolderDirective,{static:true}) adHost: CompHolderDirective;

  menuComponents: ComponentItem[];
  public showParentComponent: Boolean = true;
  public showChildComponent: Boolean;
  public chatUserObject: ChatUser;
  private searchCriteria$ = "";
  public showSpinner: Boolean = true;

  constructor(private adminService: AdminService, public componentFactoryResolver: ComponentFactoryResolver) {
    this.countUsers(this.defaultSearchCriteria);
  }

  onChatUserCloseClickHandled(data: any) {
    this.ngOnInitPage();
    this.showChildComponent = false;
    this.showParentComponent = true;
  }

  ngOnInit() {
    this.menuComponents = [
      new ComponentItem(AdminChatUserEditComponent, { title: "Chat User Edit" })
    ];
    this.ngOnInitPage();
    this.showSpinner = false;
  }

  ngOnInitPage() {
    this.dataSource = new ChatUserDataSource(this.adminService);
    this.dataSource.loadChatUsers(this.defaultPageNumber, this.defaultPageSize, this.defaultSearchCriteria, this.PendingActivationFilter, this.AtDownStateFilter);
  }

  onPageChanged(paginator: any) {
    this.dataSource.loadChatUsers(paginator.pageIndex + 1, paginator.pageSize, this.searchCriteria$, this.PendingActivationFilter, this.AtDownStateFilter);
  }

  countUsers(searchCriteria: string) {
    this.adminService.CountUsers(searchCriteria,false, this.PendingActivationFilter, this.AtDownStateFilter).subscribe(data => {
      this.fullLength = data;
    });
  }

  onEdit(data: any) {
    this.showParentComponent = false;
    this.chatUserObject = data;
    this.showChildComponent = true;
  }

  onSearchClick(searchCriteria: string) {
    this.showSpinner = true;
    if (searchCriteria) {
      this.searchCriteria$ = searchCriteria;
    } else {
      this.searchCriteria$ = "";
    }

    this.countUsers(this.searchCriteria$);
    this.dataSource.loadChatUsers(this.defaultPageNumber, this.defaultPageSize, this.searchCriteria$, this.PendingActivationFilter, this.AtDownStateFilter);
    this.showSpinner = false;
  }

  unbanUser(user:ChatUser){
    this.adminService.UnbanUser(user.id).subscribe(data => {
      if (data.status == 200){
        alert("Success!");
        user.isBanned = false;
      }
    });
  }
} 

export class ChatUserDataSource implements DataSource<any> {
  private chatUsersSubject = new BehaviorSubject<ChatUser[]>([]);
  constructor(private adminService: AdminService) { }

  connect(collectionViewer: CollectionViewer): Observable<ChatUser[]> {
    return this.chatUsersSubject.asObservable();
  }
  disconnect(collectionViewer: CollectionViewer) {
    this.chatUsersSubject.complete();
  }

  loadChatUsers(pageIndex: number, pageSize: number, searchCriteria: string, pendingActivationFilted: boolean, atDownStateFilter: boolean) {
    this.adminService.GetChatUsers(pageIndex, pageSize, searchCriteria, false, pendingActivationFilted, atDownStateFilter).subscribe(data => {
      this.chatUsersSubject.next(data);
    });
  }
}


