export class GenericSelectItem {
    value: string;
    viewValue: string;

    public static Create(clientVM: any, customItem:Boolean): GenericSelectItem {
      let me: GenericSelectItem = new GenericSelectItem();

      if (customItem){
        me.value = clientVM.ID;
        me.viewValue = clientVM.Name;
      }
      else {
        me.value = clientVM.Value;
        me.viewValue = clientVM.Text;
      }

      return me;
    }
  }

  