import { Component, OnInit, ViewContainerRef, ComponentFactoryResolver, ComponentRef, ViewChild, Input, EventEmitter } from '@angular/core';

import { CompHolderDirective } from '../chat-panel/comp-holder.directive';

import { ChatWindowMembersComponent } from '../chat-window-members/chat-window-members.component';
import { ChatCloseFormComponent } from '../chat-close-form/chat-close-form.component';
import { ComponentItem } from '../chat-panel/componentItem';
import { IComponentHolder } from '../chat-panel/IComponentHolder';
import { RoomsService } from '../../service/rooms.service';
import { IframeComponent } from '../chat-panel/iframe/iframe.component';

import { Howl, Howler } from 'howler';
import { Subject } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';
import { ChatService } from '../../service/chatservice';



@Component({
  selector: 'chat-page',
  templateUrl: './chat-page.component.html',
  styleUrls: ['./chat-page.component.css']
})
export class ChatPageComponent implements OnInit {
  data: any;
  lastComponentIndex: number = -1;
  menuComponents: ComponentItem[];
  private onDestroy$: Subject<void> = new Subject<void>();
  currentComponent:string="";
  loadComponentIndex:number=0;
  
  @ViewChild(CompHolderDirective,{static:true}) adHost: CompHolderDirective;

  constructor(public viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver,
     private roomService: RoomsService, private chatService:ChatService,private router: Router, private route: ActivatedRoute) {

    const subChatOrTicketWidnow = this.roomService.handleChatOrTicketWindow.subscribe(val => {
      //check loaded component
      this.loadComponent(0);
    });
    const subHandleCloseRoom = this.roomService.handleCloseRoomSubject.subscribe(val => {
      localStorage.setItem('closedRoomId', JSON.stringify(val));
      this.loadComponent(1);
    });

    const subSaveChanges=this.roomService.loadNewComponent.subscribe((load :boolean)=>
    {
      var pom=load;
      
      if(load){
        this.currentComponent="other";
        this.roomService.unblockSetCurrentRoom();
        this.chatService.unblockSetCurrentRoom();

        var prevRoom=this.roomService.getPreviousRoomSet();

        if(prevRoom.closed==0 || prevRoom.name.toLowerCase()=="lobby")
        {
          if(this.roomService.getIsAllSessionsClicked())
          {
            this.roomService.setIsAllSessionsClicked(false);
            this.roomService.preselectTab.next("allSessions");
          }

          this.loadComponent(0);
        }
        else{
          if(this.roomService.getIsAllSessionsClicked())
          {
            this.roomService.setIsAllSessionsClicked(false);
            this.roomService.preselectTab.next("allSessions");
          }

          this.loadComponent(1);
        }
      }else{
        if(this.roomService.getIsAllSessionsClicked())
        {
          this.roomService.setIsAllSessionsClicked(false);
          this.roomService.preselectTab.next("allSessions");
        }

        this.roomService.unblockSetCurrentRoom();
        this.chatService.unblockSetCurrentRoom();
        this.currentComponent="other";
        this.loadComponent(this.loadComponentIndex);
      }
      
    });


  }

  ngOnInit() {
    this.menuComponents = [

      new ComponentItem(ChatWindowMembersComponent, {}),
      new ComponentItem(ChatCloseFormComponent, {})

    ];

    this.loadComponent(0);
  }

  loadComponent(currentAdIndex: number) {


    //if (this.lastComponentIndex != currentAdIndex) {
    //  this.lastComponentIndex = currentAdIndex;
      let viewContainerRef = this.adHost.viewContainerRef;

      var len= viewContainerRef.length;

      this.loadComponentIndex=currentAdIndex;

      if(len>0 && this.currentComponent=="closedChat")
      {

        this.roomService.checkIfClosePopupNeeded.next(true);
        
      }else
      {

        if(currentAdIndex===0)
        {
          this.currentComponent="other";
        }else{
          this.currentComponent="closedChat";
        }
  
        let componentItem = this.menuComponents[currentAdIndex];
  
        let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentItem.component);
  
       
        viewContainerRef.clear();
  
        let componentRef = viewContainerRef.createComponent(componentFactory);
        (<IComponentHolder>componentRef.instance).data = componentItem.data;

      }
  }

  openChat(event: any): void {
    this.loadComponent(0);
    event.preventDefault();
  }
  openHotText(event: any): void {
    this.loadComponent(1);
    event.preventDefault();
  }

  onSubmit(event: any): void {
    this.loadComponent(1);
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  } 
}



