<div id="page-wrapper">

    <!-- Sidebar Holder -->



    <!-- Page Content Holder -->
    <div id="page-content">


        <div class="col-md-12 col-lg-12 col-sm-12 chat-window-wrapper" style="padding: 0">
            <div class="col-md-3 col-lg-3 col-sm-3 col-xs-3" style="display:table-cell;padding: 0;">
                <div class="col-md-12 col-lg-12 col-sm-12 chat-room-left">
                    <chat-rooms></chat-rooms>
                </div>
            </div>
            <div class="col-md-9 col-lg-9 col-sm-9 col-xs-9" style="display:table-cell;padding-left: 0;">
                <ng-template appCompHolder  > </ng-template>
    
                
                </div>
        </div>

        <chat-room-notifications></chat-room-notifications>
    </div>
</div>
