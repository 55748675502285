<mat-dialog-actions>
  <button class="mat-raised-button close" (click)="close()"> X </button>
</mat-dialog-actions>
<h2 mat-dialog-title>Image Uploading</h2>

<div mat-dialog-body>

  <input type="file" (change)="fileChangeEvent($event)" />

  <image-cropper [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="false" [aspectRatio]="4/3" format="jpeg"
    [onlyScaleDown]="true" [resizeToWidth]="1024" [resizeToHeight]="1024" (imageCropped)="imageCropped($event)"
    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady()" (loadImageFailed)="loadImageFailed()"></image-cropper>
  <p *ngIf="hasError" style="
    font-size: 20px;
    color: red;
    text-align: center; margin-top:15px;">{{imageError}}</p>

  <mat-dialog-actions>
    <button class="btn mat-raised-button mat-primary" [ngClass]="{ 'disabled': hasError }"
      (click)="upload()">Upload</button>
  </mat-dialog-actions>

  <div style="margin-top:40px;">
    Supported formats: .JPG .JPEG .PNG .TIFF .HEIF .HEIC
  </div>
  <div style="margin-top:15px; font-weight: bold;">
    <p>File size cannot exceed 2mb in size.</p>
  </div>
</div>