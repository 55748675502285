
import {takeUntil} from 'rxjs/operators';
import { Component, OnInit, Inject } from '@angular/core';
import { Client } from '../../model/client.model';
import { ClientFactory } from '../../model/ClientFactory';
import { User } from '../../model/user';
import { UserFactory } from '../../model/userFactory';
import { Observable, Subject,ReplaySubject } from 'rxjs';
import { UsersService } from '../../service/users.service';
import { UsersInfo } from '../../model/usersInfo';
import { ChatService } from '../../service/chatservice';
import { Room } from '../../model/room.model';
import { RoomsService } from '../../service/rooms.service';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig,MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,MatLegacyDialogRef as MatDialogRef,MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { ConfirmComponent } from '../confirm/confirm.component';
import {TranscriptModalComponent} from '../transcript-modal/transcript-modal.component';
import {AllTranscriptsModalComponent} from '../all-transcripts-modal/all-transcripts-modal.component';
import {AllTicketsModalComponent} from '../all-tickets-modal/all-tickets-modal.component';
import {TicketService} from "../../service/ticket.service";
//import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-user-info-window',
  templateUrl: './user-info-window.component.html',
  styleUrls: ['./user-info-window.component.css']
})
export class UserInfoWindowComponent {
  //private onDestroy$: Subject<void> = new Subject<void>();
  userInfo: UsersInfo;
  test1: string;
  currentRoom: any;
  hideInfo: boolean = false;
  dialogRef: MatDialogRef <any> ;
  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  constructor(public spinnerService: NgxSpinnerService, public ticketService: TicketService, public userService: UsersService,public chatService: ChatService, public roomsService: RoomsService, private dialog: MatDialog) {
  }

  ngOnInit(): void {
    const subCurrentRoom = this.roomsService.currentRoom.pipe(
    takeUntil(this.onDestroy$))
    .subscribe((room: Room) => {
      if(room.name == 'Lobby'){
        this.hideInfo = false;
      }
      this.currentRoom = room;
    });

    const subUserInfo = this.userService.clickedUserInfo.pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      this.hideInfo = true;
      this.userInfo = data;
      this.test1 = this.userInfo.user.userName;
    });
  }

  onClickGetAllTickets(event: any){
    
     this.spinnerService.show();
    const subTicketByUserPerMonth = this.ticketService.getTicketsByUserPerMonth(0, this.userInfo.user.id).pipe(takeUntil(this.onDestroy$)).subscribe(
      data => 
      {
      this.openAllTicketsDialog(data);
      },error=>
      {
        
        this.openAllTicketsDialog(error.error);
      }

    );
  }

  openAllTicketsDialog(tickets) {
    
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = true;
    
    
    dialogConfig.data = { 
      ticketsArray: tickets, 
      userId: this.userInfo.user.id 
    };

    dialogConfig.height = '600px';
    dialogConfig.width = '800px'; 
    dialogConfig.disableClose=true;
    this.spinnerService.hide();

    this.dialogRef = this.dialog.open(AllTicketsModalComponent, dialogConfig);
    const subDialogRef = this.dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(result => {
      
      this.dialogRef = null;
    });
  }

  onClickGetCurrentTranscript(event: any){
    console.log('get current transcripts called');
    this.chatService.getCurrentTranscript(this.userInfo.user.id, this.currentRoom.id);
    this.openCurrentTranscriptDialog();
  }

  openCurrentTranscriptDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(TranscriptModalComponent, {
      height: '500px',
      width: '700px'
    });
  }


  openAllTranscriptsDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(AllTranscriptsModalComponent, {
      height: '500px', 
      width: '700px'
    });
  }

  openPopUp(event: any){
    window.open("https://chat.globalmentoring.com/GMSAdmin/login.aspx?mentorapp=1&page=msppopup&clientid=" + this.userInfo.clientId.toString() + "&master_call_id=35244324", "_blank");
  }

  showUserOnInit(){ 
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  } 
}
