<br>

<form *ngIf="voucher" class="example-form">

   <mat-form-field class="example-full-width" *ngIf="voucher.voucherId != '-1'">
    <input style="font-weight: bold;" matInput disabled placeholder="UUID" value="{{voucher.voucherId}}">
   </mat-form-field> 

   <mat-form-field class="example-full-width" *ngIf="voucher.voucherId != '-1'">
    <input style="font-weight: bold;" matInput disabled placeholder="Client" value="{{selectedClient}}">
  </mat-form-field> 

  <mat-form-field *ngIf="voucher.voucherId == '-1'">
        <mat-select placeholder="Partner" title="Select the client you want to assign the vouchers">
          <mat-option (onSelectionChange)="onPartnerChange($event)" *ngFor="let partner of partners" [value]="partner.value">
            {{ partner.viewValue }}
          </mat-option>
        </mat-select>
  </mat-form-field>

  <br />

  <mat-form-field *ngIf="isPartnerSelected">
        <mat-select placeholder="Child Client" disableRipple>
          <mat-option (onSelectionChange)="onClientChange($event)" *ngFor="let client of childClients" [value]="client.value">
            {{client.viewValue}}
          </mat-option>
        </mat-select>
  </mat-form-field>

  <mat-form-field class="example-full-width" *ngIf="voucher.voucherId == '-1'">
  <input type="text" (keypress)="numbersOnly($event)" matInput placeholder="Number of vouchers" [(ngModel)]="noOfVouchers" name="noVouchers" title="Specify the number of vouchers that will be generated">
  </mat-form-field>

  <mat-form-field class="example-full-width">
  <input type="text" [disabled]="disableInputs" (keypress)="numbersOnly($event)" matInput placeholder="Valid for" name="validFor" [(ngModel)]="voucher.validFor" title="Specify the number of days the voucher will be valid after the first use">
  </mat-form-field>

  <mat-form-field *ngIf="voucher.voucherId == '-1'">
            <input matInput disabled [matDatepicker]="picker" name="expiryDate" [(ngModel)]="voucher.expiryDate" placeholder="Voucher expiration date" title="Specify the latest possible date when the voucher can be redeemed">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker disabled="false" #picker></mat-datepicker>
  </mat-form-field>

  <mat-form-field *ngIf="voucher.voucherId != '-1'">
    <input matInput disabled [matDatepicker]="picker" name="expiryDate" [value]="date.value" placeholder="Voucher expiration date" title="Specify the latest possible date when the voucher can be redeemed">
    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
    <mat-datepicker disabled #picker></mat-datepicker>
</mat-form-field>

  <br />

  <mat-form-field>
            <mat-select (ngModelChange)="onVoucherTypeChange($event)" [disabled]="disableInputs" placeholder="Voucher Type" name="voucherType" [(value)]="voucher.type" [(ngModel)]="voucher.type" disableRipple title="Specify the voucher type">
              <mat-option [value]="0">Dashboard</mat-option>
              <mat-option [value]="1">Bundle</mat-option>
              <mat-option [value]="2">Room</mat-option>
              <mat-option [value]="3">Exam</mat-option>
            </mat-select>
  </mat-form-field>

  <br />

  <mat-form-field *ngIf="displayCourses">
        <mat-select (ngModelChange)="onCoursesChange($event)" [disabled]="disableInputs" placeholder="Courses" [formControl]="courses" multiple disableRipple title="Specify the courses you want to associate the voucher(s)">
          <mat-option *ngFor="let course of coursesList" [value]="course.clientCourseId">{{course.name}} - {{course.code}}</mat-option>
        </mat-select>
  </mat-form-field>

  <br />

  <div *ngIf="voucher.courses.length > 0" style="opacity: 0.5">
    Courses: <span>{{voucher.courses}}</span>
  </div>

  <mat-slide-toggle *ngIf="voucher.voucherId == '-1'" name="isTest" [(ngModel)]="voucher.isTest" title="Select if the voucher is for internal use"
                    class="example-margin"
                    [checked]="checked">
                    Test Voucher
  </mat-slide-toggle>

   <br />  <br />

  <div class="button-row">
        <button *ngIf="!disableInputs" mat-stroked-button (click)="onSaveClick()">Save</button>
        <button mat-stroked-button color="warn" (click)="onCloseClick()">Close</button>
  </div>

</form>
