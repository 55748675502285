import {
  Component,
  OnInit,
  Input
} from '@angular/core';
import { Observable, Subject } from 'rxjs';




import { UsersService } from '../../service/users.service';

import { User } from '../../model/user';
import { Message } from '../../model/message.model';



@Component({
  selector: 'chat-message-transcript',
  templateUrl: './chat-message-transcript.component.html',
  styleUrls: ['./chat-message-transcript.component.css']
})
export class ChatMessageTranscriptComponent implements OnInit {
  @Input() message: Message;
  currentUser: User;
  incoming: boolean;
  private onDestroy$: Subject<void> = new Subject<void>();

  constructor(public UsersService: UsersService) {
  }

  ngOnInit(): void {
    const subCurrentUser = this.UsersService.currentUser
      .subscribe(
        (user: User) => {
          this.currentUser = user;
          if (this.message.author && user) {

            this.incoming = this.message.author.id !== user.id;
          }
        });
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }


}
