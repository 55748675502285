
import { of as observableOf, forkJoin as observableForkJoin, Observable, Subject, BehaviorSubject, Observer, ReplaySubject, SubscriptionLike as ISubscription, of } from 'rxjs';

import { catchError, debounceTime, map, takeUntil } from 'rxjs/operators';
import { Component, OnInit, HostListener, Input, OnDestroy, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';




import { AlertService } from '../../service/alert.service';
import { Ticket } from '../../model/ticket';
import { TicketService } from '../../service/ticket.service';
import { ChatService } from '../../service/chatservice';
import { MappingFields } from '../../model/MappingFields.model';
import { Contact } from '../../model/contact.model';
import { IssueService } from '../../service/issueservice';
import { UntypedFormGroup, UntypedFormBuilder, FormControl, ReactiveFormsModule, Validators, NgForm, FormArray, FormGroup, FormBuilder, UntypedFormControl, UntypedFormArray } from '@angular/forms';
import { Status } from '../../model/status.model';
import { StatusType } from '../../model/statusType';
import { Queue } from '../../model/queue.model';
import { AtAccount } from '../../model/ataccount.model';
import { MessagesService } from '../../service/messages.service';
import { Message } from '../../model/message.model';
import { MessageType } from '../../model/messageType';

import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { ContactModalComponent } from '../contact-modal/contact-modal.component';
import { NoticeComponent } from '../notice/notice.component';
import { Room } from '../../model/room.model';
import { RoomsService } from '../../service/rooms.service';
//import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { NgxSpinnerService } from 'ngx-spinner';
import * as _ from 'lodash';
import { MatIconModule } from '@angular/material/icon'
import { LongDateFormatKey } from 'moment';
import { ComponentCanDeactivate } from '../../auth/guards/pendingchanges.guard';
import { ConfirmComponent } from '../confirm/confirm.component';
import { TicketDraft } from '../../model/ticketDraft';
import { tick } from '@angular/core/testing';
import { Priority } from '../../model/priority.model';
import { NumberInput } from '@angular/cdk/coercion';
import { MappingFieldArray } from '../../model/MappingFieldArray.model';
import { createTicket } from '../../model/createTicket.model';
import { TicketData } from '../../model/ticketData';
import { TicketCreatedStatusEnum } from '../../model/ticketCreatedStatusEnum';
import { ConfirmInfoComponent } from '../confirm-info/confirm-info.component';

@Component({
  selector: 'chat-close-form',
  templateUrl: './chat-close-form.component.html',
  styleUrls: ['./chat-close-form.component.css'],
})
export class ChatCloseFormComponent implements OnInit, OnDestroy {
  //private onDestroy$: Subject<void> = new Subject<void>();

  setContactSubject: Subject<any> = new Subject<any>();
  [x: string]: any;
  messagesInCurrentRoom: Observable<Message[]>;

  currentDate: Date;
  popupOpenedAt: string;
  showUpdateMessage: boolean = false;
  data: any;
  showFormErrors: boolean = false;
  isValidFormSubmitted = null;
  currentTicket: TicketData;
  currentRoom: Room;
  previousRoom: Room;
  loading = false;
  showSelected: boolean = false;
  showIfTTI: boolean = false;
  public clientId: number;
  public clientType: string;
  errorMessage: string;
  exist: boolean;
  public companies: Contact[];
  public issues: MappingFields[];
  public subissues: MappingFields[];
  public subissuesList: MappingFieldArray[] = [];
  public subIssueVisibleList: boolean[] = [];
  public roomMsgs: Message[];

  selectedIssueInitial: number = -1;
  selectedStatusInitial: number = -1;
  selectedContactInitial: number = -1;
  selectedQueueInitial: number = -1;
  selectedSubIssueInitial: number = -1;
  selectedChildClientInitial: number = -1;

  selectedIssue: number = -1;
  selectedStatus: number = -1;
  selectedContact: number = -1;
  selectedQueue: number = -1;
  selectedSubIssue: number = -1;
  selectedCompany: number;
  selectedChildClient: number = -1;
  selectedPriority: number = 2; // Medium
  public newContact: Contact;
  public contacts: Contact[];
  public statuses: Object[];
  public childClients: AtAccount[];
  public queues: Queue[];
  public priorities: Priority[];
  internalNote: string;
  privateNote: string;
  ticketTitle: string = "";
  internalNoteInitial: string;
  privateNoteInitial: string;
  ticketTitleInitial: string = "";
  disableUpdate: boolean = false;
  isTranscriptLoaded: boolean = false;
  userInChat: string;
  public loadingContacts: boolean = false;
  public loadingChildClients: boolean = false;
  public ticketNumberPresent: string = "Loading...";
  public ticketNumberArray: Array<string> = new Array<string>();
  public noticeMessage: string = "";
  public showSuccessMessage: boolean = false;
  public noticeImage: string = "";
  private TTI_CLIENT_ID: string = "30734187";
  private TTI_CLIENT_ID_STAGING: string = "30757764";
  private TTI_CLIENT_INC_ID: string = "30742108";
  myForm: FormGroup;
  chatUserId: string;
  chatUserFullName: string;
  addContactsNote: string;
  parentAtAccountId: number;
  isMspAuth: boolean = false;
  private initialTicketTitle = "";
  isSaveChangesNeeded: boolean = true;
  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  public isNhdTicket: boolean = false;
  public selectedTemplate: number = 0;
  popupOpenedAtInitial: string = "";
  items: FormArray;
  isPreviousTicketBinded: boolean = false;
  isTicketCreated: boolean = false;
  disablePreviousTicketUpdate: boolean = false;
  showCloseChatButton:boolean=true;
  isNhdClosed: boolean = false;

  constructor(private router: Router,
    public chatService: ChatService,
    public ticketService: TicketService,
    public messagesService: MessagesService,
    private roomsService: RoomsService,
    private spinnerService: NgxSpinnerService,
    private datePipe: DatePipe,
    private dialog: MatDialog,
    private issueservice: IssueService, private formBuilder: FormBuilder) {

    this.loadingContacts = true;
    this.loadingChildClients = true;
    this.initializeComponents();



    this.isTranscriptLoaded = false;
    const subscriptionRoom = this.roomsService.currentRoom.pipe(takeUntil(this.onDestroy$)).subscribe(room => {
      this.currentRoom = room;
    });


    


    this.showSuccessMessage = false;
    //EST
    var offset = -5.0;
    var clientDate = new Date();
    var utc = clientDate.getTime() + (clientDate.getTimezoneOffset() * 60000);
    var utcDate = new Date(utc);
    this.popupOpenedAt = this.datePipe.transform(utcDate, 'yyyy-MM-dd hh:mm:ss a');


    const subContactSubject = this.setContactSubject.pipe(takeUntil(this.onDestroy$)).subscribe((email: string) => {

      let tmpCont = this.contacts.find(c => c.email === email);

      if (tmpCont !== null && tmpCont !== undefined)
        this.selectedContact = tmpCont.id;
      else
        this.selectedContact = -1;

    });

    this.chatService.getTicketInfo(this.currentRoom.id);

    this.previousRoom = this.currentRoom;

    // this.messagesInCurrentRoomres.subscribe(msgs => {
    //     return this.msgsInCurrentRoom(msgs);
    //   });



    const subscriptionTicket = this.ticketService.currentTicket.pipe(takeUntil(this.onDestroy$)).subscribe(
      (ticket: TicketData) => {

        if (ticket) {
          this.currentTicket = ticket;

          this.ticketNumberPresent = this.currentTicket.TicketNumber;


          if (this.currentTicket.ID == '-1') {

            if (this.currentRoom.ticketNumber != '-1') {
              this.ticketNumberPresent = this.currentRoom.ticketNumber.toString();
              this.disableUpdate = true;
            }
            else {
              this.isMspAuth = true;
              this.ticketNumberPresent = "Autotask ticket does not exists for this chat session.";
            }
          }
        }
      });


      const subscriptionTicketNumbers = this.ticketService.sTicketNumbers.pipe(takeUntil(this.onDestroy$)).subscribe(
        (ticketArray: Array<TicketData>) => {
          this.ticketNumberArray=[];
          if (ticketArray) 
          {
            ticketArray.forEach(element => {
                  this.ticketNumberArray.push(element.TicketNumber.toString());
              });
            }
        });



    observableForkJoin(
      [this.issueservice.GetAllIssues().pipe(map((res) => { this.issues = res; }), catchError(e => observableOf('GetAllIssues error!')),),
      this.issueservice.GetAllStatuses().pipe(map((res) => { this.statuses = res; }), catchError(e => observableOf('GetAllStatuses error!')),),
      this.issueservice.GetQueues().pipe(map((res) => { this.queues = res; }), catchError(e => observableOf('Oops!')),),
      this.issueservice.GetPriorities().pipe(map((res) => { this.priorities = res; }), catchError(e => observableOf('GetPriorities error!')),),
      this.issueservice.GetTranscript(this.currentRoom).pipe(map(
        (res) => {
          this.msgsInCurrentRoom(res);
        }
      ), catchError(e =>
        observableOf('Oops!')),
      )]
    ).subscribe(res => {
      this.roomsService.setBlockSettingCurrentRoom(true);
      this.chatService.setBlockSettingCurrentRoom(true);

      observableForkJoin(
        [this.issueservice.GetChildClientsByUserId(this.chatUserId).pipe(map((res) => { return this.getChildClients(res) }), catchError(e => observableOf('Oops!')),),
        this.issueservice.GetContactsByUserId(this.chatUserId).pipe(map((res) => { return this.getContactsSubsription(res) }), catchError(e => observableOf('Oops!')),)]
      ).subscribe(res => {
        this.ticketService.GetTicketDraft(this.currentRoom.id).pipe(takeUntil(this.onDestroy$)).subscribe((data: TicketDraft) => {

          if (data) {
            this.ticketTitle = data.Title;
            this.selectedStatus = data.Status;
            this.selectedQueue = data.Queue;
            this.selectedContact = data.Contact;
            this.selectedIssue = data.Issue;

            if (this.selectedIssue != -1 && this.selectedIssue != 0)
              this.onIssueSelect(this.selectedIssue, 0);

            this.selectedSubIssue = data.SubIssue;
            this.internalNote = data.InternalNote;
            this.privateNote = data.PrivateNote;
            this.popupOpenedAt = data.PopupOpenedAt;
            this.selectedContact = data.Contact;
            this.selectedChildClient = data.SelectedChild;
            this.ticketTitleInitial = data.Title;
            this.selectedStatusInitial = data.Status;
            this.selectedQueueInitial = data.Queue;
            this.selectedContactInitial = data.Contact;
            this.selectedIssueInitial = data.Issue;
            this.selectedSubIssueInitial = data.SubIssue;
            this.internalNoteInitial = data.InternalNote;
            this.privateNoteInitial = data.PrivateNote;
            this.popupOpenedAtInitial = data.PopupOpenedAt;
            this.selectedContactInitial = data.Contact;
            this.selectedChildClientInitial = data.SelectedChild;
            this.selectedTemplate = data.SelectedTemplate;
            this.isNhdTicket = this.selectedTemplate == 2;
            this.selectedChildClientInitial = data.SelectedChildClientInitial;
            this.onChildClientSelect(this.selectedChildClient);
          }

        });
      });
    }
    );

    // const subscriptionQueues = this.issueservice.GetQueues().subscribe(data => {
    //   this.queues = data
    // },error => console.log(error),
    //   () => { console.log('Get all queues complete') });

    const subscriptionTicketCreated = this.chatService.TicketCreated.pipe(takeUntil(this.onDestroy$)).subscribe((ticketCreatedStatus: number) => {
      this.spinnerService.hide();
      this.roomsService.setBlockSettingCurrentRoom(false);
      this.chatService.setBlockSettingCurrentRoom(false);
      this.isSaveChangesNeeded = false;
      if (ticketCreatedStatus == TicketCreatedStatusEnum.TicketUpdated) {
        this.showSuccessMessage = true;
        this.isTicketCreated = false;
        this.noticeMessage = "Chat ticket " + this.currentTicket.TicketNumber + " was successfully updated!";
        this.noticeImage = 'assets/images/thumbs-up.png';
        this.chatService.closeChatPopup(this.currentRoom.id);
      } else if (ticketCreatedStatus == TicketCreatedStatusEnum.TicketCreated) {
        this.showSuccessMessage = true;
        this.isTicketCreated = true;
        this.noticeMessage = "Chat ticket " + this.currentTicket.TicketNumber + " was successfully updated!";
        this.noticeImage = 'assets/images/thumbs-up.png';
        this.chatService.closeChatPopup(this.currentRoom.id);
      } else {
        this.noticeMessage = "Chat ticket " + this.currentTicket.TicketNumber + " failed to be updated ";
        this.noticeImage = 'assets/images/thumbs-down.png';
      }
    });

    const subscriptionSaveChanges = this.roomsService.checkIfClosePopupNeeded.pipe(takeUntil(this.onDestroy$)).subscribe((start: boolean) => {

      var formChange = false;

      if (this.ticketTitle !== this.ticketTitleInitial)
        formChange = true;

      if (this.internalNote !== this.internalNoteInitial)
        formChange = true;

      if (this.privateNote !== this.privateNoteInitial)
        formChange = true;

      if (this.selectedContact !== this.selectedContactInitial)
        formChange = true;

      if (this.selectedQueue !== this.selectedQueueInitial)
        formChange = true;

      if (this.selectedIssue !== this.selectedIssueInitial)
        formChange = true;

      if (this.selectedSubIssue !== this.selectedSubIssueInitial)
        formChange = true;

      if (this.isSaveChangesNeeded && formChange) {
        this.roomsService.setBlockSettingCurrentRoom(true);
        this.chatService.setBlockSettingCurrentRoom(true);
        this.openConfirmDialog();
      } else {
        this.roomsService.setBlockSettingCurrentRoom(false);
        this.chatService.setBlockSettingCurrentRoom(false);
        this.roomsService.loadNewComponent.next(false);
      }

    });


  }

  msgsInCurrentRoom(msgs: Message[]) {

    var msg = msgs.find(m => m.author.isMentor === false)
    this.userInChat = msg.author.email;
    this.chatUserId = msg.author.id;
    this.chatUserFullName = msg.author.userName;
    this.ticketTitle = "Chat - " + this.makeFirstLetterUppercase(msg.author.userName) + " - ";
    this.initialTicketTitle = "Chat - " + this.makeFirstLetterUppercase(msg.author.userName) + " - ";
    this.ticketTitleInitial = this.ticketTitle;
    var transcriptMsgs = msgs.filter(x => x.msgType == 0);
    this.messagesInCurrentRoom = _.cloneDeep(_.sortBy(transcriptMsgs, (t: any) => t.sentAt));
  }

  getChildClients(data: any) {
    this.loadingChildClients = false;
    this.childClients = data;
    this.parentAtAccountId = this.childClients[0].parentId;

    let selected = data.filter((client) => client.selected === true);

    if (selected && selected.length > 0) {
      this.selectedChildClient = selected[0].id;
      this.selectedChildClientInitial = this.selectedChildClient;
    }

    const subscriptionContactsStatus = this.issueservice.CheckIfMspAllowsAddingContacts(this.parentAtAccountId).pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      if (data && data.toString().toLowerCase() == "add") {
        this.addContactsNote = "(Partner allows adding new contacts)";
      } else if (data && data.toString().toLowerCase() == "do not add") {
        this.addContactsNote = "(Partner doesn't allow adding new contacts)";
      }
    },
      error => console.log(error),
      () => { });
  }

  getContactsSubsription(data: any) {

    this.loadingContacts = false;
    this.contacts = data;

    if (this.userInChat != null && this.userInChat != "") {
      let tmpCont = this.contacts.find(c => c.email.toLowerCase() === this.userInChat.toLowerCase());
      this.selectedContact = -1;
      if (tmpCont !== null && tmpCont !== undefined) {
        this.selectedContact = tmpCont.id;
        this.selectedContactInitial = this.selectedContact;
      }
    }
  }

  getIssues(fields: MappingFields[]) {

  }

  openConfirmDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(ConfirmComponent, {
      height: '250px',
      width: '400px',
      data: { warningMessage: "Save changes before leaving this chat session?", showCancelButton: true }
    });

    const subDialogRef = dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(
      val => {
        if (val !== null && val != undefined && val) {
          this.saveDraftChanges();
        } else if (val !== null && val != undefined && val == false) {
          this.roomsService.loadNewComponent.next(true);
        } else {
          this.roomsService.setCurrentRoom(this.previousRoom);
        }
      }
    );
    dialogRef.disableClose = false;
  }

  openConfirmInfoDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    const dialogRef = this.dialog.open(ConfirmInfoComponent, {
      height: '250px',
      width: '400px',
      data: { warningMessage: "Cannot merge existing ticket into this one! Please proceed by creating a new Autotask ticket." }
    });

    const subDialogRef = dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(
      val => {
      }
    );
    dialogRef.disableClose = false;
  }

  createItem(): UntypedFormGroup {
    return this.formBuilder.group({
      additionalTicketTitle: new UntypedFormControl('', [Validators.required]),
      additionalIssue: '',
      additionalSubIssue: '',
      ticketMentorPrivateNote: '',
      ticketMentorTimeEntry: new UntypedFormControl('', [Validators.required])
    });
  }

  addItem(): void {
    this.items = this.myForm.get('items') as UntypedFormArray;

    if (this.items.length == 10)
      return;

    this.items.push(this.createItem());
    this.subissuesList.push(new MappingFieldArray);
    this.subIssueVisibleList.push(false);
  }

  onCloseAdditionalTicket(item: number) {
    this.items = this.myForm.get('items') as UntypedFormArray;
    this.items.removeAt(item);
    this.subissuesList.splice(item, 1);
    this.subIssueVisibleList.splice(item, 1);
  }

  ngOnInit() {

    // this.subissuesList.push(new MappingFieldArray);
    // this.subIssueVisibleList.push(false);
    this.myForm = this.formBuilder.group({

      items: this.formBuilder.array([])
    });


    this.ticketNumberPresent = "Loading...";
    this.noticeMessage = "";

    //setTimeout(function(){
    //  debugger;
    //  this.noticeMessage='abvbvbv';
    //  this.showSuccessMessage=true;
    //},3000)



    // this.showSuccessMessage=true;

    // this.noticeMessage = "Chat ticket " + this.currentTicket.TicketNumber + " was successfully updated!";
    // this.noticeImage = 'assets/images/thumbs-up.png';    


    // this.spinnerService.show();

  }

  initializeComponents() {
    this.ticketService.setCurrentTicket(null);
    this.contacts = null;
    this.childClients = null;
    this.popupOpenedAt = "";
    this.userInChat = "";
    this.ticketTitle = "";
    this.internalNote = "";
    this.privateNote = "";

    this.ticketTitleInitial = "";
    this.internalNoteInitial = "";
    this.privateNoteInitial = "";

  }

  loadPreviousTicket() {

    this.spinnerService.show();
    this.ticketService.checkPreviousTicketQueue(this.currentRoom.id).pipe(takeUntil(this.onDestroy$)).subscribe(
      data => {
        this.spinnerService.hide();

        this.isPreviousTicketBinded = true;

        this.ticketTitle = this.currentTicket.Title;
        this.selectedStatus = this.currentTicket.Status;
        this.selectedPriority = this.currentTicket.Priority;

        if (this.currentTicket.IssueType) {
          this.selectedIssue = this.currentTicket.IssueType;

          var selectedSubIss = 0
          if (this.currentTicket.SubIssueType)
            selectedSubIss = this.currentTicket.SubIssueType

          this.onIssueSelect(this.selectedIssue, selectedSubIss);
        }

        if (this.currentTicket.SubIssueType) {
          this.selectedSubIssue = this.currentTicket.SubIssueType;
          this.showSelected = true;
        }
      },
      error => {
        this.spinnerService.hide();
        this.disablePreviousTicketUpdate = true;
        this.openConfirmInfoDialog();
      });
  }

  revertToNewTicket() {
    this.spinnerService.show();
    this.isPreviousTicketBinded = false;
    this.selectedStatus = this.selectedStatusInitial;
    this.selectedPriority = 2;
    this.ticketTitle = this.initialTicketTitle;
    this.selectedIssue = this.selectedIssueInitial;
    this.selectedSubIssue = this.selectedSubIssueInitial;
    this.showSelected = false;
    this.spinnerService.hide();
  }

  closeNhdChat() {
    this.openConfirmationDialog();
  }

  openConfirmationDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(ConfirmComponent, {
      height: '300px',
      width: '400px',
      data: { warningMessage: "Are you sure you want to delete this chat?" }
    });

    const subDialogRef = dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(
      result => {
        if (result) {
          this.spinnerService.show();
        this.issueservice.CloseNhdChat(this.currentRoom.id).pipe(takeUntil(this.onDestroy$))
          .subscribe(
            data => {
              this.spinnerService.hide();
              this.roomsService.setBlockSettingCurrentRoom(false);
              this.chatService.setBlockSettingCurrentRoom(false);
              this.isSaveChangesNeeded = false;
              
                this.showSuccessMessage = true;
                this.isNhdClosed=true;
                //this.isTicketCreated = true;
                this.noticeMessage = "NHD ticket was successfully closed!";
                this.noticeImage = 'assets/images/thumbs-up.png';
                this.chatService.closeChatPopup(this.currentRoom.id);
              
            },
            error => {
              this.spinnerService.hide();

            });
        }
      }
    );
  }

  getContacts() {
    const subGetContactsForRoom = this.issueservice.GetContactsByUserId(this.chatUserId).pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      this.loadingContacts = false;
      this.contacts = data;

      if (this.userInChat != null && this.userInChat != "") {
        let tmpCont = this.contacts.find(c => c.email.toLowerCase() === this.userInChat.toLowerCase());
        this.selectedContact = -1;
        if (tmpCont !== null && tmpCont !== undefined) {
          this.selectedContact = tmpCont.id;
          this.selectedContactInitial = this.selectedContact;
        }
      }
    });
  }

  openTicket(event: any): void {
    window.open("https://ww3.autotask.net/Mvc/ServiceDesk/TicketDetail.mvc?openerPage=null&ticketId=" +
      this.currentTicket.ID,
      "_blank");
  }

  onSubIssueSelect(value: any) {
    if (value == 0) {
      this.selectedSubIssue = -1;
      return;
    }
    this.selectedSubIssue = value;
  }

  onCompanySelect(value: any) {
    if (value == 0) {
      this.selectedCompany = -1;
      return;
    }
    this.selectedCompany = value;
  }

  onQueueSelect(value: any) {
    if (value == 0) {
      this.selectedQueue = -1;
      return;
    }
    this.selectedQueue = value;
  }

  onStatusSelect(value: any) {
    if (value == 0)
      this.selectedStatus = -1;
    else
      this.selectedStatus = value;
  }

  onPrioritySelect(value: any) {
    this.selectedPriority = value;
  }

  onIssueSelect(value: any, selected: number) {

    if (value == 0) {
      this.selectedIssue = -1;
      this.selectedSubIssue = 0;

      this.selectedIssueInitial = -1;
      this.selectedSubIssueInitial = 0;
      this.showSelected = false;
    }
    else {
      this.selectedIssue = value;
      const subGetAllSubissues = this.issueservice.GetAllSubissues(this.selectedIssue).pipe(takeUntil(this.onDestroy$)).subscribe(data => {

        if (data.length == 0) {
          this.showSelected = false;
          this.selectedSubIssue = -1;
          return;
        }

        this.subissues = data;

        if (selected == 0)
          this.selectedSubIssue = this.subissues[0].fieldValue;

        this.showSelected = true;
      },
        error => {
          console.log(error);
          this.showSelected = false;
          this.selectedSubIssue = -1;
          this.subissues = null;
        },
        () => { console.log('Get all issues complete') });
    }
  }

  increaseByOne(value: number) {
    return value + 2;
  }

  onAdditionalIssueSelect(value: any, index: number) {

    if (value == 0) {

      this.subIssueVisibleList[index] = true;
    }
    else {

      const subGetAllSubissues = this.issueservice.GetAllSubissues(value).pipe(takeUntil(this.onDestroy$)).subscribe(data => {

        if (data.length == 0) {
          this.showSelected = false;
          this.selectedSubIssue = -1;
          return;
        }

        this.subIssueVisibleList[index] = true;
        this.subissuesList[index].mappingFields = data;

      },
        error => {
          console.log(error);
          this.showSelected = false;
          this.selectedSubIssue = -1;
          this.subissues = null;
        },
        () => { console.log('Get all issues complete') });
    }
  }

  onContactSelect(value: any) {
    if (value == 0) {
      this.showSelected = false;
      this.selectedContact = -1;
      return;
    }

    if (value == 1) {
      this.selectedContact = -1;
      this.selectedContactInitial = -1;
      this.openDialog();

    } else
      this.selectedContact = value;
  }

  onChildClientSelect(value: any) {
    if (value == 0) {
      this.selectedChildClient = -1;
      this.selectedChildClientInitial = -1;
      return;
    }
    this.selectedChildClient = value;
    this.loadingContacts = true;

    this.issueservice.GetAllContactsByClientId(this.selectedChildClient).pipe(takeUntil(this.onDestroy$)).subscribe(data => {
      this.loadingContacts = false;
      this.contacts = data;

      if (this.userInChat != null && this.userInChat != "") {
        let tmpCont = this.contacts.find(c => c.email.toLowerCase() === this.userInChat.toLowerCase());
        this.selectedContact = -1;
        if (tmpCont !== null && tmpCont !== undefined) {
          this.selectedContact = tmpCont.id;
          this.selectedContactInitial = this.selectedContact;
        }
        else if (this.isNhdTicket) {
          let selectedContact = this.contacts.filter((contact) => contact.name.toLowerCase().startsWith("escalation") === true);
          if (selectedContact && selectedContact.length > 0)
            this.selectedContact = selectedContact[0].id;
        }
      }
    });
  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.height = "400px";
    dialogConfig.width = "500px";
    let tmpCont = this.contacts.find(c => c.email.toLowerCase() === this.userInChat.toLowerCase());

    if (tmpCont == null || tmpCont == undefined) {
      let name = this.chatUserFullName.split(" ", 2);
      dialogConfig.data = {
        FirstName: name[0],
        LastName: name[1],
        Email: this.userInChat
      };
    }


    if (this.isMspAuth && this.selectedChildClient === -1) {
      this.isValidFormSubmitted = false;
      return;
    }

    let dataSend = {};
    const dialogRef = this.dialog.open(ContactModalComponent, dialogConfig);
    const subDialogRef = dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(data => {

      if (data != false) {
        let contact = new Contact({
          id: this.contacts.length + 1,
          name: data.firstname + " " + data.lastname,
          isActive: true
        });
        this.contacts.push(contact);
        this.selectedContact = contact.id;

        let accountID = "";

        if (this.isMspAuth)
          accountID = this.selectedChildClient.toString()
        else
          accountID = this.currentTicket.AccountID;
        this.spinnerService.show();
        this.issueservice.AddNewContact(accountID,
          data.firstname,
          data.lastname,
          data.email).pipe(takeUntil(this.onDestroy$))
          .subscribe(
            data => {
              this.spinnerService.hide();
              console.log(data);
              let index = this.contacts.indexOf(contact);
              this.contacts[index].id = data;
              this.selectedContact = data;
            },
            error => {
              this.spinnerService.hide();

            });
      }
      else if (this.contacts.length > 0)
        this.selectedContact = this.contacts[0].id;
      else
        this.selectedContact = -1;

    });
  }

  openNoticeDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(NoticeComponent,
      {
        height: '250px',
        width: '400px',
        data: { noticeMessage: "Chat ticket " + this.currentTicket.TicketNumber + " was successfully updated!" }
      });
  }

  onTemplateSelect(value: any) {

    this.selectedTemplate = value;
    this.ticketTitle = '';
    this.internalNote = '';
    this.selectedStatus = 0;
    this.selectedQueue = -1;

    this.ticketTitleInitial = '';
    this.internalNoteInitial = '';
    this.selectedStatusInitial = 0;
    this.selectedQueueInitial = -1;

    if (value != 2 && this.isNhdTicket == true) {
      this.isNhdTicket = false;
      this.selectedChildClient = this.selectedChildClientInitial;
      this.onChildClientSelect(this.selectedChildClient);
    }


    if (value == 1) {
      this.selectedStatus = StatusType.InProgress;
      this.internalNote = "";
      this.ticketTitle = this.initialTicketTitle;
      this.internalNote = 'Best callback number:\r\n' +
      '\r\n' +
      'Computer Name:'+
        '\r\n' +
        'Work Performed:\r\n' +
        '\r\n' +
        'Outcome/Next Steps:\r\n';

    } else if (value == 2) {
      this.isNhdTicket = true;
      this.ticketTitle = "Site Name: ";
      this.selectedStatus = StatusType.InProgress;
      this.internalNote = 'This client or contact has not signed up for helpdesk services.\r\nSite name: \r\nContact name: \r\nContact phone number: \r\nDescription:';
      this.selectedQueue = 29682833;

      let selectedChildClient = this.childClients.filter((client) => client.name.toLowerCase().startsWith("nhd calls") === true);
      if (selectedChildClient && selectedChildClient.length > 0) {
        this.selectedChildClient = selectedChildClient[0].id;
        this.onChildClientSelect(this.selectedChildClient);
      }

    } else if (value == 3) {
      this.selectedStatus = StatusType.InProgress;
      this.internalNote = 'Email address password:\r\nComputer username password:';
    } else if (value == 4) {
      this.selectedStatus = StatusType.AfterHoursWork;
      this.internalNote = 'Email address password:\r\nComputer username password:';
    } else if (value == 5) {
      this.selectedStatus = StatusType.PendingEscalationApproval;
      this.internalNote = 'Did you call next level support? Yes, No, NA\r\n' +
        'If you spoke to next level support, who did you speak to?\r\n' +
        'If known, what are the next steps required to resolve this issue?';
    } else if (value == 6) {
      this.internalNote = 'End user is not responding to chat messages.';
      this.selectedStatus = StatusType.Complete;
      this.selectedQueue = 29682833;
      this.ticketTitle = this.initialTicketTitle + "End user not responding";
    } else if (value == 7) {
      this.internalNote = 'End user was testing chat service.';
      this.selectedStatus = StatusType.Complete;
      this.selectedQueue = 29682833;
      this.ticketTitle = this.initialTicketTitle + "Testing";
    }

  }

  saveDraftChanges(): void {

    this.spinnerService.show();

    var ticketDraft = new TicketDraft();

    ticketDraft.ID = this.currentRoom.id;
    ticketDraft.TicketNumber = this.currentTicket.TicketNumber;
    ticketDraft.Title = this.ticketTitle;
    ticketDraft.Status = this.selectedStatus;
    ticketDraft.Queue = this.selectedQueue;
    ticketDraft.Contact = this.selectedContact;
    ticketDraft.Issue = this.selectedIssue;
    ticketDraft.SubIssue = this.selectedSubIssue;
    ticketDraft.InternalNote = this.internalNote;
    ticketDraft.PrivateNote = this.privateNote;
    ticketDraft.PopupOpenedAt = this.popupOpenedAt;
    ticketDraft.SelectedChild = this.selectedChildClient;
    ticketDraft.SelectedTemplate = this.selectedTemplate;
    ticketDraft.SelectedChildClientInitial = this.selectedChildClientInitial;
    ticketDraft.Priority = this.selectedPriority;

    this.ticketService.savePendingChanges(ticketDraft, this.currentRoom.id).pipe(takeUntil(this.onDestroy$)).subscribe((data) => {
      this.spinnerService.hide();
      this.roomsService.loadNewComponent.next(true);
    },
      error => {
        this.spinnerService.hide();
      });
  }


  updateTicket(model: any): void {

    this.isValidFormSubmitted = true;
    var activeButton = document.activeElement.id;

    if (this.ticketTitle === '' || this.internalNote === '') {
      this.isValidFormSubmitted = false;
    }

    if (this.isMspAuth && this.selectedChildClient === -1) {
      this.isValidFormSubmitted = false;
    }

    if (!this.isMspAuth && this.selectedContact === -1) {
      this.isValidFormSubmitted = false;
    }

    if(!this.myForm.valid)
    {
      this.isValidFormSubmitted = false;
    }

    if(this.isValidFormSubmitted == false)
    {
      for (const key of Object.keys(this.myForm.controls)) {
            if (this.myForm.controls[key].invalid) {
              const invalidControl = this.el.nativeElement.querySelector('[formcontrolname="' + key + '"]');
              invalidControl.focus();
              break;
          }
      }
      return;
    }
      
    // return;

    this.isValidFormSubmitted = true;

    var ticketDataList: createTicket[] = [];

    var ticketData: createTicket = new createTicket();
    ticketData.ticketNumber = this.currentTicket.TicketNumber;
    ticketData.selectedStatus = this.selectedStatus;
    ticketData.selectedQueue = this.selectedQueue;
    ticketData.selectedContact = this.selectedContact;
    ticketData.selectedIssue = this.selectedIssue;
    ticketData.selectedSubIssue = this.selectedSubIssue;
    ticketData.internalNote = this.internalNote;
    ticketData.privateNote = this.privateNote;
    ticketData.ticketTitle = this.ticketTitle;
    ticketData.currentRoomId = this.currentRoom.id;
    ticketData.popupOpenedAt = this.popupOpenedAt;
    ticketData.isNhdTicket = this.isNhdTicket;
    ticketData.selectedChildClient = this.selectedChildClient;
    ticketData.parentAtAccountId = this.parentAtAccountId;
    ticketData.selectedPriority = this.selectedPriority;
    ticketData.isDefault = true;


    ticketDataList.push(ticketData);

    for (var i = 0; i < model.items.length; i++) {
      var addTicket: createTicket = new createTicket();
      // addTicket.ticketNumber=this.currentTicket.TicketNumber;
      addTicket.selectedStatus = this.selectedStatus;
      addTicket.selectedQueue = this.selectedQueue;
      addTicket.selectedContact = this.selectedContact;
      addTicket.selectedIssue =  model.items[i] && model.items[i].additionalIssue || -1;
      addTicket.selectedSubIssue = model.items[i] &&  model.items[i].additionalSubIssue || -1;
      addTicket.internalNote = model.items[i].ticketMentorTimeEntry;
      addTicket.privateNote = model.items[i].ticketMentorPrivateNote;
      addTicket.ticketTitle = model.items[i].additionalTicketTitle;
      addTicket.currentRoomId = this.currentRoom.id;
      addTicket.popupOpenedAt = this.popupOpenedAt;
      addTicket.isNhdTicket = this.isNhdTicket;
      addTicket.selectedChildClient = this.selectedChildClient;
      addTicket.parentAtAccountId = this.parentAtAccountId;
      addTicket.selectedPriority = this.selectedPriority;
      addTicket.isDefault = false;
      ticketDataList.push(addTicket);
    }


    if (activeButton == "submit-1") {
      this.chatService.updateTicket(
        ticketDataList, false);

      this.noticeMessage = "Please wait while your request is being processed...";
    }

    if (activeButton == "submit-2") {
      this.chatService.createNewTicket(
        ticketDataList, false);

      this.noticeMessage = "Please wait while your request is being processed...";
    }

    if (activeButton == "submit-previous-1") {
      this.chatService.updateTicket(
        ticketDataList, true);

      this.noticeMessage = "Please wait while your request is being processed...";
    }

    if (activeButton == "submit-previous-2") {
      this.chatService.createNewTicket(
        ticketDataList, true);

      this.noticeMessage = "Please wait while your request is being processed...";
    }


    this.noticeImage = "assets/images/gears.gif";
    this.initializeComponents();
    setTimeout(() => {
      this.spinnerService.show();
    }, 600);
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

  public makeFirstLetterUppercase(word: string) {
    var result = "";
    var splitted = word.split(" ");
    splitted.forEach(function (value) {
      var res = value.charAt(0).toUpperCase() + value.slice(1);
      result += res + " ";
    });
    return result.trim();
  }
}


