import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash'; 
import { User } from '../../model/user';

declare var _: any; // lodash, not strictly typed

@Pipe({
  name: 'distinctUsers',
  pure: false
})
export class DistinctUsersPipe implements PipeTransform {

  transform(items:any[], filter:any):any[] {

    if (!items || !filter) {
        return items;
    }
    // filter items array, items which match and return true will be
    // kept, false will be filtered out
    return items.filter(item => item.userName.indexOf('m1@test.com') !== -1);
}

}
