<div id="wrapper">

    <!-- Sidebar Holder -->
    <!-- <nav id="sidebar" class="active">
        <div class="sidebar-header">
            <a class="navbar-brand" href="#">
                <img src="assets/images/logo.png" />
            </a>
            <strong>GMS</strong>
        </div>

        <ul class="list-unstyled components">
            <li>
                <a (click)="openChat($event)">
                    <i class="glyphicon glyphicon-send"></i> Chat
                </a>
                <a (click)="openHotText($event)">
                    <i class="glyphicon glyphicon-send"></i> Hot Text
                </a>
                <a (click)="openHotContacts($event)">
                    <i class="glyphicon glyphicon-send"></i> Hot Contacts
                </a>
                <a (click)="openHotLogins($event)">
                    <i class="glyphicon glyphicon-send"></i> Hot Logins
                </a> 
            </li>
             <li>
                <a (click)="openHotItemManag($event)">
                    <i class="glyphicon glyphicon-send"></i> Hot Item Management
                </a>
            </li>
            <li>
                <a (click)="openBellPopUp($event)">
                    <i class="glyphicon glyphicon-send"></i> Bell Pop Up
                </a>
            </li>            
            <li>
                <a (click)="openTicketHistory($event)">
                    <i class="glyphicon glyphicon-send"></i> Ticket History
                </a>
            </li>
            <li>
                <a (click)="openReportCards($event)">
                    <i class="glyphicon glyphicon-send"></i> Report Cards
                </a>
            </li> 


        </ul>


    </nav> -->


    <!-- Page Content Holder -->
    <div id="content">

        <chat-nav-bar></chat-nav-bar>    
        <div style="width: 100%;">
           
            <ng-template appCompHolder> </ng-template>
            <div class="clear"></div>
            <b class="version-display">{{appVersion}}</b>
        </div>


    </div>


</div>
