

export class TicketData {
    ID: string;
    TicketNumber: string;
    Title: string;
    AccountID: string;
    TicketURL: string;
    TicketSourceName: string;
    ContactId: number;
    IssueType: number;
    Priority: number;
    Queue: number;
    Source: number;
    Status: number;
    SubIssueType: number;
    IsPreviousTicket:boolean;
    PreviousRoomKey: string;

    constructor(obj?: any) {
      
      this.ID = obj && obj.ID || '-1';
      this.TicketNumber = obj && obj.TicketNumber || "loading...";
      this.Title = obj && obj.Title || "";
      this.AccountID = obj && obj.AccountID || "";
      this.ContactId = obj && obj.ContactId || -1;
      this.IssueType = obj && obj.IssueType || -1;
      this.Priority = obj && obj.Priority || -1;
      this.Queue = obj && obj.Queue || -1;
      this.Source = obj && obj.Source || -1;
      this.Status = obj && obj.Status || -1;
      this.SubIssueType = obj && obj.SubIssueType || -1;
      this.IsPreviousTicket= obj && obj.IsPreviousTicket || false;
      this.PreviousRoomKey= obj && obj.PreviousRoomKey||"";
    }
  }
