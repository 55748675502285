import { Issue } from "./issue.model";
import { Subissue } from "./subissue.model";

export class Ticket {
  ID: string;
  TicketNumber: string;
  Title: string;
  AccountID: string;
  TicketURL: string;
  TicketSourceName: string;
  Issue: Issue;
  SubIssue: Subissue;
  ParentTicketNumber: string;
  CreatedDate: string;
  CreatedDateStr: string;

  constructor(obj?: any) {

    this.ID = obj && obj.ID || '-1';
    this.TicketNumber = obj && obj.TicketNumber || "loading...";
    this.Title = obj && obj.Title || "";
    this.AccountID = obj && obj.AccountID || "";
    this.Issue = obj && obj.Issue || null;
    this.SubIssue = obj && obj.Subissue || null;
    this.TicketSourceName = obj && obj.TicketSourceName || "";
    this.ParentTicketNumber = obj && obj.ParentTicketNumber || "";
    this.CreatedDate = obj && obj.CreatedDate || "";
    this.CreatedDateStr = obj && obj.CreatedDateStr || "";
  }
}
