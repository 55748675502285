import { Client } from "./client.model";

export class ClientFactory {

    public static createClient(clientVM: any): Client {
        const me: Client = new Client({
            id: clientVM.Id,
            name: clientVM.Name,
            // status: clientVM.Status,
            isActive: clientVM.IsActive,
            status: clientVM.status
        });
        return me;
    }
}