
import { MatLegacyDialog as MatDialog,MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import { Component, Inject} from '@angular/core';

import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, Validators } from '@angular/forms';

@Component({
    selector: 'app-contact-modal',
    templateUrl: './contact-modal.component.html',
    styleUrls: ['./contact-modal.component.css']
  })
  export class ContactModalComponent{
    model: any = {};
    myform: UntypedFormGroup;
    showFormErrors: boolean = false;
    isValidFormSubmitted = null;
    dataModel:any={};

    constructor(       
        private dialogRef: MatDialogRef<ContactModalComponent>,
                @Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog, fb: UntypedFormBuilder) {
                
                    this.dataModel=data;

    }

    ngOnInit() {
        this.myform = new UntypedFormGroup({
            firstname: new UntypedFormControl(),
            lastname: new UntypedFormControl(),
            email: new UntypedFormControl()
        });


        if(this.dataModel && !(this.dataModel && (Object.keys(this.dataModel).length === 0)))
        {
            this.myform.setValue({
                firstname: this.dataModel.FirstName, 
                lastname: this.dataModel.LastName,
                email:this.dataModel.Email
              });

        }
        
    }
    
    save(model: any): void {
        this.isValidFormSubmitted = false;
        if (this.myform.invalid) {
            return;
        }
        this.isValidFormSubmitted = true;
        this.dialogRef.close(model);
    }
    
    close() {
        this.dialogRef.close(false);
    }
    cancel() {
        this.myform.setValue({
            firstname: '', 
            lastname: '',
            email:''
          });
        this.dialogRef.close(false);
        return;
    }
  }
