import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor(private router: Router) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

        if (localStorage.getItem('currentUser')) {
            // logged in so return true (only for mentors)
            let currentUser = JSON.parse(localStorage.getItem('currentUser'));
            let proceed: Boolean = false;

            if (currentUser.hasMentorAccess != undefined)
                proceed = currentUser.hasMentorAccess.toLowerCase() == 'true' && currentUser.hasMentorAccess != null ? true : false;

            if (currentUser.isMentor != undefined)
                proceed = currentUser.isMentor;

            if (proceed) {
                return true;
            } else {
                if (localStorage.getItem('clientParams')) {
                    var clientParams = JSON.parse(localStorage.getItem('clientParams'));
                    this.router.navigateByUrl(clientParams.baseUrl);
                    return false;
                }
            }
        }

        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
        return false;
    }
}