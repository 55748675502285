
import {of as observableOf,  Observable } from 'rxjs';
import { Component, Inject, Input, HostListener  } from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

@Component({
  selector: 'confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.css']
})

export class ConfirmComponent {
  warningMessage:string = "";
  showCancelButton= new Observable<boolean>();

  constructor(
    private dialogRef: MatDialogRef<ConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any) 
    {
    dialogRef.disableClose = null;

    if (this.data != null){
      if (this.data.warningMessage != null){
        this.warningMessage = this.data.warningMessage;
      }

      if (this.data.showCancelButton != null){
        this.showCancelButton =observableOf(this.data.showCancelButton);
      }
    }
    }
  @HostListener('window:keyup.esc') onKeyUp() {
    this.dialogRef.close(null);
  }
  ngOnInit() {
    
  }

  save() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
