import { Component, OnInit, Input, Output, EventEmitter,OnChanges, SimpleChanges, SimpleChange} from '@angular/core';
import { ChatUser } from '../../model/chatuser.model';

import {
    MatLegacyInputModule as MatInputModule
  } from "@angular/material/legacy-input";
import {
    MatLegacyProgressSpinnerModule as MatProgressSpinnerModule
  } from "@angular/material/legacy-progress-spinner";
import {
    MatSortModule
  } from "@angular/material/sort";
import {
    MatLegacyTableModule as MatTableModule
  } from "@angular/material/legacy-table";
import {
    MatLegacyFormFieldModule as MatFormFieldModule, MatLegacyFormField as MatFormField
  } from "@angular/material/legacy-form-field";
import {
    MatLegacyButtonModule as MatButtonModule
  } from "@angular/material/legacy-button";
  
import {MatLegacyCheckboxModule as MatCheckboxModule} from "@angular/material/legacy-checkbox";
import { MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { BehaviorSubject } from 'rxjs';
import { AdminService } from '../../service/admin.service';
import { ActivatedRoute, Router } from "@angular/router";
import { UserRole } from '../../model/UserRole.model';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

@Component({
    selector: 'app-mentor-add-edit',
    templateUrl: './mentor-add-edit.component.html',
    styleUrls: ['./mentor-add-edit.component.css']
  })

  export class AdminMentorAddEditComponent implements OnInit {

    public mentorUser:ChatUser;
    public userRoles:Array<UserRole>;
    public fileList:any;
    public mentorUserLogo = new BehaviorSubject<string>(null);
    public mentorUserLogo$;
    emailPattern = "[a-zA-Z0-9.-_-]{1,}@[0-9a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
    
    myform : UntypedFormGroup= new UntypedFormGroup({
      name: new UntypedFormControl('', [Validators.required]),
      displayName: new UntypedFormControl(''),
      email: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(this.emailPattern)
      ])),
      role: new UntypedFormControl('', [Validators.required])
      
    });
    isValidFormSubmitted = null;
    disableSignInButton: boolean = false;
    

    constructor(
      private adminService:AdminService,
      private route: ActivatedRoute,
      private router:Router){
      this.getMentorRoles();
        
      if (this.route.snapshot.paramMap.get("id") != null){
        this.getMentorDetails(this.route.snapshot.paramMap.get("id"));
       
      }else {
        this.mentorUser = new ChatUser();
      }
    }
    
    ngOnInit() {
      
      
    }

    getMentorDetails(id:any): void {
      this.adminService.GetUserDetails(id).subscribe(data => {
        if (data){
          this.mentorUser = data[0];
          this.mentorUserLogo$ = this.mentorUser.logo;
          
          this.myform.setValue({
            name: this.mentorUser.name, 
            displayName: this.mentorUser.displayName,
            email:this.mentorUser.email,
            role:this.mentorUser.role,
          });
        }else {
          this.router.navigateByUrl('admin/mentors'); // no messing with query parameters!
        }
      });  
    }

    getMentorRoles(): void {
      this.adminService.GetUserRoles().subscribe(data => {
        if (data){
          this.userRoles=data;
        }
      });  
    }

    onCloseClick(){
      this.router.navigateByUrl('admin/mentors');
    }

    onSaveClick(model:any){
      
      if (this.myform.invalid) {
        return;
      }
      this.disableSignInButton=true;

      var newMewntor= new ChatUser();
      newMewntor.id=this.mentorUser.id;
      newMewntor.name=model.name;
      newMewntor.displayName=model.displayName;
      newMewntor.email=model.email;
      newMewntor.role=model.role;

      if (this.mentorUser.id != "-1"){
        if (this.fileList != null){
          if (this.fileList.length > 0) { 
            let file: File = this.fileList[0];  
            let formData: FormData = new FormData();  
            let fileName = file.name.split(".");
            let extension = fileName[1];
            let imageFullName = this.mentorUser.id + "." + extension;
            formData.append('uploadFile', file, imageFullName);  
            this.adminService.UploadLogo(formData)
            .subscribe(data => {
              this.mentorUserLogo.next(data.toString());
              this.mentorUserLogo$ = this.mentorUserLogo.getValue();
              this.disableSignInButton=false;
            },
            error =>{
              console.log(error)
              this.disableSignInButton=false;              
            }  );
          }
        }
      }
      
      if (this.mentorUser.id != "-1"){
        this.adminService.UpdateUser(newMewntor)
        .subscribe(data => {
          this.disableSignInButton=false;        
          alert("Changes successfuly saved!");
          
        },
        error => { 
          this.disableSignInButton=false;        
          
          console.log();
          alert(error.error);  
        });
      }
      else {
        this.adminService.CreateUser(newMewntor)
        .subscribe(data => {
          this.disableSignInButton=false;        
          
          alert("Successfuly saved!");
        },
        error => { 
          this.disableSignInButton=false;        
          
          console.log(error.error); 
          alert(error.error); 
        });
      }
      
  
    }

  fileChange(event) {  
    this.fileList = event.target.files;
  }

  onSendInvitationClick(){
    this.adminService.ReInviteUser(this.mentorUser)
        .subscribe(data => {
          alert("Success!");
        },
        error => { console.log(error); alert("Problem occurred while creating mentor, please try again.") });
  }

  }
