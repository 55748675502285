import { Pipe, PipeTransform } from '@angular/core';
import { Room } from '../../model/room.model';

@Pipe({
  name: 'roomColor',
  // pure: false
})
export class RoomColorPipe implements PipeTransform {

  transform(value: any, args?: any): any {


    let delta = 100;
    if (value.firstMessage != undefined) {
      let now = new Date().getTime();
      delta = (now - new Date(value.firstMessage.sentAt).getTime()) / 1000;
    }

    if (value.roomStatus == 1 && delta < 30)
      return 'yellow';
    else
      return this.getRoomStatus(value.roomStatus);
  }

  getRoomStatus(tmp): string {

    switch (tmp) {
      case 1: {
        return '#FF0000';//'#ffecee';
      }
      case 2: {
        return '#a7a7a7';//'#eff8ff';
      }
      case 3: {
        return '#0bf46d';//'#e0ffed';
      }
      case 4: {
        return '#0bd2f6';//'#D8F9FF';
      }
      default: {
        return '#775fe1';
      }
    }

  }

}
