import { Component, OnInit, Input } from '@angular/core';
import { RoomsService } from '../../service/rooms.service';
import { Room } from '../../model/room.model';
import { ChatService } from '../../service/chatservice';
@Component({
  selector: 'chat-window-members',
  templateUrl: './chat-window-members.component.html',
  styleUrls: ['./chat-window-members.component.css']
})
export class ChatWindowMembersComponent implements OnInit {
  @Input() room: Room;
  
  constructor(private roomService: RoomsService, private chatService:ChatService) {


  }

  ngOnInit() {
  }

  getUserInfoOnInit(){
  }

  getStyle() {
    return 'gray';
  }

}
