<!-- conversations -->
<div class="row">
  <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
    <li [ngbNavItem]="1">
      <a ngbNavLink>All Sessions</a>
      <ng-template ngbNavContent>
        <div class="conversation-wrap col-lg-12 col-md-12 col-sm-12 conversation-wrap-allsessions">
          <div *ngFor="let group of groupByRooms| async">
            <!-- <div>{{ group.type }} </div> -->
            <chat-room *ngFor="let rm of group.rooms" [room]="rm" (click)="onSelect(rm)"> </chat-room>
          </div>

        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="2">
      <a ngbNavLink>My Sessions</a>
      <ng-template ngbNavContent>
        <div id="mySessionContainer" class="conversation-wrap col-lg-12 col-md-12 col-sm-12 conversation-wrap-mysessions">
          <div id="mySessionItems" *ngFor="let group of groupByMyRooms| async">
            <!-- <div>{{ group.type }}</div> -->
            <chat-room-notification id="sessionItem" *ngFor="let rm of group.rooms " [room]="rm"> </chat-room-notification>
          </div>

        </div>
      </ng-template>
    </li>
    <li [ngbNavItem]="3">
      <a ngbNavLink>Not Answered</a>
      <ng-template ngbNavContent>
        <div class="conversation-wrap col-lg-12 col-md-12 col-sm-12 conversation-wrap-notanswered">
          <chat-room-notification *ngFor="let myroom of noUnsweredRooms | async " [room]="myroom" class="classRoomoNotification"> </chat-room-notification>
        </div>
      </ng-template>
    </li>

    <li [ngbNavItem]="4">
      <a ngbNavLink>To Be Closed</a>
      <ng-template ngbNavContent class='ToBeClosed'>
        <div class="conversation-wrap col-lg-12 col-md-12 col-sm-12 conversation-wrap-tobeclosed">
          <chat-room-to-be-closed *ngFor="let myroom of closedRooms | async " [room]="myroom" (click)="onSelectClosedRoom(myroom)" > </chat-room-to-be-closed>
        </div>
      </ng-template>
    </li>
  </ul>
  <div [ngbNavOutlet]="nav"></div>


</div>
