export class TicketDraft {
    ID: string;
    TicketNumber: string;
    Title: string;
    Status:number;
    Queue:number;
    SelectedChild:number;
    Contact:number;
    Issue: number;
    SubIssue: number;
    InternalNote:string;
    PrivateNote:string;
    PopupOpenedAt:string;
    SelectedTemplate:number;
    SelectedChildClientInitial:number;
    Priority: number;

    constructor(obj?: any) {
      
      this.ID = obj && obj.ID || '-1';
      this.TicketNumber = obj && obj.TicketNumber || "loading...";
      this.Title = obj && obj.Title || "";
      this.Status = obj && obj.Status || -1;
      this.Queue= obj && obj.Queue || -1;
      this.Issue = obj && obj.Issue || -1;
      this.SubIssue = obj && obj.SubIssue || -1;
      this.InternalNote = obj && obj.InternalNote || "";
      this.PrivateNote=obj&& obj.PrivateNote || "";
      this.PopupOpenedAt=obj&& obj.PopupOpenedAt || "";
      this.SelectedChild=obj && obj.SelectedChild||-1;
      this.Contact=obj && obj.Contact|| -1;
      this.SelectedTemplate = obj && obj.SelectedTemplate || -1;
      this.SelectedChildClientInitial = obj && obj.SelectedChildClientInitial || -1;
      this.Priority= obj && obj.Priority || 2;
    }
  }
