
<div id="toggleReg" class="{{paramWidget}}"  *ngIf="pageTranslated">
    <div class="regHeader" [style.background-color]="chatColor">
        <img class="partner-logo" src = "{{logoFullPath}}" onerror="this.onerror=null; this.src='assets/images/logo.png'">
    </div>
    <div class="regBody">
    <form name="form" (ngSubmit)="disableSignInButton || registerNewUser(myform.value)" [formGroup]="myform" novalidate >
           
        <div class="register-form">

            <!--FNAME-->
                <div class="form-group">
                    <label for="firstName">{{pageTranslation.FirstName}}</label>
                    <input type="text"
                    class="form-control"
                    formControlName="firstName" 
                    required
                    readonly="readonly"
                    />

                    <div class="validation-errors">
                            <div class="error-message" *ngIf="myform.get('firstName').hasError('required') && (myform.get('firstName').dirty || myform.get('firstName').touched) || (isValidFormSubmitted == false && myform.get('firstName').hasError('required'))">{{pageTranslation.FNameRequired}}</div>
                        </div>
                </div>

                <!--LNAME-->
                <div class="form-group">
                    <label for="lastName">{{pageTranslation.LastName}}</label>
                    <input type="text"
                    class="form-control"
                    formControlName="lastName" 
                    required 
                    readonly="readonly"
                    />

                    <div class="validation-errors">
                            <div class="error-message" *ngIf="myform.get('lastName').hasError('required') && (myform.get('lastName').dirty || myform.get('lastName').touched) || (isValidFormSubmitted == false && myform.get('lastName').hasError('required'))">{{pageTranslation.LNameRequired}}</div>
                        </div>
                </div>

                <!--EMAIL-->
            <div class="form-group">
                <label for="email">{{pageTranslation.Email}}</label>
                <input type="email"
                class="form-control"
                formControlName="email" 
                required 
                readonly="readonly"
                />

                <div class="validation-errors">
                  <div class="error-message" *ngIf="myform.get('email').hasError('pattern') && (myform.get('email').dirty || myform.get('email').touched)">{{pageTranslation.ValidEmail}}</div>
                        <div class="error-message" *ngIf="myform.get('email').hasError('required') && (myform.get('email').dirty || myform.get('email').touched) || (isValidFormSubmitted == false && myform.get('email').hasError('required'))">{{pageTranslation.EmailRequired}}</div>
                    </div>
            </div>
            
            <!--ADDRESS-->
            <div class="form-group">
                <label for="address">{{pageTranslation.Address}}</label>
                <input type="text" class="form-control" name="address" formControlName="address" />

                <div class="validation-errors">
                  <div class="error-message" *ngIf="myform.get('address').hasError('required') && (myform.get('address').dirty || myform.get('address').touched) || (isValidFormSubmitted == false && myform.get('address').hasError('required'))">{{pageTranslation.AddressRequired}}</div>
                    </div>
            </div>

            <!--PHONE-->
            <div class="form-group">
                <label for="phone">{{pageTranslation.Phone}}</label>
                <input type="text" class="form-control" name="phone" formControlName="phone" /> 

                <div class="validation-errors">
                  <div class="error-message" *ngIf="myform.get('phone').hasError('pattern') && (myform.get('phone').dirty || myform.get('phone').touched)">{{pageTranslation.ValidPhone}}<br>{{pageTranslation.PhoneExample}}</div>
                        <div class="error-message" *ngIf="myform.get('phone').hasError('required') && (myform.get('phone').dirty || myform.get('phone').touched) || (isValidFormSubmitted == false && myform.get('phone').hasError('required'))">{{pageTranslation.PhoneRequired}}</div>
                    </div>
            </div>

            <br />

            <div class="form-group">
                <button class="btn btn-primary" [ngClass]="{ 'disabled': disableSignInButton }" [style.background-color]="chatColor">{{pageTranslation.RegisterEnterChat}}</button>

            </div>
        </div>

    </form>
    <h1 class="cred">{{pageTranslation.PoweredByGlobalMentoring}}</h1>
    
</div>
</div>
