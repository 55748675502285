

import { Message } from './message.model';
import { uuid } from '../home/util/uuid';
import { roomsServiceInjectables } from '../service/rooms.service';

/**
 * Room represents a group of Users exchanging Messages
 */
export class Room {
  session:string;
  id: string;
  firstMessage: Message;
  lastMessage: Message;
  lastActiveMentor: string;
  lastMessageId: string;
  name: string;
  avatarSrc: string;
  type: string;
  topic: string;
  roomStatus: number;
  closed: number;
  language: string;
  mentorCount: number;
  currMentorIsPartOfTheRoom: boolean;
  ticketNumber: string;
  accountID: string;
  createdAt: Date;
  isMspAuthentication:boolean;
  //mentorsCount:number;

  constructor(obj?: any) {
    this.session = obj && obj.session || '0';
    this.id = obj && obj.id || '-1';
    this.name = obj && obj.name || "...";
    this.type = obj && obj.type || '0';//proveri za so se koristeshe type    
    this.topic = obj && obj.topic || '';
    this.roomStatus = obj && obj.roomStatus || 2;
    this.language = obj && obj.language;
    this.closed = obj && obj.closed;
    this.mentorCount = obj && obj.mentorCount || 0;
    this.currMentorIsPartOfTheRoom = obj && obj.currMentorPartOfTheRoom || false;
    this.lastMessageId = obj && obj.lastMessageId || -1;
    this.lastActiveMentor = obj && obj.lastActiveMentor || '-';
    this.ticketNumber = obj && obj.ticketNumber || '-1';
    this.createdAt= obj && obj.createdAt;
    this.isMspAuthentication = obj && obj.isMspAuthentication || false;
    //this.mentorsCount = obj && obj.mentorsCount || -1;
  }
}

export class RoomGroup {

  type: string;
  rooms: Room[];
  constructor(roomType: string, room: Room) {
    this.type = roomType
    this.rooms = [];
    this.rooms.push(room);
  }
}
