import { TicketData } from "./ticketData";


export class TicketDataFactory {
 
    public static createTicket(ticketVM: any) : TicketData {
        return new TicketData({
            ID: ticketVM.ID,
            TicketNumber:ticketVM.TicketNumber,                 
            Title: ticketVM.Title,
            TicketSourceName: ticketVM.TicketSourceName,
            AccountID: ticketVM.AccountID,
            ContactId: ticketVM.ContactId,
            IssueType: ticketVM.IssueType,
            Priority: ticketVM.Priority,
            Queue: ticketVM.Queue,
            Source: ticketVM.Source,
            Status:  ticketVM.Status,
            SubIssueType: ticketVM.SubIssueType,
            IsPreviousTicket: ticketVM.IsPreviousTicket,
            PreviousRoomKey: ticketVM.PreviousRoomKey
        });  
    }

}