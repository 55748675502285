export class MappingFields {
    id: number;
    fieldValue: number;
    fieldName: string;
    mappingType: number;
    isActive: boolean;
    parentValueidId: number;

    constructor(obj?: any) {
        this.id = obj && obj.id || null;
        this.fieldValue = obj && obj.fieldValue || null;
        this.fieldName = obj && obj.fieldName || null;
        this.mappingType = obj && obj.mappingType || false;
        this.isActive = obj && obj.isActive || 'false';
        this.parentValueidId = obj && obj.parentValueidId || null;
    }

}