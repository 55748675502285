import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { User } from '../../model/user';

@Pipe({
  name: 'typing',
  pure: false
})
export class TypingPipe implements PipeTransform {
  private currentValue: string = null;

  constructor(private changeDetector: ChangeDetectorRef) {
  }


  transform(users: User[], args: Array<any>): string {


    if (users === null) return;
    if (this.currentValue !== this.displayMessage(users,args.toString())) {
      this.currentValue = this.displayMessage(users, args.toString());
      this.changeDetector.markForCheck();
    }


    return this.currentValue;
  }

  displayMessage(users: User[], lang: any): string {
    let typingMessage = "";
    var peopleInChat = users.length;
    if (peopleInChat > 2) {
      typingMessage = peopleInChat + " people are typing";
      if (lang === 'fr')
        typingMessage = peopleInChat + " les gens tapent";
    }
    else if (peopleInChat == 2) {
      typingMessage = "";
      users.forEach(element => {
        typingMessage += element.name + " & ";
      });
      typingMessage = typingMessage.slice(0, -2);
      typingMessage += " are typing";
      if (lang === 'fr')
        typingMessage += " sont en train de taper";
    }
    else if (peopleInChat == 1)
    {      
      typingMessage = users[0].name + " is typing";
      if (lang === 'fr')
        typingMessage = users[0].name + " en train d’écrire";
    }
    else
      typingMessage = "";
    return typingMessage;
  }


}
