
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { MappingFields } from '../model/MappingFields.model';
import { HttpClient } from '@angular/common/http';
import { Contact } from '../model/contact.model';
import * as _ from 'lodash';
import { Message } from '../model/message.model';
import { messageFactory } from '../model/messageFactory';
import { UserFactory } from '../model/userFactory';
import { RoomFactory } from '../model/RoomFactory';
import { Room } from '../model/room.model';
import { AppConfig } from '../AppConfig';

@Injectable()
export class IssueService {
  private actionUrl: string;
  public subissues: any[];
  constructor(private _httpClient: HttpClient, private config:AppConfig) {}

  GetAllIssues(): Observable<MappingFields[]> {
    return this._httpClient.get(this.config.origin + "/api/popup/Issues").pipe(
      map(data => _.values(data)));
  }

  GetAllSubissues(issueId: number): Observable<MappingFields[]> {
    return this._httpClient.get(this.config.origin + "/api/popup/Issues/" + issueId).pipe(
      map(data => _.values(data)));
  }


  GetContactsForRoom(roomId: string): Observable<Contact[]> {
    return this._httpClient.get(this.config.origin + "/api/popup/Contacts/" + roomId).pipe(
      map(data => _.values(data)));
  }

  GetContactsByUserId(userId: string): Observable<Contact[]> {
    return this._httpClient.get(this.config.origin + "/api/popup/GetAllContactsByUserId/" + userId).pipe(
      map(data => _.values(data)));
  }

  GetAllStatuses(): Observable<MappingFields[]> {
    return this._httpClient.get(this.config.origin + "/api/popup/Statuses").pipe(
      map(data => _.values(data)));
  }

  GetQueues() {
    return this._httpClient.get(this.config.origin + "/api/popup/Queues").pipe(
      map(data => _.values(data)));
  }

  GetPriorities() {
    return this._httpClient.get(this.config.origin + "/api/popup/Priorities")
      .pipe(map(data => _.values(data)));
  }

  GetCompanies(clientId: string) {
    return this._httpClient.get(this.config.origin + "/api/popup/Companies/" + clientId).pipe(
      map(data => _.values(data)));
  }

  GetChildClientsByUserId(userId: string) {
    return this._httpClient.get(this.config.origin + "/api/popup/GetChildClientsByUserId/" + userId).pipe(
      map(data => _.values(data)));
  }

  GetAllContactsByClientId(clientId: number) {
    return this._httpClient.get(this.config.origin + "/api/popup/GetAllContactsByClientId/" + clientId).pipe(
      map(data => _.values(data)));
  }

  CheckIfMspAllowsAddingContacts(accountId: number) {
    return this._httpClient.get(this.config.origin + "/api/popup/CheckIfMspAllowsAddingContacts/" + accountId);
  }

  GetTranscript(room: Room): Observable<Message[]> {
    return this._httpClient.get(this.config.origin + "/api/popup/Transcript/" + room.id).pipe(
      map(data => {
        let msgs: Message[] = new Array<Message>();
        for (let entry of _.values(data)) {
          
            msgs.push(messageFactory.createMessageLowercase(entry, room, UserFactory.createUserLowercase(entry.user)));
        }
        return _.values(msgs) ;
      }));
  }

  GetRoomMessages(room: Room, lastMsgId: string): Observable<Message[]> {
    return this._httpClient.get(this.config.origin + "/api/popup/Transcript1/" + room.id+ "/"+ lastMsgId).pipe(
      map(data => _.values(data)));
  }

  GetRoomNotes(roomId: string): Observable<any[]> {
    return this._httpClient.get(this.config.origin + "/api/popup/Notes/" + roomId)
    .pipe(map(data => _.values(data)));
  }

  AddNewContact(clientId: string, firstName: string, lastName: string, email: string) {
    
    const body = { 
      ClientId: clientId, 
      FirstName: firstName,
      LastName: lastName,
      Email:email };

    return this._httpClient.post<any>(this.config.origin + '/api/account/contact', body);

  }

  CloseNhdChat(roomGuid: string) {
    
    const body = { 
      RoomGuid: roomGuid
     };

    return this._httpClient.post<any>(this.config.origin + '/api/popup/closenhdchat', body);

  }
  
}