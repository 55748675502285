import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../../service/authentication.service';
import { AdminService } from '../../service/admin.service';
import { MaintenanceMode } from '../../model/maintenancemode.model';

@Component({
    templateUrl: 'login.component.html',
    selector: 'signin',
    styleUrls: ['login.component.css']
})

export class LoginComponent implements OnInit {
    model: any = {};
    loading = false;
    returnUrl: string;
    fullImagePath: string;
    loadingImage: string;
    messageClass;
    message;
    public showErrorMessage: Boolean = false;
    public errorMessage: string = "";
    deviceInfo = null;
    deviceType: string;
    deviceTypeMessage: string;
    appVersion: string = "";
    private CONST_GMSMENTORS_GROUPID: number = 1;
    private maintenanceMode: MaintenanceMode;
    public showLoginForm: Boolean = false;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private authenticationService: AuthenticationService,
        private adminService: AdminService) {
    }

    ngOnInit() {
        this.adminService.CheckIfMaintenanceModeIsEnabled(this.CONST_GMSMENTORS_GROUPID).subscribe(
            data => {
                this.maintenanceMode = data[0];
                if (!this.maintenanceMode.loginAllowed) {
                    this.router.navigate(['/maintenance'], { queryParams: { q: this.CONST_GMSMENTORS_GROUPID } });
                } else {
                    this.showLoginForm = true;
                    this.authenticationService.logout();
                    this.fullImagePath = 'assets/images/logo.png';
                    this.loadingImage = 'assets/images/gears.gif';
                    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

                    const subGetAppVersion = this.adminService.GetAppVersion().subscribe((data) => {
                        this.appVersion = "v" + data.toString().replace('"', '').replace('"', '');
                    });

                    this.appVersion = "v1.0";
                }
            });
    }

    login() {
        this.showErrorMessage = false;
        this.loading = true;
        this.authenticationService.login(this.model.username, this.model.password)
            .subscribe(
                data => {
                    let proceed = data.hasMentorAccess.toLowerCase() == 'true' && data.hasMentorAccess != null ? true : false;
                    if (proceed) {
                        this.router.navigate([this.returnUrl]);
                    }
                    else {
                        this.errorMessage = "You don't have access to this section!";
                        this.showErrorMessage = true;
                        this.loading = false;
                    }

                },
                error => {
                    this.errorMessage = error.error.error_description;
                    this.showErrorMessage = true;
                    this.loading = false;
                });
    }


    checkActiveSessions(data: any) {
        const isDesktop = true;
        const isMobile = false;
        const isTablet = false;

        if (isDesktop) {
            this.deviceType = "1";
            this.deviceTypeMessage = " from web browser!";
        }

        if (isMobile || isTablet) {
            this.deviceType = "2";
            this.deviceTypeMessage = " from mobile browser!";
        }

        this.adminService.CheckActiveSessions(data.userName, this.deviceType)
            .subscribe(
                data => {
                    var logOn = data.text().toLowerCase() == 'true' ? true : false;
                    if (logOn) {
                        this.router.navigate([this.returnUrl]);
                    } else {
                        this.authenticationService.logout();
                        this.errorMessage = "You are already signed in" + this.deviceTypeMessage
                        this.showErrorMessage = true;
                        this.loading = false;
                    }
                },
                error => {
                    this.loading = false;
                }
            );
    }
}
