import { Room } from "./room.model";

export class RoomFactory {

    public static createRoom(roomVM: any): Room {

        let roomTypeStr: String = '';
        if (roomVM.roomType == '1')
            roomTypeStr = "TELCO"; 
        else if (roomVM.roomType == '2')
            roomTypeStr = "MSP";
        else if (roomVM.roomType == '3')
            roomTypeStr = "EDU";
        else if (roomVM.roomType == '4')
            roomTypeStr = "GUEST";
        else roomTypeStr = "";


        return new Room({
            session: roomVM.session,
            id: roomVM.RoomGuid,            
            name: roomVM.Name,            
            type: roomTypeStr,
            ticketNumber: roomVM.TicketNumber,
            roomStatus: roomVM.roomStatus,
            language: roomVM.roomLanguage,
            createdAt: roomVM.createdAt,
            isMspAuthentication:roomVM.isMspAuthentication,
            mentorCount:roomVM.mentorCount,
            closed:roomVM.Closed


            // mentorCount: roomVM.AdminCount,
            // usersCount: roomVM.UsersCount,            
            // lastParticipant: roomVM.LastParticipant,            
            // topic: roomVM.Topic,            
            // accountID: roomVM.AccountID,
            // closed: roomVM.closed,            
            // lastMessageId: roomVM.lastMessageId,
            

            
        });
    }

  public static createRoomRestApi(roomVM: any): Room {

    let roomTypeStr: String = '';
    if (roomVM.roomType == '1')
      roomTypeStr = "TELCO";
    else if (roomVM.roomType == '2')
      roomTypeStr = "MSP";
    else if (roomVM.roomType == '3')
      roomTypeStr = "EDU";
    else if (roomVM.roomType == '4')
      roomTypeStr = "GUEST";
    else roomTypeStr = "";


    return new Room({
      session: roomVM.session,
      id: roomVM.roomGuid,
      name: roomVM.name,
      type: roomTypeStr,
      ticketNumber: roomVM.ticketNumber,
      roomStatus: roomVM.roomStatus,
      language: roomVM.roomLanguage,
      createdAt: roomVM.createdAt,
      closed:roomVM.Closed

    });
  }


    public static createLobbyRoom(roomId: string): Room {
        var today = new Date();
        today.setHours(0,0,0,0);
        return new Room({ id: roomId, name: "Lobby", createdAt: today });
    }

    public static createEmptyRoom(): Room {
        var today = new Date();
        today.setHours(0,0,0,0);
        return new Room({ id: -1, createdAt:new Date(today.getTime() - (24*60*60*1000)) });
  }

  public static createRoomWithGuid(roomId: string): Room {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    return new Room({ id: roomId, createdAt: new Date(today.getTime() - (24 * 60 * 60 * 1000)) });
  }
}
