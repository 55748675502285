
import {catchError, map} from 'rxjs/operators';

import {throwError as observableThrowError,  Observable } from 'rxjs';
import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, Injectable, NgModule } from '@angular/core';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
// import 'rxjs/Rx';
import { AppConfig } from '../AppConfig';

// Our service to handle errors (ideally in its own file)
@Injectable()
export class ErrorLogService {
  [x: string]: any;
  private name: String = 'ErrorLogService';

  constructor(private http: HttpClient,private config: AppConfig) {
  }

  logError(error: any): any {

    let errorMsg = '';
    // if (error instanceof HttpErrorResponse) {
    //   errorMsg = 'There was an HTTP error. ' + error.message + ' Status code: ' + (<HttpErrorResponse>error).status + " - \n" + error.stack;
    // } else if (error instanceof TypeError) {
    //   errorMsg = 'There was a Type error. ' + error.message + " - \n" + error.stack;
    // } else if (error instanceof Error) {
    //   errorMsg = 'There was a general error.' + error.message + " - \n" + error.stack;
    // } else {
       errorMsg = "msg:\n " + error + " - \n\n stack: \n" + error.stack;
    // }

    let headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    let urlSearchParams = new URLSearchParams();
    urlSearchParams.append('errorMsg', errorMsg);
    let body = { errorMsg:  errorMsg};
    

    //return this.http.post('/api/Logger/LogException?err=test', body, { headers: headers }).map((response: Response) => {
      
    return this.http.post(this.config.origin + '/api/Logger/LogException', body).pipe(map((response: Response) => {
      //some stuff...
    }),catchError((error: any) => {
      return observableThrowError(error);
    }),);;
  }



  private handleErrorObservable(error: Response | any) {
    console.error(error.message || error);
    return observableThrowError(error.message || error);
  }
}

// global error handler that utilizes the above created service (ideally in its own file)
@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(private errorLogService: ErrorLogService) {
    super();
  }

  handleError(error) {
    
    this.errorLogService.logError(error).subscribe(() => console.log("-"));

  }
}