import { Injectable } from '@angular/core';
//import { Ng4LoadingSpinnerService } from 'ng4-loading-spinner';
import { Subject } from 'rxjs';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class MentorSpinnerService {

  public spinnerState: Subject<any> = new Subject<any>();

  constructor() {

  }

  public ShowSpinner() {
    this.spinnerState.next(true);
  }

  public HideSpinner() {
    this.spinnerState.next(false);
  }
}
