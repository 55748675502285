import { AfterViewInit, Component, OnInit, Input } from '@angular/core';
import { RegisterProcessComponent } from '../register-process';
import { Subscription ,  Subject, BehaviorSubject } from 'rxjs';
import { UsersService } from '../../service/users.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AlertService } from '../../service/alert.service';
import { ActivatedRoute } from '@angular/router';
import { MessagesService } from '../../service/messages.service';
import { LocalizationService } from '../../service/localization.service';
import { MentorSpinnerService } from '../../service/mentorspinner.service';
import {CustomizationService} from '../../service/customization.service';
import { AppConfig } from '../../AppConfig';

@Component({
  selector: 'app-register-user-bell',
  templateUrl: './register-user-bell.component.html',
  styleUrls: ['./register-user-bell.component.css']
})
export class RegisterUserBellComponent implements RegisterProcessComponent {
  @Input() data: any;
  myform: UntypedFormGroup;
  email: any;
  paramWidget: boolean = false;
  subscription: Subscription;
  public clientType: Subject<string> = new BehaviorSubject<string>(null);
  public clientTypeParam: string;
  mobnumPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/; 
  emailPattern = "[a-zA-Z0-9.-_-]{1,}@[0-9a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  isValidFormSubmitted = null;
  disableSignInButton: boolean = false;
  clientSubType: string = "Central";
  public logoFullPath: string = '';
  public colorFileFullPath: string = '';
  public chatColor: string = '#0061AA';
  //localization - s
  private pageName = "RegisterUserBellComponent";
  public pageTranslation: any = "";
  public pageTranslated = new BehaviorSubject<string>("");
  //localization - e

  constructor(private customizationService: CustomizationService, private mspinnerService: MentorSpinnerService, private localizationService: LocalizationService, private userService: UsersService, private alertService: AlertService,private activatedRoute: ActivatedRoute, private msgService: MessagesService, private config: AppConfig) {
    let clientId = userService.getClientIdParam();
    let clientType = userService.getClientTypeParam();

    
    this.logoFullPath =customizationService.getNoCacheImage(this.config.logoURL + clientId.toString() + '.png');
    
    this.colorFileFullPath = this.config.logoURL + this.userService.getClientIdParam().toString() + '.txt';

    this.chatColor = customizationService.getPersonalizedColor(this.colorFileFullPath);
    this.SetLocalization();

   this.clientType.next(clientType);
   this.myform = new UntypedFormGroup({

    firstName: new UntypedFormControl(),
    lastName: new UntypedFormControl(),
    email: new UntypedFormControl('', [
          Validators.required,
          Validators.pattern(this.emailPattern)
        ]),
    b1number: new UntypedFormControl(),
    message: new UntypedFormControl(),
    address: new UntypedFormControl(),
    phone: new UntypedFormControl('', [
          Validators.required, 
          Validators.pattern(this.mobnumPattern)
        ]),
    isAtDown: new UntypedFormControl()
  });

    this.subscription = this.userService.alreadyEntered.subscribe(t => { 
      this.myform.controls["email"].setValue(t.email);
      this.myform.controls["b1number"].setValue(t.b1number);
      this.myform.controls["message"].setValue(t.message);
      this.myform.controls["isAtDown"].setValue(t.isAtDown);
    });
  }

  ngOnInit() {
    this.clientType.subscribe((clientType: string) => {
      this.clientTypeParam = clientType;
    });
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['isWidget']) {
        this.paramWidget = true;
      } 
    });
  }

  SetLocalization() {
    var localeFromUrl = this.userService.getClientLanguageParam();
    var locale = this.localizationService.ValidateLocale(localeFromUrl);

    this.localizationService.GetLocalizationData().subscribe(data => {
      this.pageTranslation = data[0][this.pageName][locale];
      this.pageTranslated.next(this.pageTranslation);
    },
      error => { console.log(error) }, () => { });
  }

  registerNewUser(model: any): void {

    this.isValidFormSubmitted = false;
     if (this.myform.invalid) {
        return;
     }
     this.isValidFormSubmitted = true;
     model.firstName = model.firstName.trim();
    model.lastName = model.lastName.trim();
    model.userType = this.clientTypeParam;
    this.disableSignInButton = true;
    //this.spinnerService.show();
    this.mspinnerService.ShowSpinner();

    if(model.isAtDown){
      this.userService.RegisterNewTelcoUserInAtDownTime(model)
    .subscribe(
      data => {
          this.alertService.success('User exists', true);
          this.data = true;
          this.userService.checkUser(model);
          this.msgService.clientInitMessage.next(model.message);
        },
        error => {
          this.mspinnerService.HideSpinner();
          if(error.status == 404){
            this.alertService.error("User not found!");
            this.data = false;
            }
            else{
              this.userService.UserNotFound(error.status.toString());
              return;
            }
        });
    }
    else{
      this.userService.RegisterNewTelcoUser(model)
    .subscribe(
      data => {
          this.alertService.success('User exists', true);
          this.data = true;
          this.userService.checkUser(model);
          this.msgService.clientInitMessage.next(model.message);
        },
        error => {
          this.mspinnerService.HideSpinner();
          if(error.status == 404){
            this.alertService.error("User not found!");
            this.data = false;
            }
            else{
              this.userService.UserNotFound(error.status.toString());
              return;
            }
        });
    }
  }
}
