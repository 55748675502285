<ng-template appCompHolder> </ng-template>


<div id="toggleReg" class="{{paramWidget}}" *ngIf="twoCombined | async">
  <div class="regHeader" [style.background-color]="chatColor">
      <!--  -->
     <img class="partner-logo" [src]="logoFullPath | safe" > 
  </div>
  <div class="regBody">

    <h1 class="cred1"> 
      <p [innerHtml]="pageTranslation.WelcomeToYourHelpDesk"></p>
      <!-- <br /> -->
      <p [innerHtml]="pageTranslation.PleaseSignIn"></p>    
    </h1>
    
    <form name="form" (ngSubmit)="disableSignInButton || registerUser(myform.value)" [formGroup]="myform">
      <!--EMAIL-->
      <div class="form-group">
        <label for="email">{{pageTranslation.Email}}</label>
        <input type="email" name="email" class="form-control emailfield" formControlName="email" required />
        <!--  -->
        <div class="validation-errors">
          <div class="error-message" *ngIf="myform.get('email').hasError('pattern') && (myform.get('email').dirty || myform.get('email').touched)">
            {{pageTranslation.ValidEmail}}
          </div>
          <div class="error-message" *ngIf="myform.get('email').hasError('required') && (myform.get('email').dirty || myform.get('email').touched) || (isValidFormSubmitted == false && myform.get('email').hasError('required'))">{{pageTranslation.EmailRequired}}</div>
        </div>
      </div>

      <!--B1NUMBER-->
      <div class="form-group">
        <label for="b1number">{{pageTranslation.IdentificationNumber}}</label>
        <input type="text" class="form-control b1numberfield" name="b1number" formControlName="b1number" required />

        <div class="validation-errors">
          <!-- <div class="error-message" *ngIf="myform.get('b1number').hasError('pattern') && (myform.get('b1number').dirty || myform.get('b1number').touched)">
          </div> -->
          <div class="error-message b1" *ngIf="(myform.get('b1number').hasError('required') || myform.get('b1number').hasError('pattern')) && (myform.get('b1number').dirty || myform.get('b1number').touched) || (isValidFormSubmitted == false && myform.get('b1number').hasError('required'))">
            {{pageTranslation.PleaseEnterValid}}<br />
            {{pageTranslation.PleaseClickHere}} <a href="https://mybell.bell.ca/Login" target="_blank">{{pageTranslation.Here}}</a>.
          </div>
        </div>
      </div>

      <!-- FNAME -->
      <div class="form-group">
        <label for="firstName"> {{pageTranslation.FirstName}} </label>
        <input type="text"
               class="form-control fnamefield"
               formControlName="firstName"
               required />

        <div class="validation-errors">
          <div class="error-message" *ngIf="myform.get('firstName').hasError('required') && (myform.get('firstName').dirty || myform.get('firstName').touched) || (isValidFormSubmitted == false && myform.get('firstName').hasError('required'))">{{pageTranslation.FNameRequired}}</div>
        </div>
      </div>

      <!--LNAME-->
      <div class="form-group">
        <label for="lastName"> {{pageTranslation.LastName}} </label>
        <input type="text"
               class="form-control lnamefield"
               formControlName="lastName"
               required />

        <div class="validation-errors">
          <div class="error-message" *ngIf="myform.get('lastName').hasError('required') && (myform.get('lastName').dirty || myform.get('lastName').touched) || (isValidFormSubmitted == false && myform.get('lastName').hasError('required'))">{{pageTranslation.LNameRequired}}</div>
        </div>
      </div>

           <!--HOST NAME-->
           <div class="form-group">
            <label for="hostName">{{pageTranslation.HostName}}</label>
            <i class="fa fa-info-circle info-size" 
            matTooltip="{{pageTranslation.HostNameTooltip}}"
            [matTooltipPosition]="'right'"
            [matTooltipDisabled]="true"
            #myTooltip="matTooltip" 
            (mouseover)="displayTooltip('mouseover')"
            (mouseout)="closeTooltip()"
            (click)="displayTooltip('click')"></i>
            <input type="text" class="form-control hostnamefield" formControlName="hostName" />
          </div>
          
      <!--MESSAGE-->
      <div class="form-group">
        <label for="message">{{pageTranslation.Message}}</label>

        <textarea class="form-control messagefield" name="message" placeholder="{{pageTranslation.TypeYourMessage}}" 
          formControlName="message" required></textarea>

        <div class="validation-errors">
          <div class="error-message" *ngIf="myform.get('message').hasError('required') && (myform.get('message').dirty || myform.get('message').touched) || (isValidFormSubmitted == false && myform.get('message').hasError('required'))">
            {{pageTranslation.MessageRequiered}}
          </div>
          <div class="error-message" *ngIf="(isValidFormSubmitted == false && myform.get('message').hasError('whitespace'))">{{pageTranslation.ValidMessage}}</div>
        </div>
      </div>
      <br />

      <div class="form-group">
        <button type="submit" name="signIn" class="btn btn-primary btn-login-chat" [ngClass]="{ 'disabled': disableSignInButton }" 
        [style.background-color]="chatColor" [style.color]="butonTextColor"> {{pageTranslation.SignIn}} </button>
      </div>
    </form>
    <h1 class="cred">{{pageTranslation.PoweredByGlobalMentoring}}</h1>
  </div>
</div>
