
import {takeUntil} from 'rxjs/operators';
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  Inject,
  ViewContainerRef,
  SimpleChanges,
  ChangeDetectorRef,
  OnDestroy,
  OnChanges,
  SimpleChange,
  ChangeDetectionStrategy,
  ElementRef,
  ViewChild
} from '@angular/core';
import { Observable, Subject,ReplaySubject  } from 'rxjs';
import { Room } from '../../model/room.model';
import { RoomsService } from '../../service/rooms.service';
import { ChatService } from '../../service/chatservice';
import { ConfirmService } from '../confirm/confirm.service';
import { ConfirmComponent } from '../confirm/confirm.component';
import { BrowserModule } from '@angular/platform-browser';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { RoomFactory } from '../../model/RoomFactory';
// import { ToastsManager } from 'ng2-toastr';
import { User } from '../../model/user';
import { Message } from '../../model/message.model';





declare var componentHandler: any;

@Component({
  selector: 'chat-room',
  templateUrl: './chat-room.component.html',
  styleUrls: ['./chat-room.component.css'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})


export class ChatRoomComponent implements OnInit, OnDestroy {
  //@Input() room: Roo
  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() room: Room;
  private selectedRoom: Room;

  lastMessageSendAt: Date = new Date();
  selected = false;
  avatarSrc: string;
  roomTimer: string;
  intervalHolder: any;
  ticketCreated: boolean;

  constructor(private dialog: MatDialog, public roomsService: RoomsService, public chatService: ChatService, public el: ElementRef, private cd: ChangeDetectorRef) {

  }


  ngOnInit(): void {

    this.ticketCreated = false;
    const subCurrentROom = this.roomsService.currentRoom.pipe(
    takeUntil(this.onDestroy$))
      .subscribe((currentRoom: Room) => {
        this.selected = currentRoom && this.room && (currentRoom.id === this.room.id);
      });
    this.avatarSrc = '/assets/images/avatar.png';

  }


  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    

    const dialogRef = this.dialog.open(ConfirmComponent, {
      height: '250px',
      width: '400px',
      data: { warningMessage: "Enter as cloaked user?" }
    });

    const subDialogRef = dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(
      val => {
        if (val !== null && val != undefined) {
          this.roomsService.setCurrentRoom(this.selectedRoom);
          this.chatService.joinRoom(this.selectedRoom, val);
        }
      }
    );
    dialogRef.disableClose = false;
  }

  joinSelectedRoom(curRoom: Room): void {
    this.selectedRoom = curRoom;

    this.roomsService.handleChatOrTicketWindow.next('test' + this.selectedRoom.name);

    if (this.selectedRoom.name == "Lobby") {

      this.roomsService.setCurrentRoom(this.selectedRoom);
      this.chatService.joinRoom(this.selectedRoom, false);
    }
    else
      if (this.selectedRoom.mentorCount <= 0 || this.selectedRoom.currMentorIsPartOfTheRoom) {
        this.chatService.GetPreviousMessagesInProgres.next(true); 
        this.roomsService.setCurrentRoom(this.selectedRoom);
        this.chatService.joinRoom(this.selectedRoom, false);

        if (this.ticketCreated == false) {
          //this.chatService.createTicket(this.selectedRoom.id);
          this.ticketCreated = true;
        }
      }
      else {
        this.openDialog();
      }

  }

  getMentorName(): String {

    if (this.room.lastMessage != undefined && this.room.lastMessage.author.isMentor)
      return this.room.lastMessage.author.userName;
    return "";
  }

  getChatStartTime(): void {


    this.roomTimer = this.GetFormatedTime(this.room.createdAt);
    this.cd.markForCheck();
  }


  getRoomColor(): String {

    let delta = 100;
    if (this.room.createdAt != undefined) {
      let now = new Date().getTime();
      delta = (now - new Date(this.room.createdAt).getTime()) / 1000;
    }

    let rez = '#ffe6be';
    if (this.room.roomStatus == 1 && delta < 30)
      rez = '#ffe6be';
    else
      rez = this.getRoomStatus(this.room.roomStatus);

    return rez;
  }


  getRoomNameColor(): String {

    let delta = 100;
    if (this.room.createdAt != undefined) {
      let now = new Date().getTime();
      delta = (now - new Date(this.room.createdAt).getTime()) / 1000;
    }

    let rez = '#000000';

    if (this.room.roomStatus == 1 && delta < 30)
      rez = '#000000';
    else
      rez = this.getRoomNameStatus(this.room.roomStatus);

    return rez;
  }
  getRoomStatus(tmp): string {

    switch (tmp) {
      case 1: {
        return '#fdc7cd';//red;
      }
      case 2: {
        return '#cecece';///blue(lobby);
      }
      case 3: {
        return '#e0ffed';//green;
      }
      case 4: {
        return '#afddff';//'#D8F9FF';
      }
      default: {
        return '#775fe1';
      }
    }

  }

  getRoomNameStatus(status): string {

    return '#000000';

    // switch (status) {
    //   case 1: {
    //     return '#da3939';//red;
    //   }
    //   case 2: {
    //     return 'grey';//blue(lobby);
    //   }
    //   case 3: {
    //     return '#269454';//green;
    //   }
    //   case 4: {
    //     return '#389eea';//blue;#
    //   }
    //   default: {
    //     return '#775fe1';
    //   }
    // }

  }

  private GetFormatedTime(value: Date): string {

    let now = new Date().getTime();

    let delta = 10;
    let tmp = (now - new Date(value).getTime());
    if (tmp > 0)
      delta = tmp / 1000;

    if (this.getHours(delta) > 0)
      return this.getHours(delta) + ':' + this.getMinutes(delta) + ':' + this.getSeconds(delta);
    else
      return this.getMinutes(delta) + ':' + this.getSeconds(delta);
  }

  private getSeconds(ticks: number) {
    return this.pad(Math.floor(ticks % 60));
  }

  private getMinutes(ticks: number) {
    return this.pad((Math.floor(ticks / 60)) % 60);
  }

  private getHours(ticks: number) {
    return this.pad(Math.floor((ticks / 60) / 60));
  }

  private pad(digit: any) {
    return digit <= 9 ? '0' + digit : digit;
  }



  ngOnDestroy() {

    clearInterval(this.intervalHolder);
    
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }

}
