
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogConfig as MatDialogConfig,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { MatLegacyPaginator as MatPaginator } from "@angular/material/legacy-paginator";
import {
  Component,
  Inject,
  OnInit,
  OnDestroy,
  ViewChild,
  ViewContainerRef,
  ComponentFactoryResolver,
  ChangeDetectorRef
} from '@angular/core';
import {
  MessagesService
} from '../service/messages.service';
import {
  Ticket
} from '../model/ticket';
import {
  RoomsService
} from '../service/rooms.service';
import {
  ChatService
} from '../service/chatservice';
import {
  TicketService
} from '../service/ticket.service';
import {
  Observable
  ,
  BehaviorSubject
  ,
  Subject
  ,
  Subscription
} from 'rxjs';

import {
  User
} from "../model/user";
import {
  TicketFactory
} from "../model/TicketFactory";
import {
  CollectionViewer,
  DataSource
} from "@angular/cdk/collections";

import { NgxSpinnerService } from "ngx-spinner";
import { FindValueSubscriber } from "rxjs/internal/operators/find";
import { base64ToFile, ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';

@Component({
  selector: 'image-upload-modal',
  templateUrl: './image-upload-modal.component.html',
  styleUrls: ['./image-upload-modal.component.css'],
})

export class ImageUploadModalComponent implements OnInit, OnDestroy {
  allItems: Subject<any> = new Subject<any>();
  tickets: any;
  selectedUserId: any;
  getMore: number = 0;
  dataSource: any;
  noContentMessage: string = null;
  private onDestroy$: Subject<void> = new Subject<void>();
  imageChangedEvent: any = '';
  croppedImage: any = '';
  imageName: string = '';
  imageExtensions: string[] = ['jpg', 'jpeg', 'png', 'giff', 'tiff', 'heif', 'heic'];
  imageError: any = '';
  hasError: boolean = false;
  imageSize: string = '';
  constructor(
    private dialogRef: MatDialogRef<ImageUploadModalComponent>, public messagesService: MessagesService,
    public RoomsService: RoomsService, private chatService: ChatService, public ticketService: TicketService,
    @Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog,
    public viewContainerRef: ViewContainerRef, private ref: ChangeDetectorRef,
    private spinnerService: NgxSpinnerService) {
  }

  ngOnInit() {
  }

  save() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }

  fileChangeEvent(event: any): void {
    var extension = event.target.files[0].name.substring(event.target.files[0].name.lastIndexOf('.') + 1).toLowerCase();

    if (this.imageExtensions.includes(extension)) {
      this.imageChangedEvent = event;
      if (event.target.files.length > 0)
        this.imageName = event.target.files[0].name;

      this.imageSize = '';
      this.imageError = '';
      this.hasError = false;
    } else {
      this.imageChangedEvent = null;
      this.imageSize = '';
      this.hasError = true;
      this.imageError = extension + ' is not a supported file format!';
    }

    // var sizeInMB = event.target.files[0].size / (1024 * 1024);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.blob;

    var sizeInMB = (this.croppedImage.size / (1024 * 1024));

    if (sizeInMB > 2) {
      this.imageSize = sizeInMB.toFixed(2);
      this.hasError = true;
      this.imageError = "File size is too big, image size should be less than 2MB!";
    } else {
      this.hasError = false;
      this.imageError = "";
    }
  }

  imageLoaded() {
  }

  cropperReady() {
  }
  loadImageFailed() {
  }

  upload() {
    if (this.hasError)
      return;

    this.croppedImage.name = this.imageName;
    this.dialogRef.close(this.croppedImage);
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  }

}
