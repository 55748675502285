import { Component, OnInit, ViewContainerRef, ComponentFactoryResolver, ComponentRef, ViewChild, Input } from '@angular/core';

import { ChatPageComponent } from '../chat-page/chat-page.component';
import { ChatRoomMembersComponent } from '../chat-room-members/chat-room-members.component';
import { ComponentItem } from './componentItem';

import { CompHolderDirective } from './comp-holder.directive';
import { IframeComponent } from './iframe/iframe.component';
import { ChatCloseFormComponent } from '../chat-close-form/chat-close-form.component';
import { IComponentHolder } from './IComponentHolder';
import { AdminService } from '../../service/admin.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'chat-panel',
  templateUrl: './chat-panel.component.html',
  styleUrls: ['./chat-panel.component.css']
})
export class ChatPanelComponent implements OnInit {
  data: any;
  menuComponents: ComponentItem[];
  appVersion: string  = "";
  private onDestroy$: Subject<void> = new Subject<void>();
  
  @ViewChild(CompHolderDirective,{static:true}) adHost: CompHolderDirective;

  constructor(public viewContainerRef: ViewContainerRef, private componentFactoryResolver: ComponentFactoryResolver, private adminService: AdminService) { }
  ngOnInit() {

    const subGetAppVersion = this.adminService.GetAppVersion().subscribe((data)=>{        
      this.appVersion ="v" + data.toString().replace('"','').replace('"','');
    });

    this.appVersion = "v1.0";
    
    this.menuComponents = [
      new ComponentItem(ChatPageComponent, {}),
      new ComponentItem(IframeComponent, { url: "https://chat.globalmentoring.com/GMSAdmin/login.aspx?mentorapp=1&page=hot_text" }),
      new ComponentItem(IframeComponent, { url: "https://chat.globalmentoring.com/GMSAdmin/login.aspx?mentorapp=1&page=hot_contacts" }),
      new ComponentItem(IframeComponent, { url: "https://chat.globalmentoring.com/iGMS/HotLogins.aspx" }),
      new ComponentItem(IframeComponent, { url: "https://chat.globalmentoring.com/GMSAdmin/login.aspx?mentorapp=1&page=hot_sheet" }),
      new ComponentItem(IframeComponent, { url: "https://chat.globalmentoring.com/GMSAdmin/login.aspx?mentorapp=1&page=bell_techexpert_order&TFN=&Master_Call_ID=0" }),
      new ComponentItem(IframeComponent, { url: "https://chat.globalmentoring.com/GMSAdmin/login.aspx?mentorapp=1&page=techrx_bell_pcprotect_order" }),
      new ComponentItem(IframeComponent, { url: "https://chat.globalmentoring.com/iGMS/TicketHistory.aspx" }),
      new ComponentItem(IframeComponent, { url: "https://chat.globalmentoring.com/GMSAdmin/login.aspx?mentorapp=1&page=apptreport" }),
      new ComponentItem(IframeComponent, { url: "https://chat.globalmentoring.com/GMSAdmin/login.aspx?mentorapp=1&page=smb" })
    ];

    this.loadComponent(0);
  }

  loadComponent(currentAdIndex: number) {

    let componentItem = this.menuComponents[currentAdIndex];

    let componentFactory = this.componentFactoryResolver.resolveComponentFactory(componentItem.component);

    let viewContainerRef = this.adHost.viewContainerRef;
    viewContainerRef.clear();

    let componentRef = viewContainerRef.createComponent(componentFactory);
    (<IComponentHolder>componentRef.instance).data = componentItem.data;
  }

  openChat(event: any): void {
    this.loadComponent(0);
    event.preventDefault();
  }
  openHotText(event: any): void {
    this.loadComponent(1);
    event.preventDefault();
  }
  openHotContacts(event: any): void {
    this.loadComponent(2);
    event.preventDefault();
  }
  openHotLogins(event: any): void {
    this.loadComponent(3);
    event.preventDefault();
  }
  openHotItemManag(event: any): void {
    this.loadComponent(4);
    event.preventDefault();
  }
 
  openBellPopUp(event: any): void {
    this.loadComponent(5);
    event.preventDefault();
  }
  openTicketHistory(event: any): void {
    this.loadComponent(7);
    event.preventDefault();
  }
  openReportCards(event: any): void {
    this.loadComponent(8);
    event.preventDefault();
  }
  public ngOnDestroy(): void {
    this.onDestroy$.next();
  } 
}
