import { MessageAttachment } from "./MessageAttachment.model";


export class MessageAttachmentFactory{

    public static CreateMessageAttachment(attachment: any): MessageAttachment {

        return new MessageAttachment({
            ImageId: attachment.ImageId,
            ImageUri: attachment.ImageUri,
            ThumbnailUri: attachment.ThumbnailUri,
            FileName:attachment.FileName,
            MessageId:attachment.MessageId,
            UserId:attachment.UserId,
            ContainerName:attachment.ContainerName
            
        });
    } 

    public static CreateMessageAttachmentFromList(attachments: any[]): MessageAttachment[] {

        let notes: MessageAttachment[] = new Array<MessageAttachment>();
        if(attachments && attachments.length>0)
        for (let entry of attachments) 
        {
            notes.push(MessageAttachmentFactory.CreateMessageAttachment(entry));
        }

        return notes;
       
    } 

    public static CreateMessageAttachmentLowerCase(attachment: any): MessageAttachment {

        return new MessageAttachment({
            ImageId: attachment.imageId,
            ImageUri: attachment.imageUri,
            ThumbnailUri: attachment.thumbnailUri,
            FileName:attachment.fileName,
            MessageId:attachment.messageId,
            UserId:attachment.userId,
            ContainerName:attachment.containerName
            
        });
    } 

    public static CreateMessageAttachmentFromListLowerCase(attachments: any[]): MessageAttachment[] {

        let notes: MessageAttachment[] = new Array<MessageAttachment>();
        if(attachments && attachments.length>0)
        for (let entry of attachments) 
        {
            notes.push(MessageAttachmentFactory.CreateMessageAttachmentLowerCase(entry));
        }

        return notes;
       
    } 

    
}