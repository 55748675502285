import { Component, Inject, Input } from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

@Component({
  selector: 'notice',
  templateUrl: './notice.component.html',
  styleUrls: ['./notice.component.css']
})

export class NoticeComponent {
  noticeMessage:string = "";

  constructor(
    private dialogRef: MatDialogRef<NoticeComponent>,
    @Inject(MAT_DIALOG_DATA) public data:any) 
    {
    dialogRef.disableClose = true;
    }

  ngOnInit() {
    if (this.data != null){
        if (this.data.noticeMessage != null){
        this.noticeMessage = this.data.noticeMessage;
      }
    }
  }

  save() {
    this.dialogRef.close(true);
  }

  close() {
    this.dialogRef.close(false);
  }

  cancel() {
    this.dialogRef.close(null);
  }
}
