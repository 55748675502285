import { Component, OnInit, ViewChild, Pipe, PipeTransform, Input } from '@angular/core';

import { BrowserModule, DomSanitizer, SafeResourceUrl } from '@angular/platform-browser'

@Component({
  selector: 'app-iframe',
  templateUrl: './iframe.component.html',
  styleUrls: ['./iframe.component.css']
})
export class IframeComponent implements OnInit {
  @Input() data: any;
  url: SafeResourceUrl;
  
  constructor(private sanitizer: DomSanitizer) {    
    
  }

  ngOnInit() {
    this.url = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);     
}

  getUrl() {
    
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.data.url);
  };
}
