<mat-dialog-actions>
  <button class="mat-raised-button close" (click)="close()"> X </button>
</mat-dialog-actions>

<div mat-dialog-body >

 
  <mat-table *ngIf="dataSource" [dataSource]="dataSource" class="mat-elevation-z8">

    <ng-container matColumnDef="ParentTicketNumber" *ngIf="displayColumns=='msp'">
      <mat-header-cell *matHeaderCellDef> TIcket Number </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.ParentTicketNumber}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Title">
      <mat-header-cell *matHeaderCellDef>Ticket Title </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.Title}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="CreatedDateStr">
      <mat-header-cell *matHeaderCellDef> Date Created </mat-header-cell>
      <mat-cell *matCellDef="let element"> {{element.CreatedDateStr}} </mat-cell>
    </ng-container>

    <ng-container matColumnDef="Select">
      <mat-header-cell *matHeaderCellDef> Action </mat-header-cell>
      <mat-cell *matCellDef="let element"> <a (click)="clickedTicket(element)">
          <button mat-stroked-button>
            Select
          </button>
        </a> </mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
    <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
  </mat-table>

</div>