<!-- <div class="col-md-12 col-lg-12 col-sm-12 chat-window-wrapper" style="padding: 0;"> -->
<div class="members-title">Members Online</div>

<!-- <chat-room-member *ngFor="let item of (roomMembers ? roomMembers : []) | async" [thisUser]="item" [contextMenu]="basicMenu" [contextMenuSubject]="item"> </chat-room-member> -->
<chat-room-member class="member" *ngFor="let item of roomMembers  | async" [thisUser]="item" [roomId]="roomId" 
  matTooltip="Click to view more information about this user" matTooltipPosition="left"  (contextmenu)="onContextMenu($event, item)"  >
  <!-- (contextmenu)="onContextMenu($event, item)" -->
  <!-- (contextmenu)="open($event, record); $event.preventDefault();" -->
</chat-room-member>

<div style="visibility: hidden; position: fixed"
    [style.left]="contextMenuPosition.x"
    [style.top]="contextMenuPosition.y"
    [matMenuTriggerFor]="contextMenu">
</div>
 <mat-menu #contextMenu="matMenu">
	<ng-template matMenuContent let-item="item">
    <section class="user-menu">
  	
      <div (click)="removeUser(item)">Remove User</div>
      <div (click)="banUser(item)">Ban User</div>
      
      
      </section>
		<!-- <button mat-menu-item (click)="removeUser(item)">Remove User</button>
		<button mat-menu-item (click)="banUser(item)">Ban User</button> -->
	</ng-template>
</mat-menu> 
<!-- <ng-template #contextMenu let-item>
	<section class="user-menu">
  	
  <div (click)="removeUser(item)">Add Record</div>
  <div (click)="banUser(item)">Edit Record </div>
	
	
	</section>
</ng-template> -->

<!-- <context-menu #oneContextMenu>
  <ng-template contextMenuItem (execute)="removeUser($event.item)">
    <section class="user-menu">
    Remove User {{item}}
  </section>
  </ng-template>
  <ng-template contextMenuItem (execute)="banUser($event.item)">
    <section class="user-menu">
    Ban User {{item}}
  </section>
  </ng-template>
</context-menu> -->




<!-- </div> -->
