import { uuid } from "../home/util/uuid";


/**
 * A User represents an agent that sends messages
 */
export class Client {
  id: number;
  name: string;
  status: string;
  // lastActivity: any;
  isActive: boolean;
  //constructor(id?:string, name?:string, avatar?: string) {
  constructor(obj?: any) {
    this.id = obj && obj.id || '-1';
    if(obj.id == 0){
      this.id = obj.id;
    }
    // this.id = obj.id;
    this.name = obj && obj.name || '';
    this.status = obj && obj.status || '';
    // this.lastActivity = obj && obj.lastActivity || '';
    this.isActive = obj && obj.isActive || false;
    //this.id = uuid();
  }
  // constructor(obj?: any) {
  //   this.id = uuid();
  // }

}