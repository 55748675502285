
import {map} from 'rxjs/operators';
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from '@angular/common/http';
import { AppConfig } from '../AppConfig';

import * as _ from 'lodash';
import { ChatLabelSetup } from "../model/ChatLabels.model";
import { Observable } from "rxjs";

@Injectable()
export class LocalizationService {

  private validLocales: Array<string> = ["en", "fr"];

  constructor(private http: HttpClient,private _httpClient: HttpClient, private config: AppConfig) {
  }

  GetLocalizationData() {
    return this.http.get(this.config.origin + "/api/localization/getlocalizationdata").pipe(
      map(data => _.values(data)));
  }

  GetBannedClients(){
    return this.http.get(this.config.origin + "/api/localization/getBannedClients").pipe(
      map(data => _.values(data)));
  }

  GetCustomLabels(v5id:Number,locale:string): Observable<ChatLabelSetup> {
    return this._httpClient.get(this.config.origin + "/api/localization/getcustomlabels?v5id="+v5id+"&locale="+locale).pipe(
    map((responseData: any) =>{ 
      return responseData;
    }));
  }

  ValidateLocale(locale: any) {
    if (locale == null)
      return "en";

    if (this.validLocales.indexOf(locale) > -1)
      return locale;

    return "en";
  }
}
