<br />

<div id="login_form" class="col-lg-3 col-md-3 col-sm-3 col-xs-3">

        <img [src]="fullImagePath">

        <form class="example-form" name="myform" (ngSubmit)="changePassword(myform.value)" [formGroup]="myform">

            <div class="form-group">
            <label for="password">Password</label>
            <input type="password" class="form-control" formControlName="password" password name="password" required />  
            </div>

            <div class="form-group">
                <label for="password">Confirm Password</label>
                <input type="password" class="form-control" formControlName="confirmPassword" confirmPassword name="confirmPassword" required />            
            </div>

            <div class="form-group">
                <button class="btn btn-primary" [disabled]='!myform.valid'>Save</button>
            </div>

        </form>
    </div>
    