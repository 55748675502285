
import {debounceTime, takeUntil} from 'rxjs/operators';
import { Component, OnInit, ViewChild, ViewContainerRef, Input, NgZone } from '@angular/core';
import {CommonModule} from '@angular/common';
import { Observable ,  ReplaySubject,  Subject } from 'rxjs';
import { RoomsService } from '../../service/rooms.service';
import { Room } from '../../model/room.model';
import { ChatService } from '../../service/chatservice';
import { ConfirmComponent } from '../confirm/confirm.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig } from "@angular/material/legacy-dialog";
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { Howl, Howler } from 'howler';
import { User } from '../../model/user';
import { debug } from 'util';

@Component({
  selector: 'chat-room-notifications',
  templateUrl: './chat-room-notifications.component.html',
  styleUrls: ['./chat-room-notifications.component.css']
})
export class ChatRoomNotificationsComponent implements OnInit {
  @Input() room: Room;
  noUnsweredRooms: Observable<any>;
  toggleButtonName: string = 'Show Less Notifications';
  showMore: boolean = true;
  private onDestroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  constructor(private dialog: MatDialog, public roomsService: RoomsService, public chatService: ChatService, private ngZone: NgZone) {

    this.noUnsweredRooms = roomsService.notRespondedRooms;

    const subNewChatSoundNotification = this.roomsService.newChatSoundNotification.pipe(takeUntil(this.onDestroy$)).pipe(debounceTime(1500)).subscribe((room: Room) => {
      this.ngZone.run(() => {
        
        var userVolume=100;
       
        if(localStorage.getItem("currentUserRoomVolume"))
        {
          userVolume=parseInt(localStorage.getItem("currentUserRoomVolume"));
        }
        var sound = new Howl({
          src: ['assets/audio/online.mp3'],
          volume:userVolume/100
        });

        sound.play();
      });
    })

  }

  ngOnInit() {

  }

  openDialog() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    const dialogRef = this.dialog.open(ConfirmComponent, {
      height: '250px',
      width: '400px',
      data: {warningMessage: "Enter as cloaked user?"}
    });

    const subDialogRef = dialogRef.afterClosed().pipe(takeUntil(this.onDestroy$)).subscribe(
      val => {
        if(val !== null && val != undefined){
          this.chatService.joinRoom(this.room, val);
          this.roomsService.setCurrentRoom(this.room);
        }
      }
    );
  }


  clicked(event: any): void {
    
    this.roomsService.handleChatOrTicketWindow.next('test');

    if (this.room.name == "Lobby") {
      this.chatService.joinRoom(this.room, false);
      
      this.roomsService.setCurrentRoom(this.room);
    }
    else
      if (this.roomsService.getNumOfMentorsInCurrRoom(this.room.id) <= 0) {
        this.chatService.joinRoom(this.room, false);
        
        this.roomsService.setCurrentRoom(this.room);
        //this.chatService.createTicket(this.room.id);
      }
      else {
        this.openDialog();
      }
    event.preventDefault();
  }

  toggle(){
    this.showMore = !this.showMore;

    // CHANGE THE NAME OF THE BUTTON.
    if(this.showMore)  
      this.toggleButtonName = "Show Less Notifications";
    else
      this.toggleButtonName = "Show More Notifications";
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  } 
}
