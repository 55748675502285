export class ClientCourse{
    clientCourseId: Number;
    name: string;
    code: string;

    constructor(obj?: any) {
        this.clientCourseId = obj && obj.clientCourseId;
        this.name = obj && obj.name;
        this.code = obj && obj.code;
    }
}