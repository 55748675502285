import { Component, OnInit, NgZone } from '@angular/core';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
// import { 
//   HubService, 
//   Hub, 
//   HubSubscription, 
//   HubWrapper 
// } from 'ngx-signalr-hubservice';
import { Observable } from 'rxjs';


import { UsersService } from './service/users.service';
import { ChatService } from './service/chatservice';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { JwtInterceptor } from './auth/helpers/jwt.interceptor';
import { Router, ActivatedRoute, NavigationEnd} from '@angular/router';
import { filter } from 'rxjs/operators';
import {Location} from '@angular/common';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',  
  styleUrls: ['./app.component.css']
})

export class AppComponent{

  templateParam: string = "false";
  clientParam: string = "";

  constructor(
    private activatedRoute: ActivatedRoute, private router: Router, private location: Location) {
      
  }

  ngOnInit(): void {
    this.router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe(event => {

      if(event.url.includes("/client?")){
        this.templateParam = "client";
      }

      if(event.url.includes("/maintenance")){
        this.templateParam = "maintenance";
      }
       
    });

    this.activatedRoute.queryParams.subscribe(params => {
      if (params['isWidget']) {
        this.templateParam = "true";
      }
    });
  }

  
  GetBackgroundColor(){
    let currentUserAdmin = JSON.parse(localStorage.getItem('currentAdminUser'));
    let clientType = localStorage.getItem('clientType');

    if (clientType == null && currentUserAdmin != null){
      return "#0061AA"; // mentor chat page (after auth)
    }
    else{
      return "#f5f5f5"; 
    }
  }
}
