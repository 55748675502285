<br /><br />

<form class="example-form" name="myform" (ngSubmit)="changePassword(myform.value)" [formGroup]="myform">

    <mat-form-field class="example-full-width">
      <input type="password" class="input" matInput placeholder="Current Password" formControlName="currentPassword" required currentPassword>
    </mat-form-field>
     
    <br />

    <mat-form-field class="example-full-width">
        <input type="password" class="input" matInput placeholder="New Password" formControlName="newPassword" required #newPassword  >
    </mat-form-field>

    <br />

     <mat-form-field class="example-full-width">
        <input type="password" class="input" matInput placeholder="Confirm New Password" formControlName="confirmNewPassword" required #confirmNewPassword>
     </mat-form-field>

     <div class="button-row">
            <button mat-stroked-button [disabled]='!myform.valid'>Save</button>
    </div>

</form>