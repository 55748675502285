export class ChatUser {
    id: string;
    name: string;
    displayName:string;
    email: string;
    logo: string;
    isMentor: boolean;
    clientType:number;
    isAtDown: boolean;
    pendingActivation: number;
    b1number: string;
    isBanned: boolean;
    role:string;

    constructor(obj?: any) {
        this.id = obj && obj.id || '-1';
        this.name = obj && obj.name || "";
        this.displayName = obj && obj.displayName || "";
        this.email = obj && obj.email || "";
        this.logo = obj && obj.logo || "";
        this.isMentor = obj && obj.isMentor || false;
       
        this.isAtDown = obj && obj.isAtDown || false;
        this.pendingActivation = obj && obj.pendingActivation || 2;
        this.b1number = obj && obj.b1number || "";
        this.isBanned = obj && obj.isBanned || false;
        this.role  = obj && obj.role || "";
        this.clientType = obj && obj.clientType || 0;
    }

    public static createClient(clientVM: any): ChatUser {
        
        const me: ChatUser = new ChatUser({
            id: clientVM.Id,
            name: clientVM.Name,
            displayName: clientVM.DisplayName,
            email: clientVM.Email,
            logo: clientVM.Logo,
            isMentor: clientVM.IsMentor,
            clientType: clientVM.ClientType,
            isAtDown: clientVM.IsAtDown,
            pendingActivation: clientVM.PendingActivation,
            b1number: clientVM.B1number,
            isBanned: clientVM.isBanned,
            role:clientVM.Role
            
        });
        return me;
    }
}
