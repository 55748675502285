<mat-dialog-actions>
    <button class="mat-raised-button close" (click)="close()"> X </button>
</mat-dialog-actions>

<h2 mat-dialog-title *ngIf='currentTranscript'>Chat transcript</h2>

<!-- <div class="mat-dialog-question">TRANSCRIPT TABLE</div> -->

<div mat-dialog-body>
    <!-- <p>THIS WILL BE THE TABLE</p> -->
    <ngx-spinner [timeout]="10000" type="ball-clip-rotate"> </ngx-spinner>
    <p *ngIf='noContentMessage'>{{noContentMessage}}</p>
    <p *ngFor='let item of currentTranscript' ><span class="mrg-right">{{item.author.Name}}</span><span class="mentor-flag" *ngIf="item.author.IsAdmin"> (Mentor)</span><span class="mentor-flag" *ngIf="!item.author.IsAdmin"> (EndUser)</span><span id="curr-transcript-sentAt">{{item.sentAt | date: 'M/d/yy, h:mm a - z'}}</span>  <span [ngClass]="(item.orgMsgType==3)?'private-message':'public-message'">{{item.text}}</span> </p>

</div>