<h2 mat-dialog-title>Set Volume</h2>


<div mat-dialog-body>
  
  <div class="form-group">  
    <label style="width:30%">Chat Volume</label>
    <mat-slider  [(ngModel)]="volume" ngDefaultControl   min="0" max="100" step="1" showTickMarks discrete [displayWith]="formatLabel">
      <input matSliderThumb [value]="volume" >
    </mat-slider>
  </div>
  <div class="form-group">
    <label style="width:30%">Notification Volume</label>
    <mat-slider  [(ngModel)]="roomVolume" ngDefaultControl   min="0" max="100" step="1" showTickMarks discrete [displayWith]="formatLabel">
      <input matSliderThumb [value]="roomVolume" >
    </mat-slider>
  </div> 
    <mat-dialog-actions>
      <button type="submit" class="btn-chat btn-contact-add" (click)="save()">Save</button>
      <button class="btn-chat btn-contact-cancel" (click)="cancel()">Cancel</button>
    </mat-dialog-actions>
</div>
