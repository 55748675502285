
import {combineLatest, map, debounceTime} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable, Operator } from 'rxjs';


import * as _ from 'lodash';
import { Room, RoomGroup } from '../model/room.model';
import { MessagesService } from './messages.service';
import { Message } from '../model/message.model';
import { UsersService } from './users.service';
import { User } from '../model/user';
import { UserFactory } from '../model/userFactory';
import { RoomFactory } from '../model/RoomFactory';

import { MessageType } from '../model/messageType';
import { RoomType } from '../model/RoomType';
import { debug } from 'util';
import { ChatUser } from '../model/chatuser.model';





interface IRoomMemberOperation extends Function {
  (currRoomMembers: User[]): User[];
}


@Injectable()
export class RoomsService {
  // `rooms` is a observable that contains the most up to date list of rooms
  //rooms: Observable<{ [key: string]: Room }>;

  clickAllTranscript: Subject<Array<Room>> = new Subject<Array<Room>>();
  allTranscriptsLen: Subject<number> = new Subject<number>();
  setCurrentPage: Subject<number> = new Subject<number>();
  currentTranscriptUser: Subject<any> = new Subject<any>();

  checkIfClosePopupNeeded: Subject<boolean>=new Subject<boolean>();
  loadNewComponent: Subject<boolean>= new Subject<boolean>();
  preselectTab: Subject<string>=new Subject<string>();


  //roomUsers: Observable<User[]> = new Observable<User[]>();

  //my rooms is a observable that contains the most up to date list of rooms in which mentor is part of
  //myRooms: Observable<{ [key: string]: Room }>;

  notRespondedRooms: Observable<Room[]>;
  notRespondedRoomsCache: Room[] = [RoomFactory.createEmptyRoom()];

  handleCloseRoomSubject: Subject<string> = new Subject<string>();
  newChatSoundNotification: Subject<any> = new Subject<any>();

  handleChatOrTicketWindow: Subject<any> = new Subject<any>();
  // `orderedRooms` contains a newest-first chronological list of rooms
  orderedRooms: Observable<Room[]>;
  orderedMyRooms: Observable<Room[]>;

  //groupedRooms: Observable<{ [key: string]: Room[] }>;
  groupedRooms: Observable<RoomGroup[]>;
  groupedMyRooms: Observable<RoomGroup[]>;

  closedRooms: Observable<Room[]>;
  roomAsArrayCache: Room[];
  // abv
  // `currentRoom` contains the currently selected room
  private previousSetRoom:Room=null;
  currentRoom: Subject<Room> =
    new BehaviorSubject<Room>(new Room());
  private blockSettingCurrentRoom:boolean=false;
  private isAllSessionsSelected:boolean=false;
  currentRoomMsgLoaded: Subject<string[]> = new BehaviorSubject<string[]>(['','']);

  newRoom: Subject<Room> = new Subject<Room>();

  clearRoomMembers: Subject<any> = new Subject<any>();
  createRoomMembers: Subject<any> = new Subject<any>();
  updateRoomMembers: Subject<any> = new Subject<any>();
  refreshRoomMembers: Subject<User[]> = new Subject<User[]>();

  // `currentRoomMessages` contains the set of messages for the currently
  // selected room
  currentRoomMessagesForClient: Observable<Message[]>;
  currentRoomMessages: Observable<Message[]>;
  currentRoomMembers: Observable<User[]>;


  ////////////////////////////
  updateGroupByName: Subject<any> = new BehaviorSubject<any>(null);
  groupRoomsByName: RoomGroup[] = [];

  updateMyGroupByName: Subject<any> = new BehaviorSubject<any>(null);
  groupMyRoomsByName: RoomGroup[] = [];




  constructor(public messagesService: MessagesService, public userService: UsersService) {

    const roomsTmp: { [key: string]: Room } = {};
    let usrsPerRoom: Map<string, User[]> = new Map<string, User[]>();

    const compareFn = (a, b) => {
      if (a.session > b.session) {
        return 1;
      } else if (b.session > a.session) {
        return -1;
      } else {
        return 0;
      }
    };
    const compareFnDesc = (a, b) => {
      if (a.session > b.session) {
        return -1;;
      } else if (b.session > a.session) {
        return 1;;
      } else {
        return 0;
      }
    };

    ///////////////////////////////////////////////////////////////////////////////////////

    // this.groupedRooms = this.updateGroupByName.map(() => {
    //   console.log('REFRESH GROUP BY NAME');
    //   //return _.cloneDeep(this.groupByName);
    //   return _.cloneDeep(_.sortBy(this.groupRoomsByName, (t: RoomGroup) => t.type));
    // });

    // messagesService.newMessages.subscribe(msg => {
    //   let newRoom: Room = msg.room;
    //   var index = this.groupRoomsByName.findIndex(s => s.type == msg.room.type);
    //   if (index == -1) {
    //     newRoom.firstMessage = msg;
    //     newRoom.lastMessage = msg;
    //     newRoom.roomStatus = msg.room.roomStatus;
    //     if (msg.msgType !== MessageType.MarkAsPendingClosed)
    //       this.groupRoomsByName.push(new RoomGroup(newRoom.type, newRoom));
    //   }
    //   else {

    //     var roomIndex = this.groupRoomsByName[index].rooms.findIndex(r => r.id == newRoom.id);

    //     if (roomIndex === -1) {
    //       if (msg.msgType !== MessageType.MarkAsPendingClosed) {
    //         newRoom.firstMessage = msg;
    //         newRoom.lastMessage = msg;
    //         newRoom.roomStatus = msg.room.roomStatus;
    //         this.groupRoomsByName[index].rooms.push(newRoom);
    //       }
    //     }
    //     else {
    //       if (index !== -1 && roomIndex !== -1) {
    //         if (msg.author.isMentor)
    //           this.groupRoomsByName[index].rooms[roomIndex].lastMessage = msg.room.lastMessage;
    //         this.groupRoomsByName[index].rooms[roomIndex].roomStatus = msg.room.roomStatus;
    //       }
    //     }

    //   }
    //   this.updateGroupByName.next(true);
    // });





    this.groupedRooms = messagesService.messages.pipe(debounceTime(500),
      map((messages: Message[]) => {

        const rooms: { [key: string]: Room } = {};

        let groupByName: RoomGroup[] = [];

        //console.log("THROTTLE TIME");        
        messages.sort(compareFn).forEach(function (msg) {
          let newRoom: Room = new Room();
          newRoom = msg.room;
          var index = groupByName.findIndex(s => s.type == msg.room.type);
          if (index == -1) {
            newRoom.firstMessage = msg;
            newRoom.lastMessage = msg;
            newRoom.roomStatus = msg.room.roomStatus;
            newRoom.session = msg.room.session;
            newRoom.language = msg.room.language;
            newRoom.mentorCount=msg.room.mentorCount;
            if (msg.author.isMentor)
              newRoom.lastActiveMentor = msg.author.name.toString();
            if (msg.msgType !== MessageType.MarkAsPendingClosed && userService.checkMentorRole(newRoom.type))
              groupByName.push(new RoomGroup(newRoom.type, newRoom));
          }
          else {

            var roomIndex = groupByName[index].rooms.findIndex(r => r.id == newRoom.id);

            if (roomIndex === -1) {
              if (msg.msgType !== MessageType.MarkAsPendingClosed && userService.checkMentorRole(newRoom.type)) {
                newRoom.firstMessage = msg;
                newRoom.lastMessage = msg;
                newRoom.roomStatus = msg.room.roomStatus;
                newRoom.session = msg.room.session;
                newRoom.language = msg.room.language;
                newRoom.mentorCount=msg.room.mentorCount;
                if (msg.author.isMentor)
                  newRoom.lastActiveMentor = msg.author.name.toString();
                groupByName[index].rooms.push(newRoom);
              }
            }
            else {
              if (index !== -1 && roomIndex !== -1) {
                newRoom.language = msg.room.language;
                groupByName[index].rooms[roomIndex].session = msg.room.session;
                groupByName[index].rooms[roomIndex].roomStatus = msg.room.roomStatus;
                groupByName[index].rooms[roomIndex].mentorCount = msg.room.mentorCount;

                if (msg.author.isMentor) {
                  groupByName[index].rooms[roomIndex].lastMessage = msg.room.lastMessage;
                  groupByName[index].rooms[roomIndex].lastActiveMentor = msg.author.name.toString();
                }

              }
            }

          }

        });


        let currentUser = JSON.parse(localStorage.getItem('currAuthor'));

        

        groupByName.sort(compareFn).forEach(element => {
          element.rooms.forEach(room => {
            let count = messages.filter(m => m.room.id === room.id);
            if (room.name === "Lobby")
              room.roomStatus = 2;
            let mentorsCount: string[] = [];
            messages.filter(m => m.room.id === room.id && m.author.isMentor && m.uninhibited === false).forEach(element => {
              if (mentorsCount.findIndex(m => m === element.author.id) === -1) {
                mentorsCount.push(element.author.id);
              }
            });

            //room.mentorCount = mentorsCount.length;
            room.currMentorIsPartOfTheRoom = false;
            if (mentorsCount.findIndex(authorId => authorId === currentUser.id) !== -1)
              room.currMentorIsPartOfTheRoom = true;

              var msgSorted=count.sort(compareFn);
        
              var msgMentor=msgSorted.filter(x=>x.author.isMentor)[0];
              if(msgMentor)
                room.lastActiveMentor = msgMentor.author.name.toString();
          });
        });

        
        
        return _.cloneDeep(_.sortBy(groupByName, (t: RoomGroup) => t.type));
      }),);

    ///////////////////////////////////////////////////////////////////////////////////////////////////////


    // this.groupedMyRooms = this.updateMyGroupByName.map(() => {
    //   console.log('REFRESH GROUP BY NAME');
    //   //return _.cloneDeep(this.groupByName);
    //   return _.cloneDeep(_.sortBy(this.groupMyRoomsByName, (t: RoomGroup) => t.type));
    // });

    // messagesService.newMessages.subscribe(msg => {
    //   let newRoom: Room = msg.room;
    //   var index = this.groupMyRoomsByName.findIndex(s => s.type == msg.room.type);
    //   if (index == -1) {
    //     newRoom.firstMessage = msg;
    //     newRoom.lastMessage = msg;
    //     newRoom.roomStatus = msg.room.roomStatus;
    //     if (msg.msgType !== MessageType.MarkAsPendingClosed)
    //       this.groupMyRoomsByName.push(new RoomGroup(newRoom.type, newRoom));
    //   }
    //   else {

    //     var roomIndex = this.groupMyRoomsByName[index].rooms.findIndex(r => r.id == newRoom.id);

    //     if (roomIndex === -1) {
    //       if (msg.msgType !== MessageType.MarkAsPendingClosed) {
    //         newRoom.firstMessage = msg;
    //         newRoom.lastMessage = msg;
    //         newRoom.roomStatus = msg.room.roomStatus;
    //         this.groupMyRoomsByName[index].rooms.push(newRoom);
    //       }
    //     }
    //     else {
    //       if (index !== -1 && roomIndex !== -1) {
    //         if (msg.author.isMentor)
    //           this.groupMyRoomsByName[index].rooms[roomIndex].lastMessage = msg.room.lastMessage;
    //         this.groupMyRoomsByName[index].rooms[roomIndex].roomStatus = msg.room.roomStatus;
    //       }
    //     }

    //   }
    //   this.updateMyGroupByName.next(true);
    // });


    this.groupedMyRooms = messagesService.messages.pipe(debounceTime(500),
      map((messages: Message[]) => {
        const rooms: { [key: string]: Room } = {};

        let groupByName: RoomGroup[] = [];
        let currentUser = JSON.parse(localStorage.getItem('currAuthor'));


        messages.sort(compareFn).forEach(function (msg) {
          let newRoom: Room = msg.room;

          var index = groupByName.findIndex(s => s.type == msg.room.type);
          if (index == -1) {
            newRoom.firstMessage = msg;
            newRoom.lastMessage = msg;
            if (msg.msgType !== MessageType.MarkAsPendingClosed && !msg.uninhibited && msg.author.id == currentUser.id && userService.checkMentorRole(newRoom.type))
              groupByName.push(new RoomGroup(newRoom.type, newRoom));
          }
          else {

            var roomIndex = groupByName[index].rooms.findIndex(r => r.id == newRoom.id);

            if (roomIndex == -1) {
              if (msg.msgType !== MessageType.MarkAsPendingClosed && !msg.uninhibited && msg.author.id == currentUser.id && userService.checkMentorRole(newRoom.type)) {
                newRoom.firstMessage = msg;
                newRoom.lastMessage = msg;
                groupByName[index].rooms.push(newRoom);
              }
            }
            else {
              if (msg.author.isMentor)
                groupByName[index].rooms[roomIndex].lastMessage = msg.room.lastMessage;
              groupByName[index].rooms[roomIndex].roomStatus = msg.room.roomStatus;
            }

          }


        });



        groupByName.sort(compareFn).forEach(element => {
          element.rooms.forEach(room => {
            let count = messages.filter(m => m.room.id === room.id && m.author.isMentor);
            if (room.name === "Lobby")
              room.roomStatus = 2;
            let mentorsCount: string[] = [];
            messages.filter(m => m.room.id === room.id && m.author.isMentor).forEach(element => {
              if (mentorsCount.findIndex(m => m === element.author.id) === -1) {
                mentorsCount.push(element.author.id);
              }
            });

            //room.mentorCount = room.mentorsCount;
            if (mentorsCount.findIndex(authorId => authorId === currentUser.id) !== -1)
              room.currMentorIsPartOfTheRoom = true;
          });
        });

        return _.cloneDeep(_.sortBy(groupByName, (t: RoomGroup) => t.type));
        //return groupByName;
      }),);






    this.notRespondedRooms = messagesService.messages.pipe(debounceTime(500),
      map((messages: Message[]) => {
        const rooms: { [key: string]: Room } = {};

        messages.forEach(function (message) {
          let lastRoomStatus = message.room.roomStatus;
          if (message.msgType !== MessageType.MarkAsPendingClosed && message.room.roomStatus===1
            && userService.checkMentorRole(message.room.type)) {
            lastRoomStatus = message.room.roomStatus;            
            rooms[message.room.id] = rooms[message.room.id] || message.room;
            rooms[message.room.id].lastMessage = message;
            rooms[message.room.id].roomStatus = 1;

            if (rooms[message.room.id].firstMessage == undefined)
              rooms[message.room.id].firstMessage = message;
          }
        });

        let roomsAsArray: Room[] = [];
        Object.keys(rooms).map(itm => rooms[itm]).forEach(val => {
          //Object["values"](rooms).forEach(val => {

          let cntUninhibited = messages.filter(msg => msg.author.isMentor && msg.uninhibited == true && msg.room.id == (<Room>val).id).length;
          let cntMentors = messages.filter(msg => msg.author.isMentor && msg.room.id == (<Room>val).id).length;

          if (cntUninhibited === cntMentors)
            roomsAsArray.push(<Room>val);
        });


        let startNotification = false;
        roomsAsArray.forEach(element => {
          var rez = this.notRespondedRoomsCache.find(r => r.id === element.id);

          if (rez === undefined && !startNotification) {
            startNotification = true;
            this.newChatSoundNotification.next(true);
          }
        });

        this.notRespondedRoomsCache = roomsAsArray;

        return _.sortBy(roomsAsArray, (t: Room) => t.session);
        //return roomsAsArray;
      }),);


    this.closedRooms = messagesService.messages.pipe(
      map((messages: Message[]) => {

        const rooms: { [key: string]: Room } = {};
        let currentUserAdmin = JSON.parse(localStorage.getItem('currentAdminUser'));
        let currentUser = JSON.parse(localStorage.getItem('currentUser'));
        
          messages.filter(m => m.msgType === MessageType.MarkAsPendingClosed && 
            userService.checkMentorRole(m.room.type)).map((message: Message) => {
            rooms[message.room.id] = rooms[message.room.id] || message.room;
          });
        // else
        //   messages.filter(m => m.author.id == currentUser.id && m.closedByUserId == currentUser.id).map((message: Message) => {
        //     if (message.msgType === MessageType.MarkAsPendingClosed) {
        //       rooms[message.room.id] = rooms[message.room.id] || message.room;
        //     }
        //   });
        return _.sortBy(rooms, (t: Room) => t.session);
      }));

      // this.currentRoomMembers=this.refreshRoomMembers.map((users: User[]) => {
      //   return users;
      // });

      this.currentRoomMembers=this.currentRoom.pipe(
      combineLatest(this.updateRoomMembers, (currentRoom: Room, data: any) => {
        const users: User[] = [];;

        if(data.roomId==currentRoom.id){
          data.chatUsers.forEach(userVM => {
            if(currentRoom.id)
            users.push( UserFactory.createUser(userVM))
          });
  
          return _.cloneDeep(_.sortBy(users, (t: User) => t.name));
        }
      }));

    
      
      

    

    // this.currentRoomMembers = this.messagesService.messages
    //   .combineLatest(this.currentRoom, (messages: Message[], currentRoom: Room) => {
    //     const users: User[] = [];;


    //     messages.filter(m => m.room.id == currentRoom.id && !m.uninhibited).map((message: Message) => {

    //       var userIdx = users.findIndex((elt) => elt.id === message.author.id);
    //       if (userIdx === -1) {
    //         users.push(message.author);
    //       }
    //       else {
    //         let author: User = users[userIdx];
    //         if (author.isClocked == false)
    //           author.isClocked = message.author.isClocked;
    //         author.status = message.author.status;
    //         users.splice(userIdx, 1, author);
    //       }
    //     });

    //     return _.cloneDeep(_.sortBy(users, (t: User) => t.name));
    //   });






    this.currentRoomMessages = this.currentRoom.pipe(
      combineLatest(messagesService.messages, (currentRoom: Room, messages: Message[]) => {

        if (currentRoom && messages.length > 0) {
          return _.chain(_.sortBy(messages, (t: Message) => t.sentAt))
            .filter((message: Message) =>
              //(message.room.id === currentRoom.id && message.msgType !== MessageType.Notification))
              (message.room.id === currentRoom.id && message.text!==null))
            .map((message: Message) => {
              //console.log("add msgs to current room");
              message.isRead = true;
              return message;
            })
            .value();
        } else {
          return [];
        }
      }));



    this.currentRoomMessagesForClient = this.currentRoom.pipe(
      combineLatest(messagesService.messages, (currentRoom: Room, messages: Message[]) => {
        
        if (currentRoom && messages.length > 0) {
          return _.chain(_.sortBy(messages, (t: Message) => t.sentAt))
            .filter((message: Message) =>
              (message.room.id === currentRoom.id && message.msgType !== MessageType.Notification
                && message.msgType !== MessageType.MentorSystem
                && message.msgType !== MessageType.SystemPrivate
                 && message.msgType !== MessageType.MarkAsPendingClosed && message.orgMsgType !== MessageType.Private

                 &&!(message.msgType==MessageType.System && message.text.includes("Your ticket number"))))
            .map((message: Message) => {
              //console.log("add msgs to current room");
              message.isRead = true;
              return message;
            })
            .value();
        } else {
          return [];
        }
      }));



    this.currentRoom.subscribe(this.messagesService.markRoomAsRead);



  };

  ngOnInit() {

  }




  getNumOfMentorsInCurrRoom(roomId: string): number {
    return 0;

    // let numOfMentorsInCurrentRoom: number = 0;
    // let isMentorMember = false;

    // this.currentRoom
    //   .combineLatest(this.messagesService.messages,
    //     (currentRoom: Room, messages: Message[]) => [currentRoom, messages])
    //   .subscribe(([currentRoom, messages]: [Room, Message[]]) => {
    //     let mentorsInRoom: string[] = [];
    //     numOfMentorsInCurrentRoom =
    //       _.reduce(
    //         messages,
    //         (sum: number, m: Message) => {
    //           const messageIsInCurrentRoom: boolean = m.room && (roomId === m.room.id);

    //           if (messageIsInCurrentRoom && !m.uninhibited && m.msgType != MessageType.MarkAsPendingClosed) {

    //             let currUser = JSON.parse(localStorage.getItem('currAuthor'));

    //             if (m.author.isMentor && (mentorsInRoom.indexOf(m.author.id) == -1)) {
    //               if (m.author.id === currUser.id)
    //                 isMentorMember = true;
    //               mentorsInRoom.push(m.author.id);
    //               sum = sum + 1;
    //             }
    //           }
    //           return sum;
    //         },
    //         0);

    //   });
    // if (isMentorMember)
    //   return -1;
    // else
    //   return numOfMentorsInCurrentRoom;
  }


  setBlockSettingCurrentRoom(value:boolean)
  {
    this.blockSettingCurrentRoom=value;
  }

  setIsAllSessionsClicked(value:boolean)
  {
    this.isAllSessionsSelected=value;
  }

  getIsAllSessionsClicked()
  {
     return this.isAllSessionsSelected;
  }

  unblockSetCurrentRoom(): void {

    this.setBlockSettingCurrentRoom(false);
    if(this.previousSetRoom!=null)
    this.setCurrentRoom(this.previousSetRoom);
  }

  getPreviousRoomSet():Room
  {
    if(this.previousSetRoom!=null)
    return this.previousSetRoom;

    return null;
  }

  setCurrentRoom(newRoom: Room): void {

    this.previousSetRoom=newRoom;
    

    if(!this.blockSettingCurrentRoom)
      this.currentRoom.next(newRoom);
  }


  showAllTranscript(rooms: Array<Room>, fullLen: number, page: number, user: any): void {
    this.currentTranscriptUser.next(user);
    this.allTranscriptsLen.next(fullLen);
    this.setCurrentPage.next(page);
    this.clickAllTranscript.next(rooms);
  }

public  checkMentorRoleForCloseChat(type:string):Boolean
{
  if(+type==0) // soba tip lobby
    return true;

  let currentUser = JSON.parse(localStorage.getItem('currAuthor'));


  if(type.toUpperCase() == 'TELCO' && currentUser.role.toUpperCase() == 'TELCO ADMIN')
    return true;

  if(type.toUpperCase() == 'MSP' && currentUser.role.toUpperCase() == 'MSP ADMIN')
    return true;

  
  if(currentUser.role.toUpperCase() == 'ADMIN')
    return true;

  return false;
}

}

export const roomsServiceInjectables: Array<any> = [
  RoomsService
];
