
<div id="toggleReg" class="{{paramWidget}}" *ngIf="pageTranslated">
    <div class="regHeader" [style.background-color]="chatColor">
        <img class="partner-logo" src = "{{logoFullPath}}" onerror="this.onerror=null; this.src='assets/images/logo.png'">
    </div>
    <div class="regBody">
    <form name="form" (ngSubmit)="disableSignInButton || registerNewUser(myform.value)" [formGroup]="myform">
           
        <div class="register-form" *ngIf=(data)>
        
            <!--FNAME-->
                <div class="form-group">
                    <label for="firstName">{{pageTranslation.FirstName}}</label>
                    <input type="text"
                    class="form-control"
                    formControlName="firstName" 
                    required 
                    placeholder="{{data.firstName}}"
                    readonly="readonly"
                    />

                    <div class="validation-errors">
                            <div class="error-message" *ngIf="myform.get('firstName').hasError('required') && (myform.get('firstName').dirty || myform.get('firstName').touched) || (isValidFormSubmitted == false && myform.get('firstName').hasError('required'))">Firstname is required</div>
                        </div>
                </div>

                <!--LNAME-->
                <div class="form-group">
                    <label for="lastName">{{pageTranslation.LastName}}</label>
                    <input type="text"
                    class="form-control"
                    formControlName="lastName" 
                    required 
                    placeholder="{{data.lastName}}"
                    readonly="readonly"
                    />

                    <div class="validation-errors">
                            <div class="error-message" *ngIf="myform.get('lastName').hasError('required') && (myform.get('lastName').dirty || myform.get('lastName').touched) || (isValidFormSubmitted == false && myform.get('lastName').hasError('required'))">Lastname is required</div>
                        </div>
                </div>

                <!--EMAIL-->
            <div class="form-group">
                <label for="email">{{pageTranslation.Email}}</label>
                <input type="email"
                class="form-control"
                formControlName="email" 
                required 
                placeholder="{{data.email}}"
                readonly="readonly"
                />

                <div class="validation-errors">
                        <div class="error-message" *ngIf="myform.get('email').hasError('pattern') && (myform.get('email').dirty || myform.get('email').touched)">Please enter a valid email</div>
                        <div class="error-message" *ngIf="myform.get('email').hasError('required') && (myform.get('email').dirty || myform.get('email').touched) || (isValidFormSubmitted == false && myform.get('email').hasError('required'))">Email is required</div>
                    </div>
            </div>

            <!--VOUCHER-->
            <div class="form-group">
                <label for="voucher">{{pageTranslation.Voucher}}</label>
                <input type="text" class="form-control voucherfield" name="voucher" formControlName="voucher" placeholder="{{data.voucher}}" required/>

                <div class="validation-errors">
                  <div class="error-message" *ngIf="myform.get('voucher').hasError('pattern') && (myform.get('voucher').dirty || myform.get('voucher').touched)">{{pageTranslation.ValidVoucher}}<br>{{pageTranslation.VoucherFormat}}</div>
                   <div class="error-message" *ngIf="myform.get('voucher').hasError('required') && (myform.get('voucher').dirty || myform.get('voucher').touched) || (isValidFormSubmitted == false && myform.get('voucher').hasError('required'))">{{pageTranslation.VoucherRequired}}</div>
                </div>
            </div>

            <!--PNUMBER-->
            <!--<div class="form-group">
                <label for="phone">Phone</label>
                <input type="text" class="form-control" name="phone" formControlName="phone" />

                <div class="validation-errors">
                        <div class="error-message" *ngIf="myform.get('phone').hasError('pattern') && (myform.get('phone').dirty || myform.get('phone').touched)">Please enter a valid phone number.<br>e.g. 0000000000, 111-111-1111, (222)-222-2222, (333)3333333</div>
                        <div class="error-message" *ngIf="myform.get('phone').hasError('required') && (myform.get('phone').dirty || myform.get('phone').touched) || (isValidFormSubmitted == false && myform.get('phone').hasError('required'))">Phone number is required</div>
                    </div>
            </div>-->

            <br />

            <div class="form-group">
              <button class="btn btn-primary" [ngClass]="{ 'disabled': disableSignInButton }" [style.background-color]="chatColor">{{pageTranslation.RegisterEnterChat}}</button>

            </div>
        </div>

    </form>
    <h1 class="cred">{{pageTranslation.PoweredByGlobalMentoring}}</h1>
    
</div>
</div>
