import { AfterViewInit, Component, OnInit, OnDestroy, Input, Injector, ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable ,  BehaviorSubject } from 'rxjs';
import { RegisterProcessComponent } from '../register-process';
import { AlertService } from '../../service/alert.service';
import { UsersService } from '../../service/users.service';
import { MappingFields } from '../../model/MappingFields.model';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule, Validators } from '@angular/forms';
import { User } from '../../model/user';
import { Message } from '../../model/message.model';
import { MessagesService } from '../../service/messages.service';
import { Room } from '../../model/room.model';
import { ChatService } from '../../service/chatservice';
import { RoomsService } from '../../service/rooms.service';
import {MatLegacyInputModule as MatInputModule} from "@angular/material/legacy-input";
import {MatLegacyPaginatorModule as MatPaginatorModule} from "@angular/material/legacy-paginator";
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";
import {MatSortModule} from "@angular/material/sort";
import {MatLegacyTableModule as MatTableModule} from "@angular/material/legacy-table";
import {MatLegacyFormFieldModule as MatFormFieldModule, MatLegacyFormField as MatFormField} from "@angular/material/legacy-form-field";
import {MatLegacyButtonModule as MatButtonModule} from "@angular/material/legacy-button";
import { LocalizationService } from '../../service/localization.service';
import { MentorSpinnerService } from '../../service/mentorspinner.service';

import {CustomizationService} from '../../service/customization.service';
import { AppConfig } from '../../AppConfig';

@Component({
  selector: 'app-register-chat-tti',
  templateUrl: './register-chat-tti.component.html',
  styleUrls: ['./register-chat-tti.component.css']
})
export class RegisterChatTtiComponent implements RegisterProcessComponent {

  @Input() data: any;

  model: any = {};
  showSelected: boolean = false;
  messageText: string;
  draftMessage: Message;
  messages: Observable<any>;
  myform: UntypedFormGroup;
  currentRoom: Room;
  userObject: User[];
  isChatInit: boolean;
  paramWidget: boolean = false;
  showFormErrors: boolean = false;
  emailPattern = "[a-zA-Z0-9.-_-]{1,}@[0-9a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  isValidFormSubmitted = null;
  disableSignInButton: boolean = false;
  public logoFullPath: string = 'assets/images/logo.png';
  public colorFileFullPath: string = '';
  public chatColor: string = '#0061AA';
  public clientIdParam: string; public clientTypeParam: string; public clientSubType: string = "Central";
  //localization - s
  private pageName = "RegisterChatTtiComponent";
  public pageTranslation: any = "";
  public pageTranslated = new BehaviorSubject<string>("");
  //localization - e

  constructor(private customizationService: CustomizationService, private spinnerService: MentorSpinnerService, private router: Router, private activatedRoute: ActivatedRoute, private localizationService: LocalizationService,
    private userService: UsersService, private alertService: AlertService, fb: UntypedFormBuilder, injector: Injector, public msgService: MessagesService, public chatService: ChatService, public roomsService: RoomsService, private config: AppConfig) {
      let clientId = userService.getClientIdParam();
      let clientType = userService.getClientTypeParam();
      
      this.logoFullPath =customizationService.getNoCacheImage(this.config.logoURL + clientId.toString() + '.png');
      
      this.colorFileFullPath = this.config.logoURL + this.userService.getClientIdParam().toString() + '.txt';

    this.chatColor = customizationService.getPersonalizedColor(this.colorFileFullPath);

    this.SetLocalization();

    this.clientIdParam = clientId;
    this.clientTypeParam = clientType;
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['isWidget']) {
        this.paramWidget = true;
      }


    });
    this.draftMessage = new Message();
    this.data = false;

    this.myform = new UntypedFormGroup({
      firstName: new UntypedFormControl(),
      lastName: new UntypedFormControl(),
      email: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(this.emailPattern)
      ])),
      message: new UntypedFormControl('', [Validators.required, this.noWhitespaceValidator]),
      isAtDown: new UntypedFormControl()
    });
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(() => resolve(), ms)).then();
  }

  registerUser(model: any): void {

    this.isValidFormSubmitted = false;
    if (this.myform.invalid) {
      return;
    }
    this.disableSignInButton = true;
    this.spinnerService.ShowSpinner();
    this.isValidFormSubmitted = true;

    let delayFlag: Boolean = false;

    if (localStorage.getItem("delayCurrentLogin")) {
      delayFlag = true;
    }
    else {
      localStorage.setItem("delayCurrentLogin", "true");
    }

    if (delayFlag) {
      this.delay(5000).then(() => {
        this.registerUserGo(model);
      });
    } else {
      this.registerUserGo(model);
    }

  }

  private registerUserGo(model: any) {
    this.myform.controls["isAtDown"].setValue(false);
    model.isAtDown = false;

    model.email = model.email.trim();
    this.userService.RegisterToChatGuest(model)
      .subscribe(
        data => {
          this.alertService.success('User exists', true);
          this.userService.checkUser(model);
          this.msgService.clientInitMessage.next(model.message);
          this.data = true;
        },
        error => {
          this.spinnerService.HideSpinner();
          if (error.status == 404) {
            this.alertService.error("User not found!");
            this.data = false;
            this.userService.checkUser(this.data);
            this.userService.setAlreadyEnteredUserData(model);
          }
          else if (error.status == 417) {
            this.alertService.error("User not found!");
            this.data = false;
            this.userService.checkUser(this.data);
            this.myform.controls["isAtDown"].setValue(true);
            model.isAtDown = true;
            this.userService.setAlreadyEnteredUserData(model);
          }
          else {
            this.userService.UserNotFound(error.status.toString());
            return;
          }
        });
  }

  public noWhitespaceValidator(control: UntypedFormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : {
      'whitespace': true
    };
  }

  SetLocalization() {
    var localeFromUrl = this.userService.getClientLanguageParam();
    var locale = this.localizationService.ValidateLocale(localeFromUrl);

    this.localizationService.GetLocalizationData().subscribe(data => {
      this.pageTranslation = data[0][this.pageName][locale];
      this.pageTranslated.next(this.pageTranslation);
    },
      error => { console.log(error) }, () => { });
  }

  notValid() {
    this.showFormErrors = true;
  }

}
