
import { Component, OnInit, Input, Injector, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { AppConfig } from '../AppConfig';
import { AdminService } from '../service/admin.service';
import { MaintenanceMode } from '../model/maintenancemode.model';
import { AuthenticationService } from '../service/authentication.service';

@Component({
  selector: 'app-maintenance-mode',
  templateUrl: './maintenance.component.html',
  styleUrls: ['./maintenance.component.css']
})

export class MaintenanceComponent implements MaintenanceComponent {
  @Input() data: any;

  public greetingText: string;
  private maintenanceMode: MaintenanceMode;

  constructor(
    private router: Router,
    private config: AppConfig,
    private adminService: AdminService,
    private activatedRoute: ActivatedRoute,
    private authenticationService: AuthenticationService) {

    this.authenticationService.logout();

    let groupId = this.activatedRoute.snapshot.queryParams["q"]; // groupId
    let groupIdNum = Number.parseInt(groupId);

    // const qParams: Params = {};
    // this.router.navigate([], {
    //   relativeTo: this.activatedRoute,
    //   queryParams: qParams,
    //   queryParamsHandling: ''
    // });

    if (!isNaN(Number(groupId))) {
      groupIdNum = Number(groupId);

      this.adminService.CheckIfMaintenanceModeIsEnabled(groupIdNum).subscribe(
        data => {
          this.maintenanceMode = data[0];
          console.log(this.maintenanceMode);
          this.greetingText = this.maintenanceMode.greetingMessage;
        });
    } else {
      this.greetingText = "APP OFFLINE FOR MAINTENANCE";
    }
  }

  ngOnInit() {

  }
}
