
import {map} from 'rxjs/operators';
import { Injectable } from '@angular/core';


import { HttpClient, HttpHeaders } from '@angular/common/http';


import { CookieService } from 'ngx-cookie-service';
import { UserFactory } from '../model/userFactory';
import { AppConfig } from '../AppConfig';



@Injectable()
export class AuthenticationService {
    _config(_config: any) {
        throw new Error("Method not implemented.");
    }

    constructor(private _httpClient: HttpClient, private cookieService: CookieService, private config: AppConfig) { 
        //this._adal.init(this._config);
    }


    // public isLoggedIn():boolean {
    //     return this._adal.userInfo.authenticated;
    //  }
     
    //  public signout():void {
    //     this._adal.logOut();
    //  }
     
    //  public startAuthentication():any {
    //     this._adal.login();
    //  }
     
    //  public getName():string {
    //     return this._user.profile.name;
    //  }
     
    //  public completeAuthentication():void {
    //     this._adal.handleWindowCallback();
    //     this._adal.getUser().subscribe(user=> {
    //     this._user=user;
    //     console.log(this._adal.userInfo);
    //     //var expireIn=user.profile.exp-newDate().getTime();
    //  });
     
    //  }

    login(username: string, password: string) {

        let headers: HttpHeaders = new HttpHeaders({ 
            'Content-Type': 'application/x-www-form-urlencoded'
        });

        let urlSearchParams = new URLSearchParams();
        urlSearchParams.append('userName', username);
        urlSearchParams.append('password', password);
        urlSearchParams.append('grant_type', 'password');

        let body = urlSearchParams.toString();

        return this._httpClient.post<any>(this.config.origin+'/api/token', body, { headers: headers }).pipe(

            map(user => {
               
               // login successful if there's a jwt token in the response        
                
                if (user && user.access_token && user.hasMentorAccess && user.hasMentorAccess != null) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes                    
                    
                    localStorage.setItem('currentUser', JSON.stringify(user));

                    let currentUser = JSON.parse(localStorage.getItem('currentUser'));
                    localStorage.setItem('BearerToken', currentUser.access_token );


                    //this.cookieService.set('BearerToken', currentUser.access_token)
                    this.cookieService.set('BearerToken', currentUser.access_token,1,null,null,true);
                    //(name: string, value: string, expires?: number | Date, path?: string, domain?: string, secure?: boolean)
                    //console.log('SET token cookie::', this.cookieService.get('BearerToken'));

                    localStorage.setItem('currentAdminUser', JSON.stringify(user));
                }

                return user;
            }));
    }

    logout() {
        this.cookieService.deleteAll();       
        localStorage.removeItem('BearerToken');
        localStorage.removeItem('currentUser');
        localStorage.removeItem('currentAdminUser');
        localStorage.removeItem('currAuthor');
        localStorage.removeItem('clientParams');
        localStorage.removeItem('userChatTopic');
        localStorage.removeItem("currentTicket");
        localStorage.removeItem("isConnected");
        localStorage.removeItem("clientLanguageParam");
        localStorage.removeItem("clientType");
        localStorage.removeItem("clientId");
        localStorage.removeItem("selectedRoomToBeOpen");
        localStorage.removeItem('currentUserChatVolume');
        localStorage.removeItem('currentUserRoomVolume');
    }

    checkTokenExpiration(){
        let token = localStorage.getItem('BearerToken');

        if (token !== '' && token != '' && token != null) 
        {
            // let pom=this.getDecodedAccessToken(token);
            // const expiry = this.getDecodedAccessToken(token).exp;
            // let isExpired= (Math.floor((new Date).getTime() / 1000)) >= expiry;
            // if(isExpired){
            //     this.logout();
            // }
        }
    }

    
}
