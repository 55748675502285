

export class RegisterChatEduModel {
    firstName: string;
      lastName: string;
      courseName: string;
      email: string;
      message: string;
      isUrlCreated:boolean;
}


export class RegisterChatEduFactory {
    public static GetModel(model:any):RegisterChatEduModel
    {
        var retModel=new RegisterChatEduModel();
        retModel.isUrlCreated=false;

        return retModel;
    }
}
