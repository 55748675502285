import { Component, OnInit, Input } from '@angular/core';
import { RegisterProcessComponent } from '../register-process';
import { Subscription ,  Subject, BehaviorSubject } from 'rxjs';
import { UsersService } from '../../service/users.service';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { AlertService } from '../../service/alert.service';
import { ActivatedRoute } from '@angular/router';
import { MessagesService } from '../../service/messages.service';
import { LocalizationService } from '../../service/localization.service';
import { MentorSpinnerService } from '../../service/mentorspinner.service';
import {CustomizationService} from '../../service/customization.service';
import { AppConfig } from '../../AppConfig';

@Component({
  selector: 'app-register-user-edu',
  templateUrl: './register-user-edu.component.html',
  styleUrls: ['./register-user-edu.component.css']
})

export class RegisterUserEduComponent implements RegisterProcessComponent {
  [x: string]: any;
  @Input() data: any;
  myform: UntypedFormGroup;
  email: any;
  paramWidget: boolean = false;
  subscription: Subscription;
  public clientType: Subject<string> = new BehaviorSubject<string>(null);
  public clientTypeParam: string;

  emailPattern = "[a-zA-Z0-9.-_-]{1,}@[0-9a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}";
  voucherPattern = "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}";
  isValidFormSubmitted = null;
  disableSignInButton: boolean = false;
  public logoFullPath: string = 'assets/images/logo.png';
  public colorFileFullPath: string = '';
  public chatColor: string = '#0061AA';
  
  private pageName = "RegisterUserEduComponent";
  public pageTranslation: any = "";
  public pageTranslated = new BehaviorSubject<string>("");

  constructor(private customizationService: CustomizationService, public mspinnerService: MentorSpinnerService, private localizationService: LocalizationService, private userService: UsersService, private alertService: AlertService, private activatedRoute: ActivatedRoute, private msgService: MessagesService, private config: AppConfig) {
    this.logoFullPath =customizationService.getNoCacheImage(this.config.logoURL + this.userService.getClientIdParam().toString() + '.png');
    
    this.colorFileFullPath = this.config.logoURL + this.userService.getClientIdParam().toString() + '.txt';

    this.chatColor = customizationService.getPersonalizedColor(this.colorFileFullPath);
    this.SetLocalization();
    let clientType = userService.getClientTypeParam();
    this.clientType.next(clientType);

    this.myform = new UntypedFormGroup({
      firstName: new UntypedFormControl(),
      lastName: new UntypedFormControl(),
      email: new UntypedFormControl('', [
        Validators.required,
        Validators.pattern(this.emailPattern)
      ]),
      voucher: new UntypedFormControl('', Validators.compose([
        Validators.required,
        Validators.pattern(this.voucherPattern)
      ])),
      message: new UntypedFormControl(),
      isAtDown: new UntypedFormControl()
    });

    this.subscription = this.userService.alreadyEntered.subscribe(t => {
      this.myform.controls["firstName"].setValue(t.firstName);
      this.myform.controls["lastName"].setValue(t.lastName);
      this.myform.controls["email"].setValue(t.email);
      this.myform.controls["message"].setValue(t.message);
      this.myform.controls["isAtDown"].setValue(t.isAtDown);
    });
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
      if (params['isWidget']) {
        this.paramWidget = true;
      } 


    });
    this.clientType.subscribe((clientType: string) => {
      this.clientTypeParam = clientType;
    });

  }

  SetLocalization() {
    var localeFromUrl = this.userService.getClientLanguageParam();
    var locale = this.localizationService.ValidateLocale(localeFromUrl);

    this.localizationService.GetLocalizationData().subscribe(data => {
      this.pageTranslation = data[0][this.pageName][locale];
      this.pageTranslated.next(this.pageTranslation);
    },
      error => { console.log(error) }, () => { });
  }

  registerNewUser(model: any): void {
    this.isValidFormSubmitted = false;
    if (this.myform.invalid) {
      return;
    }
    this.isValidFormSubmitted = true;
    this.disableSignInButton = true;
    this.mspinnerService.ShowSpinner();
    model.userType = this.clientTypeParam;
    this.userService.RedeemVoucherToUser(model)
      .subscribe(
        data => {
          this.alertService.success('User exists', true);
          this.data = true;
          this.userService.checkUser(model);
        },
        error => {
          this.mspinnerService.HideSpinner();
          if (error.status == 404) {
            this.alertService.error("User not found!");
            this.data = false;
          }
          else {
            this.userService.UserNotFound(error.status.toString());
          }
        });
  }
}
