import { Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import * as moment from 'moment';

/**
 * FromNowPipe let's us convert a date into a human-readable relative-time
 * such as "10 minutes ago".
 */
@Pipe({
  name: 'fromNow',
  pure: false
})
export class FromNowPipe implements PipeTransform {

  private currentValue: any = null;
  constructor(private changeDetector: ChangeDetectorRef) {
  }

  transform(value: any, args: Array<any>): string {

    if (this.currentValue !== moment(value, '', args.toString()).fromNow(true)) {
      //this.currentValue = moment(value).fromNow();
      
      this.currentValue = moment(value, '', args.toString()).fromNow(true);
      this.changeDetector.detectChanges();
    }
    return this.currentValue;

    //return moment(value).fromNow();
  }
}

export const fromNowPipeInjectables: Array<any> = [
  FromNowPipe
];
