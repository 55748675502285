<div id="box">
  <div class="error-title">{{pageTranslation.SomethingWentWrong}}</div>
  <hr />

  <p id="error-message">
    <span [innerHtml]="errMsg"></span>
    <span *ngIf="clientTypeParam == 'Telco'">{{pageTranslation.PleaseTryAgain}} <a href="https://mybell.bell.ca/Login">{{pageTranslation.Here}}</a></span>
  </p>
  <hr />
 
  <button mat-stroked-button *ngIf="showTryAgain==true"  (click)="ReloadPage()">{{pageTranslation.TryAgainButton}}</button>
  
</div>
