import { Ticket } from "./ticket";

export class TicketFactory {
 
    public static createTicket(ticketVM: any) : Ticket {
        return new Ticket({
            ID: ticketVM.ID,
            TicketNumber:ticketVM.TicketNumber,                 
            Title: ticketVM.Title,
            TicketSourceName: ticketVM.TicketSourceName,
            AccountID: ticketVM.AccountID,
            ParentTicketNumber: ticketVM.ParentTicketNumber,
            CreatedDate: ticketVM.CreatedDate,
            CreatedDateStr: ticketVM.CreatedDateStr
        });  
    }
}