
import {throttleTime} from 'rxjs/operators';
import {
  Component,
  OnInit,
  Inject,
  EventEmitter,
  Output,

  ChangeDetectorRef,
  ElementRef,
  ViewChild
} from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { RoomsService } from '../../service/rooms.service';
import { GroupByPipe } from '../pipes/GroupByPipe';
import { Room, RoomGroup } from '../../model/room.model';
import { ChatRoomToBeClosedComponent } from '../chat-room-to-be-closed/chat-room-to-be-closed.component';
import { MessagesService } from '../../service/messages.service';
import { messageFactory } from '../../model/messageFactory';
import { ChatRoomComponent } from '../chat-room/chat-room.component';
import { NgbNavModule, NgbModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'chat-rooms',
  // pipes: [GroupByPipe],
  templateUrl: './chat-rooms.component.html',
  styleUrls: ['./chat-rooms.component.css'],
  

})
export class ChatRoomsComponent {
  private onDestroy$: Subject<void> = new Subject<void>();
  noUnsweredRooms: Observable<any>;
  closedRooms: Observable<any>;
  groupByRooms: Observable<RoomGroup[]>;
  groupByMyRooms: Observable<RoomGroup[]>;
  intervalHolder: any;
  active = 1;


  selectedRoom: Room;
  @ViewChild(ChatRoomComponent,{static:false}) chatRoom: ChatRoomComponent;
  @ViewChild(ChatRoomToBeClosedComponent, {static:false}) chatRoomToBeClosed: ChatRoomToBeClosedComponent;
   @ViewChild('nav',{static:false}) ctdTabset;

  clk: Subject<any> = new Subject<any>();
  clkClosedRoom: Subject<any> = new Subject<any>();

  constructor(public roomsService: RoomsService, public messageService: MessagesService, public el: ElementRef) {

    this.groupByRooms = roomsService.groupedRooms;
    this.groupByMyRooms = roomsService.groupedMyRooms;
    this.noUnsweredRooms = roomsService.notRespondedRooms;
    this.closedRooms = roomsService.closedRooms;

    this.roomsService.preselectTab.subscribe((tabid:string)=>{
       this.ctdTabset.select(tabid);
    });

  }


  ngOnInit(): any {

    const subClk = this.clk
      .subscribe((event: MouseEvent) => {
        this.chatRoom.joinSelectedRoom(this.selectedRoom);
      });

    
    // this.clk.throttleTime(3000)
    //   .subscribe((event: MouseEvent) => {
        
    //   });

    const subClkCloseRoom = this.clkClosedRoom.pipe(throttleTime(3000))
      .subscribe((event: MouseEvent) => {
        this.chatRoomToBeClosed.joinSelectedRoom(this.selectedRoom);
      });

  }


  onSelect(currRoom: Room): void {
    this.selectedRoom = currRoom;
    this.clk.next(currRoom.id);
  }

  onSelectClosedRoom(currRoom: Room): void {
    this.selectedRoom = currRoom;
    this.clkClosedRoom.next(currRoom.id);
  }

  public ngOnDestroy(): void {
    this.onDestroy$.next();
  } 

}
